export function tree(svg, context, typeid, manual) {
  const viewport = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  viewport.setAttribute('transform', 'translate(88, 300)');

  const instance = {
    viewport,
    manual,
    context,
    vertices: new Map(),
    onselect: null,
    setListener(listener) {
      this.onselect = listener;
    },
    node(element) {
      const vertex = this.vertices.get(element);
      if (vertex) {
        const fqn = vertex.parts.join('.');
        const selected = this.vertex ? this.vertex.parts.join('.') : '';
        const prefix = selected ? `${selected}.` : '';
        return {
          key: fqn || null,
          cardinality: vertex.cardinality,
          parts: vertex.parts,
          typeid: vertex.typeid,
          disabled: vertex.disabled,
          parent: fqn && (fqn === selected || fqn.startsWith(prefix)) ? {
            path: selected || null,
            depth: vertex.parts.length - this.vertex.parts.length,
            relative: fqn.slice(prefix.length) || null,
          } : null,
        };
      } else return null;
    },
    select(notify, ...parts) {
      let vertex = this.vertices.get(null);
      for (const part of parts) {
        if (!vertex.expanded) {
          this.toggle(vertex);
        }
        if (!vertex.cached.fields.has(part)) {
          break;
        }
        vertex = vertex.cached.fields.get(part);
      }

      if (this.vertex) {
        this.vertex.selectable.classList.add('uk-button-secondary')
        this.vertex.selectable.classList.remove('uk-button-primary')
      }
      this.vertex = vertex;
      this.vertex.selectable.classList.add('uk-button-primary')
      this.vertex.selectable.classList.remove('uk-button-secondary')
      if (!this.vertex.expanded) {
        this.toggle(this.vertex);
      }
      if (this.onselect && notify) {
        this.onselect({
          cardinality: vertex.cardinality,
          parts: vertex.parts,
          typeid: vertex.typeid,
          disabled: vertex.disabled,
        });
      }
    },
    refresh() {
      const vertex = this.vertices.get(null);
      if (this.vertex.cached) {
        vertex.group.removeChild(vertex.cached.element);
      }
      this.vertices.clear();
      this.vertices.set(null, vertex);
      this.vertices.set(vertex.selectable, vertex);
      this.vertex.cached = null;
      this.vertex.expanded = false;
      this.toggle(vertex);
    },
    annotate(labels, ...parts) {
      let vertex = this.vertices.get(null);
      for (const part of parts) {
        if (!vertex.expanded) {
          this.toggle(vertex, true);
        }

        if (!vertex.cached.fields.has(part)) {
          const placeholder = this.addNode(
              null,
              false,
              [],
              vertex.cached.spine,
              [...vertex.cached.children],
              vertex,
              ...vertex.parts,
              part);
            vertex.cached.fields.set(part, placeholder);
            placeholder.group.appendChild(createText(-20, -3, '!'));
            this.vertices.set(placeholder.fqn, placeholder);
            vertex.cached.element.appendChild(placeholder.group);
            vertex.cached.children.push(placeholder);
            vertex.cached.shift(placeholder.next);
        }
        vertex = vertex.cached.fields.get(part);
      }
      vertex.annotate(...labels);
    },
    addNode(typeid, cardinality, properties, spine, downstream, parent, ...parts) {
      const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
      const translate = svg.createSVGTransform();
      group.transform.baseVal.insertItemBefore(translate, 0);

      if (parts.length) {
        const connector = createLine(-100, 0, -11, 0);
        connector.setAttribute('stroke-linecap', 'round');
        group.appendChild(connector);
      }
      if (cardinality === '0') {
        group.appendChild(createText(-36, -3, '0..'));
      }

      const element = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
      element.setAttribute('class', 'object-field-container');
      element.setAttribute('x', 0);
      element.setAttribute('y', -26);
      element.setAttribute('width', 360);
      element.setAttribute('height', 48);

      const label = parts.length ? parts[parts.length-1] || '<span class="uk-text-italic">unnamed</span>' : '';
      const selectable = document.createElementNS('http://www.w3.org/1999/xhtml', 'button');
      selectable.setAttribute('class', 'uk-border-pill uk-button-secondary uk-text-nowrap uk-button uk-button-large');
      if (typeid == null) {
        selectable.innerHTML = label;
      } else if (label) {
        selectable.innerHTML = `${label}: ${this.context.label(typeid)}`;
      } else {
        selectable.innerHTML = this.context.label(typeid);
      }
      element.appendChild(selectable)
      group.appendChild(element);

      const fqn = parts.join('.');
      const vertex = {
        next: 66,
        fqn,
        parent: parent,
        element: element,
        selectable: selectable,
        group: group,
        transform: translate,
        typeid: typeid,
        cardinality: cardinality,
        spine: spine,
        downstream: downstream,
        expanded: false,
        cached: null,
        properties,
        parts,
        disabled: parent ? parent.disabled || !parts[parts.length-1] : false,
        annotations: [],
        height() {
          let value = this.next;
          if (this.cached && this.expanded) {
            this.cached.children.forEach((child) => {
              value += child.height();
            })
          }
          return value;
        },
        select: function(field) {
          if (this.cached) {
            return this.cached.fields.get(field);
          } else {
            return null;
          }
        },
        annotate(...labels) {
          this.next = 66;
          let offset = 0;
          for (const txt of this.annotations) {
            group.removeChild(txt);
            offset -= 20;
          }
          const updated = [];
          for (let i = 0; i < labels.length; i++) {
            const txt = createText(60, 48 + 20*i, labels[i]);
            group.appendChild(txt);
            updated.push(txt);
            offset += 20;
          }
          this.annotations = updated;
          this.shift(offset);
          this.next = 66 + labels.length * 20;
          if (this.cached) {
            this.cached.shift(this.next);
          }
        },
        shift: function(delta) {
          let node = this;
          while (node) {
            if (node.downstream.length) {
              node.spine.setAttribute(
                'y2',
                Number(node.spine.getAttribute('y2')) + delta);
              }
              for (const child of node.downstream) {
                child.transform.matrix.f += delta;
              }
              node = node.parent;
            }
          }
      }

      selectable.onclick = (evt) => {
        evt.preventDefault();
        if (this.vertex !== vertex && !this.manual) {
          if (this.vertex) {
            this.vertex.selectable.classList.add('uk-button-secondary')
            this.vertex.selectable.classList.remove('uk-button-primary')
          }
          this.vertex = vertex;
          this.vertex.selectable.classList.add('uk-button-primary')
          this.vertex.selectable.classList.remove('uk-button-secondary')
          if (!this.vertex.expanded) {
            this.toggle(this.vertex);
          }
          if (this.onselect) {
            this.onselect({
              cardinality: vertex.cardinality,
              parts: vertex.parts,
              typeid: vertex.typeid,
              disabled: vertex.disabled,
            });
          }
        } else {
          this.toggle(vertex);
        }
      }
      selectable.ontouchstart = selectable.onclick;

      this.vertices.set(parts.length ? vertex.fqn : null, vertex);
      this.vertices.set(vertex.selectable, vertex);
      viewport.appendChild(group);
      return vertex;
    },
    toggle(vertex, forced) {
      let delta = 0;
      if (vertex.expanded) {
        delta = vertex.next - vertex.height();
        vertex.group.removeChild(vertex.cached.element);
        vertex.expanded = false;
      } else {
        if (vertex.cached === null || (forced && !vertex.cached)) {
          if (!vertex.properties.length && !forced) {
            vertex.cached = false;
            return;
          }

          vertex.cached = {
            element: document.createElementNS('http://www.w3.org/2000/svg', 'g'),
            spine: createLine(34, 36, 34, 36),
            children: [],
            fields: new Map(),
            shift: function(delta) {
              let hOffset = delta;
              let priorOffset = 0;
              for (let i = this.children.length; i--;) {
                const child = this.children[i];
                this.element.appendChild(child.group);
                child.transform.matrix.e = 134;
                child.transform.matrix.f = hOffset;
                priorOffset = child.height();
                hOffset += priorOffset
              }
              vertex.cached.spine.setAttribute('y2', hOffset - priorOffset);
            }
          };

          vertex.cached.element.appendChild(createText(40, 46, '1'));
          vertex.cached.spine.setAttribute('stroke-linecap', 'round');
          vertex.cached.element.appendChild(vertex.cached.spine);
          for (let i = vertex.properties.length; i--;) {
            const property = vertex.properties[i];
            const child = this.addNode(
              property.data_type,
              property.cardinality,
              property.schema,
              vertex.cached.spine,
              [...vertex.cached.children],
              vertex,
              ...vertex.parts,
              property.name);
            vertex.cached.fields.set(property.name, child);
            child.group.appendChild(createText(-20, -3, property.cardinality ? '1' : '*'));
            this.vertices.set(child.fqn, child);
            vertex.cached.children.push(child);
          }
          vertex.cached.shift(vertex.next);
        } else if (!vertex.cached) {
          return;
        }
        vertex.group.appendChild(vertex.cached.element);
        vertex.expanded = true;
        delta = vertex.height() - vertex.next;
      }

      vertex.shift(delta);
    },
  };
  instance.addNode(typeid, "1", context.fields(typeid), null, [], null);
  return instance;
}

export function createLine(x1, y1, x2, y2) {
  const el = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  el.setAttribute('class', 'annotation');
  el.setAttribute('x1', x1);
  el.setAttribute('y1', y1);
  el.setAttribute('x2', x2);
  el.setAttribute('y2', y2);
  return el;
}

export function createText(x, y, text, classes) {
  const el = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  el.setAttribute('class', classes);
  el.setAttribute('x', x);
  el.setAttribute('y', y);
  el.textContent = text;
  return el;
}

export function createRect(x, y, width, height, classes) {
  const el = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
  el.setAttribute('class', classes);
  el.setAttribute('x', x);
  el.setAttribute('y', y);
  el.setAttribute('width', width);
  el.setAttribute('height', height);
  return el;
}