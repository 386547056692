<template>
  <EditableItem
    :terminal="true"
    :readonly="readonly"
    :hidden="true"
    :id="item.id"
    :name="name"
    placeholder="new field constraint"
    :editing="editing"
    :attributes="attributes"
    :error="error"
    v-on:edit="$emit('edit')"
    v-on:delete="$emit('delete')"
    v-on:center="$emit('center', item.data_type)">
    <template v-if="editing">
      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">name <span class="uk-text-meta"> {{ error.field('/name') }}</span></span>
          <input
            ref="name"
            class="uk-form-controls uk-inline uk-input"
            type="text"
            pattern="[a-z](?:_?[a-z0-9]+)*"
            :placeholder="$command('objects.name', { label: 'option' })"
            autofocus
            v-on:change="$emit('update', {field: 'name', value: name.trim().replace(/[\s-()+\.]/g, '_').replace(/__+/g, '_').toLowerCase()})"
            v-model="name"
            spellcheck="false"
            autocorrect="off"
            autocapitalize="off"
            autocomplete="off"
            v-bind:disabled="readonly">
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Enter a short, unique, and memorable name. The name must start with a
            lowercase letter ("a" to "z"), and can contain lowercase letters, numbers
            ("0" to "9"), with an underscore ("_") between words.
          </p>
        </label>
      </div>
      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">type</span>
          <select class="uk-form-controls uk-inline uk-select" v-model="type" @change="doReset" v-bind:disabled="readonly">
            <option value="" v-if="!type" disabled>-- select --</option>
            <option value="CHECK">check</option>
            <option value="DISJOINT">disjoint</option>
            <option value="DISTINCT">distinct</option>
          </select>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Choose the type of named constraint
          </p>
        </label>
      </div>
      <div class="uk-margin" v-if="type">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">scope</span>
          <select class="uk-form-controls uk-inline uk-select" v-model="scope" @change="doUpdate" v-bind:disabled="readonly">
            <option value="">/</option>
            <template v-for="(field, i) in structure.tree">
            <option :value="field.value"  :key="i" v-if="field.children">{{ field.label }}</option>
            </template>
          </select>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Choose the scope where this named constraint applies
          </p>
        </label>
      </div>
      <div class="uk-margin" v-if="type === 'DISTINCT'">
        <label class="uk-flex uk-flex-column">
          <div class="uk-form-label">field</div>
          <div class="uk-form-controls">
            <ul class="uk-list uk-text-right">
              <li v-if="expression">{{ expression }}</li>
              <li class="uk-text-italic">select field on canvas...</li>
            </ul>
          </div>
          <p class="uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Select the field that will have an enforced distinct constraint
          </p>
        </label>
      </div>
      <div class="uk-margin" v-else-if="type === 'DISJOINT'">
        <label class="uk-flex uk-flex-column">
          <div class="uk-form-label">fields</div>
          <div class="uk-form-controls">
            <ul class="uk-list uk-text-right">
              <li v-for="(item, index) in expression.split(',')" :key="index">{{ item }}</li>
              <li class="uk-text-italic">add or remove fields on canvas...</li>
            </ul>
          </div>
          <p class="uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Select the fields that must have dissimilar values. Missing values are ignored by this constraint
          </p>
        </label>
      </div>
      <div class="uk-margin" v-else>
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">{{ $label('objects.expression') }}</span>
          <textarea
            ref="expression"
            class="uk-form-controls uk-inline uk-textarea uk-width-expand uk-flex-auto"
            style="resize: vertical"
            rows="5"
            v-model="expression"
            :placeholder="$command('objects.expression', { label: type })"
            v-on:change="doUpdate"
            v-bind:disabled="readonly">
          </textarea>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help('objects.expression') }}
          </p>
        </label>
      </div>
      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <div class="uk-form-label">description <span class="uk-text-meta"> {{ error.field('/description') }}</span></div>
          <textarea
            ref="description"
            class="uk-form-controls uk-inline uk-textarea uk-width-expand uk-flex-auto"
            style="resize: vertical"
            rows="5"
            v-model="description"
            :placeholder="$command('objects.description', { label: 'field_constraint' })"
            v-on:change="$emit('update', {field: 'description', value: description})"
            v-bind:disabled="readonly">
          </textarea>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Describe the net effect of all constraints applied to this field, if any are required. This helps produce relevant error messages.
          </p>
        </label>
      </div>
    </template>
    <p v-else-if="description">{{ description }}</p>
    <p class="uk-text-italic uk-text-center" v-else>this field constraint has no description</p>
  </EditableItem>
</template>

<script>
import { mapState } from 'vuex';
import EditableItem from '@/components/canvas/EditableItem.vue';
const REGEX = /^(?:DISTINCT\s*\(\s*([a-z0-9_.]*)\s*\)|DISJOINT\s*\(\s*([a-z0-9_.,]*)\s*\)|CHECK\s*\((.*)\))(?:\s*IN\s*([a-z0-9_.]+))?\s*$/;

function assign(src, dst) {
  dst.attributes = src.attribute ? [src.attribute] : [];
  dst.name = src.item.name ?? '';
  dst.description = src.item.description ?? '';
  const match = REGEX.exec(src.item.value ?? '');
  if (match) {
    dst.scope = match[4] ? match[4] : '';
    if (match[1] != null) {
      dst.type = 'DISTINCT';
      dst.expression = match[1];
    } else if (match[2] != null) {
      dst.type = 'DISJOINT';
      dst.expression = match[2];
    } else {
      dst.type = 'CHECK';
      dst.expression = match[3];
    }
  } else {
    dst.type = '';
    dst.scope = '';
    dst.expression = src.item.value ?? '';
  }
  dst.error = {
    invalid: src.errors.length,
    msg: src.errors.length ? 'invalid data' : null,
    local: [],
    fields: new Map(),
    field(src) {
      return this.fields.get(src) ||'';
    },
  }
  for (const {property, detail} of src.errors) {
    switch (property) {
      case '/name':
      case '/description':
        dst.error.fields.set(property, detail);
        break;
      default:
        dst.error.local.push(detail);
        break;
    }
  }
  return dst;
}

export default {
  name: 'NamedConstraint',
  props: {
    parent: Object,
    version: Number,
    item: Object,
    errors: Array,
    editing: Boolean,
    context: Object,
    categories: Object,
    attribute: String,
    structure: Object,
    readonly: Boolean,
  },
  components: {
    EditableItem,
  },
  data() {
    return assign(this, {});
  },
  mounted() {
  },
  computed: {
    ...mapState('api', ['help']),
  },
  watch: {
    version() {
      assign(this, this);
    },
  },
  methods: {
    doReset() {
      if (!this.type) {
        this.scope = '';
      }
      this.expression = '';
      this.doUpdate();
    },
    doUpdate() {
      if (this.scope) {
        this.$emit('update', {field: 'value', value: `${this.type}(${this.expression}) IN ${this.scope}`})
      } else if (this.type) {
        this.$emit('update', {field: 'value', value: `${this.type}(${this.expression})`})
      } else {
        this.$emit('update', {field: 'value', value: this.expression})
      }
    }
  }
}
</script>