<template>
  <input
    class="uk-form-controls uk-inline uk-input"
    :disabled="disabled"
    :class="{'uk-disabled': disabled}"
    type="text"
    pattern="[a-z](?:_?[a-z0-9]+)*"
    :placeholder="disabled ? 'unable to rename yet' : 'rename field'"
    autofocus
    v-model="renamed"
    spellcheck="false"
    autocorrect="off"
    autocapitalize="off"
    autocomplete="off"
    v-on:change="$emit('update', renamed)">
</template>

<script>
export default {
  name: 'ObjectField',
  props: {
    value: String,
    disabled: Boolean,
  },
  data() {
    return {
      renamed: this.value || '',
    }
  },
  watch: {
    value() {
      this.renamed = this.value || '';
    },
  },
}
</script>