<template>
  <EditableItem
    :terminal="true"
    :hidden="true"
    :readonly="readonly"
    :name="item.name"
    :placeholder="$label('objects.unnamed', { label: $label(`objects.${label}`) })"
    :editing="editing"
    :attributes="attributes"
    :error="error"
    v-on:focus="$emit('focus')"
    v-on:edit="$emit('edit')"
    v-on:delete="$emit('delete')"
    v-on:center="$emit('center')">
    <template v-if="editing">
      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">{{ $label('objects.name') }} <span class="uk-text-meta"> {{ error.field('/name') }}</span></span>
          <input
            ref="name"
            class="uk-form-controls uk-inline uk-input"
            type="text"
            v-model="name"
            pattern="[a-z](?:_?[a-z0-9]+)*"
            :placeholder="$command('objects.name', { label: $label(`objects.${label}`) })"
            v-on:change="$emit('update', {field: 'name', value: name.trim().replace(/[\s-()+\.]/g, '_').replace(/__+/g, '_').toLowerCase()})"
            spellcheck="false"
            autocorrect="off"
            autocapitalize="off"
            autocomplete="off"
            v-bind:disabled="readonly">
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help('objects.name') }}
          </p>
        </label>
      </div>

      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">{{ $label('objects.expression') }} <span class="uk-text-meta"> {{ error.field('/expression') }}</span></span>
          <input
            ref="expression"
            class="uk-form-controls uk-inline uk-input"
            type="text"
            v-model="expression"
            :placeholder="$command('objects.expression')"
            v-on:change="$emit('update', {field: 'expression', value: expression})"
            spellcheck="false"
            autocorrect="off"
            autocapitalize="off"
            autocomplete="off"
            v-bind:disabled="readonly">
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help('objects.expression') }}
          </p>
        </label>
      </div>

      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">{{ $label('objects.description') }} <span class="uk-text-meta"> {{ error.field('/description') }}</span></span>
          <textarea
            ref="description"
            class="uk-form-controls uk-inline uk-textarea uk-width-expand uk-flex-auto"
            style="resize: vertical"
            rows="5"
            v-model="description"
            :placeholder="$command('objects.description', { label: $label(`objects.${label}`) })"
            v-on:change="$emit('update', {field: 'description', value: description})"
            v-bind:disabled="readonly">
          </textarea>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help(`objects.regexes`) }}
          </p>
        </label>
      </div>
    </template>
    <p v-else-if="description">{{ description }}</p>
    <p class="uk-text-italic uk-text-center" v-else>
      {{ $message('objects.missingattr', { label: $label(`objects.${label}`), attr: $label('objects.description') }) }}
    </p>
  </EditableItem>
</template>

<script>
import { mapState } from 'vuex';
import EditableItem from '@/components/canvas/EditableItem.vue';

function assign(src, dst) {
  dst.attributes = src.attribute ? [ src.attribute ] : [];
  dst.name = src.item.name ?? '';
  dst.expression = src.item.expression ?? '';
  dst.description = src.item.description ?? '';
  dst.error = {
    invalid: src.errors.length,
    msg: src.errors.length ? 'invalid data' : null,
    local: [],
    fields: new Map(),
    field(src) {
      return this.fields.get(src) ||'';
    },
  }
  for (const {property, detail} of src.errors) {
    switch (property) {
      case '/name':
      case '/expression':
      case '/description':
        dst.error.fields.set(property, detail);
        break;
      default:
        dst.error.local.push(detail);
        break;
    }
  }
  return dst;
}

export default {
  name: 'Regex',
  props: {
    version: Number,
    item: Object,
    category: String,
    label: String,
    errors: Array,
    editing: Boolean,
    context: Object,
    categories: Object,
    attribute: String,
    readonly: Boolean,
  },
  components: {
    EditableItem
  },
  data() {
    return assign(this, {});
  },
  mounted() {
  },
  beforeDestroy() {
  },
  watch: {
    version() {
      assign(this, this);
    },
  },
  computed: {
    ...mapState('api', ['help']),
  },
  methods: {
  },
}
</script>
