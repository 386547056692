<template>
  <section>
    <div class="uk-flex uk-flex-right uk-flex-middle uk-margin-small-top">
      <span class="uk-text-meta uk-margin-small-right uk-text-italic" v-if="!value">no action selected</span>
      <select class="uk-select uk-form-width-medium" v-model="value" @change="$emit('change', value)">
        <option value="" disabled>Select merge action...</option>
        <option value="remote">{{ upstream }}</option>
        <option value="local"> {{ downstream }}</option>
      </select>
    </div>
    <dl class="uk-description-list">
      <div v-for="value in values" :key="value.key">
        <dt>{{ value.key }}</dt>
        <dd v-if="value.remote && value.remote !== value.local"><s v-if="keep">{{ value.remote }}</s><span :class="!action ? 'uk-text-italic' : ''" v-else>{{ value.remote }}</span></dd>
        <dd v-if="value.local"><s v-if="!keep && value.remote !== value.local">{{ value.local }}</s><span :class="!action ? 'uk-text-italic' : ''" v-else>{{ value.local }}</span></dd>
      </div>
    </dl>
  </section>
</template>

<script>

function assign(src, dst) {
  dst.value = src.action || '';
  if (src.local == null) {
    dst.upstream = 'accept remote changes';
    dst.downstream = 'discard remote changes';
  } else if (src.remote == null) {
    dst.upstream = 'discard local changes';
    dst.downstream = 'accept local changes';
  } else {
    dst.upstream = 'override local changes';
    dst.downstream = 'override remote changes';
  }
  return dst;
}

export default {
  name: 'MergeItem',
  props: {
    action: String,
    local: Number,
    remote: Number,
    values: Array,
  },
  components: {
  },
  data() {
    return assign(this, {});
  },
  computed: {
    keep() {
      return this.value != 'remote';
    }
  },
  watch: {
    action() {
      assign(this, this);
    }
  }
}
</script>