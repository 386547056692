<template>
  <div>
      <div class="uk-margin" v-if="value || typeid === 'STRING'">
        <input
          class="uk-form-controls uk-inline uk-input"
          :disabled="readonly"
          :class="{'uk-disabled': readonly}"
          type="text"
          :placeholder="readonly ? '' : 'add metadata'"
          autofocus
          v-model="value"
          spellcheck="false"
          autocorrect="off"
          autocapitalize="off"
          autocomplete="off"
          v-on:change="$emit('update', { field: 'metadata', value: value || null })">
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-animation-fade" v-if="help && typeid === 'STRING'">
          String types can be customized with LENGTH(maxlength) constraints
        </p>
      </div>
      <div class="uk-margin" v-if="typeid === 'DECIMAL'">
        <input
          class="uk-form-controls uk-inline uk-input"
          :disabled="readonly"
          :class="{'uk-disabled': readonly}"
          type="text"
          :placeholder="readonly ? '' : 'set precision and scale'"
          v-model="radio"
          spellcheck="false"
          autocorrect="off"
          autocapitalize="off"
          autocomplete="off"
          v-on:change="$emit('update', { field: 'special', value: radio })">
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-animation-fade" v-if="help">
          Decimal types can be customized with a precision and scale
        </p>
      </div>
      <div class="uk-margin uk-flex uk-flex-column">
        <label v-if="typeid !== 'LOOKUP' && typeid !== 'DECIMAL'">
          <input class="uk-radio" type="radio" name="radio1" value="" v-model="radio"
            v-on:change="$emit('update', {field: 'special', value: null})"
            v-bind:disabled="readonly">
          <span class="uk-margin-small-left uk-margin-small-right uk-form-label" style="display: inline-block">default</span>
          <p class="uk-margin-remove-top uk-text-right uk-text-meta
                uk-animation-fade" v-if="help">
            The default configuration for the selected data type
          </p>
        </label>

        <label v-if="typeid === 'ATTACHMENT'">
          <input class="uk-radio" type="radio" name="radio2" value="text/plain" v-model="radio"
            v-on:change="$emit('update', {field: 'special', value: 'text/plain'})"
            v-bind:disabled="readonly">
          <span class="uk-margin-small-left uk-margin-small-right uk-form-label" style="display: inline-block">text/plain</span>
          <p class="uk-margin-remove-top uk-text-right uk-text-meta
                uk-animation-fade" v-if="help">
            A text document
          </p>
        </label>

        <label v-if="typeid === 'ATTACHMENT'">
          <input class="uk-radio" type="radio" name="radio2" value="image/jpeg" v-model="radio"
            v-on:change="$emit('update', {field: 'special', value: 'image/jpeg'})"
            v-bind:disabled="readonly">
          <span class="uk-margin-small-left uk-margin-small-right uk-form-label" style="display: inline-block">image/jpeg</span>
          <p class="uk-margin-remove-top uk-text-right uk-text-meta
                uk-animation-fade" v-if="help">
            A JPEG image
          </p>
        </label>

        <label v-if="typeid === 'DATETIME' || radio === 'DAY'">
          <input class="uk-radio" type="radio" name="radio2" value="DAY" v-model="radio"
            v-on:change="$emit('update', {field: 'special', value: 'DAY' })"
            v-bind:disabled="readonly">
          <span class="uk-margin-small-left uk-margin-small-right uk-form-label" style="display: inline-block">date</span>
          <p class="uk-margin-remove-top uk-text-right uk-text-meta
                uk-animation-fade" v-if="help">
            A date-only component
          </p>
        </label>

        <label v-if="typeid === 'DATETIME' || radio === 'DURATION'">
          <input class="uk-radio" type="radio" name="radio3" value="DURATION" v-model="radio"
            v-on:change="$emit('update', {field: 'special', value: 'DURATION' })"
            v-bind:disabled="readonly">
          <span class="uk-margin-small-left uk-margin-small-right uk-form-label" style="display: inline-block">duration</span>
          <p class="uk-margin-remove-top uk-text-right uk-text-meta
                uk-animation-fade" v-if="help">
            A time-only component that can either represent a time interval or a time of day
          </p>
        </label>

        <label v-if="typeid === 'STRING' || radio === 'MULTILINE'">
          <input class="uk-radio" type="radio" name="radio4" value="MULTILINE" v-model="radio"
            v-on:change="$emit('update', {field: 'special', value: 'MULTILINE' })"
            v-bind:disabled="readonly">
          <span class="uk-margin-small-left uk-margin-small-right uk-form-label" style="display: inline-block">multiline</span>
          <p class="uk-margin-remove-top uk-text-right uk-text-meta
                uk-animation-fade" v-if="help">
            A free text field that can contain multiple lines of content
          </p>
        </label>

        <label v-if="typeid === 'FLOAT' || typeid === 'INTEGER' || radio === '64'">
          <input class="uk-radio" type="radio" name="radio5" value="64" v-model="radio"
            v-on:change="$emit('update', {field: 'special', value: '64' })"
            v-bind:disabled="readonly">
          <span class="uk-margin-small-left uk-margin-small-right uk-form-label" style="display: inline-block">64-bit</span>
          <p class="uk-margin-remove-top uk-text-right uk-text-meta
                uk-animation-fade" v-if="help">
            A 64-bit representation
          </p>
        </label>

        <template v-if="typeid === 'LOOKUP'">
          <label v-for="item in context.lookups()" :key="item.id">
            <input class="uk-radio" type="radio" :name="`radio-${item.id}`" :value="item.values.name" v-model="radio"
              v-on:change="$emit('update', {field: 'special', value: item.values.name })"
              v-bind:disabled="readonly || !item.values.name">
            <span class="uk-margin-small-left uk-margin-small-right uk-form-label" style="display: inline-block">{{ item.values.name || 'unnamed lookup' }}</span>
            <p class="uk-margin-remove-top uk-text-right uk-text-meta
                  uk-animation-fade" v-if="help">
              {{ item.values.description || 'This lookup has no description' }}
            </p>
          </label>
        </template>
        <template v-else-if="typeid === 'STRING'">
          <label v-for="item in context.regexes()" :key="item.id">
            <input class="uk-radio" type="radio" :name="`radio-${item.id}`" :value="item.values.name" v-model="radio"
              v-on:change="$emit('update', {field: 'special', value: item.values.name })"
              v-bind:disabled="readonly || !item.values.name">
            <span class="uk-margin-small-left uk-margin-small-right uk-form-label" style="display: inline-block">{{ item.values.name || 'unnamed pattern' }}</span>
            <p class="uk-margin-remove-top uk-text-right uk-text-meta
                  uk-animation-fade" v-if="help">
              {{ item.values.description || 'This pattern has no description' }}
            </p>
          </label>
        </template>

      </div>
  </div>
</template>

<script>
export default {
  name: 'Metadata',
  props: {
    version: Number,
    context: Object,
    typeid: String,
    special: Object,
    metadata: Object,
    readonly: Boolean,
    help: Boolean,
  },
  data() {
    return {
      value: this.metadata && this.metadata.typeid === this.typeid ? this.metadata.value : '',
      radio: this.special && this.special.typeid === this.typeid ? this.special.value : '',
    }
  },
  methods: {
  },
  computed: {
  },
  watch: {
    version() {
      this.value = this.metadata && this.metadata.typeid === this.typeid ? this.metadata.value : '';
      this.radio = this.special && this.special.typeid === this.typeid ? this.special.value : '';
    },
  },
}
</script>