<template>
  <div ref="context" uk-modal="container: #app">
    <form class="uk-modal-dialog" v-on:submit.prevent="submit()">

      <button class="uk-modal-close-default" type="button" uk-close></button>

      <div class="uk-modal-body" uk-overflow-auto>
        <h2 class="uk-modal-title">{{ field ? field.key : title }} <span class="uk-text-meta" v-if="subtitle">{{ subtitle }}</span></h2>
        <div class="uk-margin" v-if="captured.length">
          <div class="uk-form-label">{{ $label('modal.group') }}</div>
          <div class="uk-form-controls uk-margin-left">
            <label>
              <input
                class="uk-form-controls uk-inline uk-input"
                type="text"
                placeholder="enter group name"
                v-model="name"
                :class="{'uk-disabled': focused && focused !== 'name'}" :disabled="focused && focused !== 'name'">
              <p class="uk-margin-small-top uk-text-right uk-text-meta
                    uk-margin-remove-bottom uk-animation-fade" v-if="help">
                {{ $message('modal.group') }}
              </p>
            </label>
          </div>
        </div>
        <div class="uk-margin" v-if="(this.parent != null && captured.length) || move">
          <div class="uk-form-label">{{ $label('modal.move') }}</div>
          <div class="uk-form-controls uk-margin-left">
              <label>
                <select class="uk-select uk-inline" v-model="target" :class="{'uk-disabled': focused && focused !== 'target'}" :disabled="focused && focused !== 'target'">
                  <option :value="item" v-for="item in targets" :key="item" :disabled="item === parent">
                    {{ context.label(item) }}
                  </option>
                </select>
                <p class="uk-margin-small-top uk-text-right uk-text-meta
                      uk-margin-remove-bottom uk-animation-fade" v-if="help">
                  {{ $message('modal.move') }}
                </p>
              </label>
          </div>
        </div>
        <div class="uk-margin" v-else-if="selected == null">
          <div class="uk-form-label">{{ $label('modal.create') }}</div>
          <div class="uk-form-controls uk-margin-left">
            <template v-for="section in sections">
              <label :key="section.category">
                <input class="uk-radio" type="radio" name="radio1" :value="section.category" v-model="category"
                  :class="{'uk-disabled': focused && focused !== 'category'}" :disabled="focused && focused !== 'category'">
                {{ $label(`modal.${section.label}`) }}
                <p class="uk-margin-small-top uk-text-right uk-text-meta
                      uk-margin-remove-bottom uk-animation-fade" v-if="help">
                  {{ $message(`modal.${section.label}`) }}
                </p>
                <br/>
              </label>
            </template>
          </div>
        </div>
        <div class="uk-margin" v-if="properties.length">
          <div class="uk-form-label">{{ $label('modal.update') }}</div>
          <div class="uk-form-controls uk-margin-left">
            <template v-for="(property, index) in properties">
            <label :key="index">
              <input class="uk-radio" type="radio" name="radio" :value="property.value ?? `$${property.name}`" v-model="update"
                :class="{'uk-disabled': !property.enabled || (focused && focused !== 'update')}" :disabled="!property.enabled || (focused && focused !== 'update')"
                >
              {{ $label(`modal.${property.name}`, property) }}
              <p class="uk-margin-small-top uk-text-right uk-text-meta
                    uk-margin-remove-bottom uk-animation-fade" v-if="help">
                {{ $message(`modal.${property.name}`) }}
              </p>
            </label><br :key="-1-index">
            </template>
          </div>
        </div>
        <div class="uk-margin" v-if="fields.length">
          <div class="uk-form-label">{{ $label('modal.new') }}</div>
          <div class="uk-form-controls uk-margin-left">
            <template v-for="(field, index) in fields">
            <label :key="index">
              <input class="uk-radio" type="radio" name="radio1" :value="field.name" v-model="create"
                :class="{'uk-disabled': !field.enabled || (focused && focused !== 'create')}" :disabled="!field.enabled || (focused && focused !== 'create')">
              {{ $label(`modal.${field.name}`) }}
              <p class="uk-margin-small-top uk-text-right uk-text-meta
                    uk-margin-remove-bottom uk-animation-fade" v-if="help">
                {{ $message(`modal.${field.name}`) }}
              </p>
            </label><br :key="-1-index">
            </template>
          </div>
        </div>
      </div>
      <div class="uk-modal-footer uk-flex uk-flex-between">
          <button class="uk-button uk-button-text uk-modal-close" type="button">{{ $label('modal.back') }}</button>
          <button class="uk-button uk-button-default" :class="{'uk-disabled': !action, 'uk-button-primary': action }" type="submit">
            {{ $label('modal.ok') }}
          </button>
      </div>

    </form>
  </div>
</template>

<script>
import UIkit from 'uikit';
import { mapState } from "vuex";

const FIELD = /(UNSAFE)|(INLINE)|APPLY\s*\(\s*([a-z0-9_.]*)\s*\)|(SET)(?:\s*\(\s*([a-z0-9_.]*)\s*\))?|CHECK\s*\((.*)\)/g;
const NAMED = /^(?:DISTINCT\s*\(\s*([a-z0-9_.]*)\s*\)|DISJOINT\s*\(\s*([a-z0-9_.,]*)\s*\)|CHECK\s*\((.*)\))(?:\s*IN\s*([a-z0-9_.]+))?\s*$/;

const HIDDEN = new Set(['Lookup', 'Regex', 'Configuration', 'Provider']);

const HAS_PROPERTIES = new Set(['data_types', 'events', 'commands', 'queries', 'processes', 'user_inputs']);
const PROXIABLE = new Set(['views', 'actions', 'contexts']);
const HAS_DATA_TYPE = new Set(['aggregate_roots', 'prototypes']);
const HAS_RETURN_TYPE = new Set(['queries', 'views', 'contexts']);
const HAS_EVENT_TYPE = new Set(['commands', 'subscriptions', 'subprocesses', 'interrupts']);
const IS_PROPERTY = new Set(['data_types', 'aggregate_roots']);
const IS_DEPENDENCY = new Set(['queries', 'processes', 'aggregate_roots']);
const IS_EVENT = new Set(['events', 'aggregate_roots']);
const IS_SINK = new Set(['contexts', 'data_types']);
const NAVIGABLE = new Set(['views', 'contexts', 'actions']);

export default {
  name: 'ContextModal',
  props: {
    context: Object,
    title: String,
    subtitle: String,
    parent: String,
    schema: Object,
    selected: String,
    field: Object,
    editing: String,
    x: Number,
    y: Number,
    captured: Array,
    group: String,
  },
  data() {
    return {
      name: this.group,
      category: '',
      target: this.parent,
      update: '',
      create: '',
      position: { x: Math.round(Math.round(this.x/40)*40), y: Math.round(Math.round(this.y/40)*40) },
    };
  },
  mounted() {
    UIkit.util.on(this.$refs.context, 'hidden', () => this.$emit('close'));
    UIkit.modal(this.$refs.context).show();
  },
  methods: {
    submit() {
      if (this.captured.length && this.name != this.group) {
        this.$emit('group', { captured: this.captured, name: this.name || null });
      } else if (this.target !== this.parent && this.selected != null) {
        this.$emit('migrate', {parent: this.parent, captured: [ this.selected ], target: this.target});
      } else if (this.target !== this.parent) {
        this.$emit('migrate', {parent: this.parent, captured: this.captured, target: this.target});
      } else if (this.category) {
        this.$emit('create', {
          section: this.schema.sections.find(x => x.category === this.category),
          position: this.position,
          group: this.group || null,
        });
      } else if (this.create) {
        const section = this.schema.sections.find(x => x.label === this.create);
        switch (this.create) {
          case 'properties':
            this.$emit('create', { section, values: {
              name: this.context.label(this.selected),
              data_type: this.selected,
              cardinality: '1',
            } });
            break;
          case 'dependencies':
          case 'workflows':
            this.$emit('create', { section, values: { service: this.selected } });
            break;
          case 'navigations':
            this.$emit('create', { section, values: { dst: this.selected } });
            break;
          case 'distinct.create': {
              const section = this.schema.sections.find(x => x.label === 'named');
              this.$emit('create', {
                section,
                values: {
                  value: this.field.parent.path ? `DISTINCT(${this.field.parent.relative}) IN ${this.field.parent.path}` : `DISTINCT(${this.field.parent.relative})`,
                }
              });
            }
            break
          case 'disjoint.create': {
            const section = this.schema.sections.find(x => x.label === 'named');
              this.$emit('create', {
                section,
                values: {
                  value: this.field.parent.path ? `DISJOINT(${this.field.parent.relative}) IN ${this.field.parent.path}` : `DISJOINT(${this.field.parent.relative})`,
                }
              });
            }
            break
          case 'check.create': {
              const section = this.schema.sections.find(x => x.label === 'named');
              this.$emit('create', {
                section,
                values: {
                  value: this.field.parent.path ? `CHECK(${this.field.parent.relative}) IN ${this.field.parent.path}` : `CHECK(${this.field.parent.relative})`,
                }
              });
            }
            break
        }
      } else if (this.update) {
        switch (this.update) {
          case '$properties':
            this.$emit('update', { id: this.editing, field: 'data_type', value: this.selected, link: true });
            break;
          case '$dependencies':
          case '$workflows':
            this.$emit('update', { id: this.editing, field: 'service', value: this.selected, link: true });
            break;
          case '$navigations':
            this.$emit('update', { id: this.editing, field: 'dst', value: this.selected, link: true });
            break;
          case '$source':
          case '$data_type':
          case '$return_type':
          case '$event_type':
          case '$state_type': {
              const ref = this.context.attribute(this.parent, 'Reference');
              if (ref != null) {
                this.$emit('update', { id: ref, field: 'name', value: this.selected, link: true });
              } else {
                const section = this.schema.attributes.find(x => x.category === 'Reference');
                this.$emit('create', { section, values: { name: this.selected } });
              }
            }
            break;
          case '$sink': {
              // TODO: Use different terminology for actions vs subprocesses, sink is different....
              const obj = this.context.get(this.parent);
              if (obj.category === 'Action') {
                const ref = this.context.attribute(this.parent, 'Reference');
                if (ref != null) {
                  this.$emit('update', { id: ref, field: 'name', value: this.selected, link: true });
                } else {
                  const section = this.schema.attributes.find(x => x.category === 'Reference');
                  this.$emit('create', { section, values: { name: this.selected } });
                }
              } else {
                const ref = this.context.attribute(this.parent, 'Proxy');
                if (ref != null) {
                  this.$emit('update', { id: ref, field: 'target', value: this.selected, link: true });
                } else {
                  const section = this.schema.attributes.find(x => x.category === 'Proxy');
                  this.$emit('create', { section, values: { target: this.selected } });
                }
              }
            }
            break;
          case '$target': {
              const ref = this.context.attribute(this.parent, 'Proxy');
              if (ref != null) {
                this.$emit('update', { id: ref, field: 'target', value: this.selected, link: true });
              } else {
                const section = this.schema.attributes.find(x => x.category === 'Proxy');
                this.$emit('create', { section, values: { target: this.selected } });
              }
            }
            break;
          case '$emits':
            this.$emit('link', { id: this.parent, src: this.parent, dst: this.selected, reset: true });
            break;
          case '$audit':
            this.$emit('update', {
              id: this.parent,
              field: 'audit',
              value: `${this.context.get(this.parent).values.audit || ''}{/${this.field.key.replaceAll('.', '/')}}`,
            });
            break;
          case '$summary.add': {
              const fields = (this.context.get(this.parent).values.summary || '').split(',').map(x => x.trim()).filter(x => x && x !== '*');
              fields.push(this.field.key);
              this.$emit('update', {
                id: this.parent,
                field: 'summary',
                value: fields.sort().join(','),
              });
            }
            break;
          case '$summary.remove': {
              const fields = (this.context.get(this.parent).values.summary || '').split(',').map(x => x.trim()).filter(x => x && x !== '*');
              fields.splice(fields.indexOf(this.field.key), 1);
              this.$emit('update', {
                id: this.parent,
                field: 'summary',
                value: fields.length ? fields.join(',') : '*',
              });
            }
            break;
          case '$summary.reset':
            this.$emit('update', { id: this.parent, field: 'summary', value: this.field.key || '*' });
            break
          case '$search.add': {
              const fields = (this.context.get(this.parent).values.search || '').split(' ').map(x => x.trim()).filter(x => x);
              fields.push(this.field.key);
              this.$emit('update', {
                id: this.parent,
                field: 'search',
                value: fields.sort().join(' '),
              });
            }
            break;
          case '$index.add':
          case '$entity.add': {
              const fields = (this.context.get(this.editing).values.fields || '').split(',').map(x => x.trim()).filter(x => x);
              fields.push(this.field.key);
              this.$emit('update', {
                id: this.editing,
                field: 'fields',
                value: this.update === '$index.add' ? fields.join(',') : fields.sort().join(','),
              });
            }
            break
          case '$search.remove': {
              const fields = (this.context.get(this.parent).values.search || '').split(' ').map(x => x.trim()).filter(x => x);
              fields.splice(fields.indexOf(this.field.key), 1);
              this.$emit('update', {
                id: this.parent,
                field: 'search',
                value: fields.length ? fields.join(' ') : null,
              });
            }
            break;
          case '$index.remove':
          case '$entity.remove': {
              const fields = (this.context.get(this.editing).values.fields || '').split(',').map(x => x.trim()).filter(x => x);
              fields.splice(fields.indexOf(this.field.key), 1);
              this.$emit('update', {
                id: this.editing,
                field: 'fields',
                value: fields.join(','),
              });
            }
            break
          case '$document.add': {
              const ref = this.context.attribute(this.parent, 'Document');
              const fields = (this.context.get(ref).values.fields || '').split(',').map(x => x.trim()).filter(x => x);
              fields.push(this.field.key);
              this.$emit('update', { id: ref, field: 'fields', value: fields.sort().join(',') });
            }
            break
          case '$document.remove':{
              const ref = this.context.attribute(this.parent, 'Document');
              const fields = (this.context.get(ref).values.fields || '').split(',').map(x => x.trim()).filter(x => x);
              fields.splice(fields.indexOf(this.field.key), 1);
              this.$emit('update', { id: ref, field: 'fields', value: fields.join(',') });
            }
            break
          case '$set': {
              const parts = [];
              const value = this.context.get(this.editing).values.value ?? '';
              FIELD.lastIndex = 0;
              let match = FIELD.exec(value)
              while (match) {
                if (match[1]) parts.push('UNSAFE');
                if (match[2]) parts.push('INLINE');
                if (match[3]) parts.push(`APPLY(${match[3]})`);
                if (match[6]) parts.push(`CHECK(${match[6]})`);
              }
              parts.push(`SET(${this.field.parts[this.field.parts.length-1]})`);
              this.$emit('update', {
                id: this.editing,
                field: 'value',
                value: parts.join(' '),
              });
            }
            break
          case '$distinct.update':
            this.$emit('update', {
              id: this.editing,
              field: 'value',
              value: this.field.parent?.path ? `DISTINCT(${this.field.parent.relative}) IN ${this.field.parent.path}` : `DISTINCT(${this.field.parent.relative})`,
            });
            break
          case '$disjoint.add': {
              const match = NAMED.exec(this.context.get(this.editing).values.value ?? '');
              const fields = match[2].split(',').map(x => x.trim());
              fields.push(this.field.parent.relative);
              this.$emit('update', {
                id: this.editing,
                field: 'value',
                value: this.field.parent.path ? `DISJOINT(${fields.sort().join(',')}) IN ${this.field.parent.path}` : `DISJOINT(${fields.sort().join(',')})`,
              });
            }
            break
          case '$disjoint.remove': {
              const match = NAMED.exec(this.context.get(this.editing).values.value ?? '');
              const fields = match[2].split(',').map(x => x.trim());
              fields.splice(fields.indexOf(this.field.parent.relative), 1);
              this.$emit('update', {
                id: this.editing,
                field: 'value',
                value: this.field.parent.path ? `DISJOINT(${fields.join(',')}) IN ${this.field.parent.path}` : `DISJOINT(${fields.join(',')})`,
              });
            }
            break
          case '$check.add': {
              const match = NAMED.exec(this.context.get(this.editing).values.value ?? '');
              this.$emit('update', {
                id: this.editing,
                field: 'value',
                value: this.field.parent.path ? `CHECK(${match[3] || ''}${this.field.parent.relative}) IN ${this.field.parent.path}` : `CHECK(${match[3] || ''}${this.field.parent.relative})`,
              });
            }
            break
          default: {
              // Must be replacing a field
              const fields = (this.context.get(this.editing).values.fields || '').split(',').map(x => x.trim()).filter(x => x);
              const i = fields.indexOf(this.update);
              if (i > -1) {
                fields.splice(i, 1, this.field.key);
                this.$emit('update', {
                  id: this.editing,
                  field: 'fields',
                  value: fields.join(','),
                });
              }
            }
            break;
        }
      }
      this.$emit('submit');
      UIkit.modal(this.$refs.context).hide();
    },
  },
  computed: {
    move() {
      return this.field == null && this.selected != null && this.context.get(this.selected).parent == this.parent;
    },
    targets() {
      if (this.captured.length && this.parent) {
        const obj = this.context.get(this.parent);
        return this.context.layers.get(null).categories[obj.category].filter(x => x.values).map(x => x.id);
      } else if (this.selected == null) {
        return [];
      } else {
        return this.context.targets(this.selected);
      }
    },
    sections() {
      return this.schema.sections.filter(x => !HIDDEN.has(x.category));
    },
    action() {
      if (this.name !== this.group) return true;
      else if (this.category) return true;
      else if (this.target !== this.parent) return true;
      else if (this.update) return true;
      else if (this.create) return true;
      else return false;
    },
    focused() {
      if (this.name !== this.group) return 'name';
      else if (this.category) return 'category';
      else if (this.target !== this.parent) return 'target';
      else if (this.update) return 'update';
      else if (this.create) return 'create';
      else return null;
    },
    properties() {
      const editing = this.context.get(this.editing);
      const section = editing == null ? null : editing.section.label;
      const parent = this.context.get(this.parent);
      const target = parent == null ? null : parent.section.label;
      const selected = this.context.get(this.selected);
      const src = selected == null ? null : selected.section.label;

      const properties = [];
      if (this.field) {
        if (target === 'events') {
          properties.push({
            name: 'audit',
            enabled: this.terminal,
          });
        }

        if (target === 'aggregate_roots') {
          const summary = new Set((parent.values.summary ?? '').split(',').map(x => x.trim()));
          properties.push({
            name: summary.has(this.field.key) ? 'summary.remove' : 'summary.add',
            enabled: this.field.key && !summary.has('*'),
          });
          properties.push({
            name: 'summary.reset',
            enabled: true,
          });
          if (parent.values.search) {
            const search = new Set((parent.values.search ?? '').split(' ').map(x => x.trim()));
            properties.push({
              name: search.has(this.field.key) ? 'search.remove' : 'search.add',
              enabled: this.terminal && this.field.key !== 'id',
            });
          }
          const ref = this.context.attribute(this.parent, 'Document');
          if (ref) {
            const document = new Set((this.context.get(ref).values.fields ?? '').split(',').map(x => x.trim()));
            properties.push({
              name: document.has(this.field.key) ? 'document.remove' : 'document.add',
              enabled: this.field.parts.length === 1 && this.field.key !== 'id',
            });
          }
        }

        if (section === 'field') {
          const value = editing.values.value ?? '';
          FIELD.lastIndex = 0;
          let match = FIELD.exec(value)
          while (match) {
            if (match[5]) {
              properties.push({
                name: 'set',
                enabled: this.terminal && this.field.parent?.depth === 1,
              });
            }
            match = FIELD.exec(value);
          }

          properties.push({
            name: 'check.add',
            enabled: this.terminal && this.field.parent?.depth === 0,
          });
        } else if (section === 'named') {
          const match = NAMED.exec(editing.values.value ?? '');
          if (match && match[1]) {
            properties.push({
              name: 'distinct.update',
              enabled: this.terminal && this.field.parent?.relative,
            });
          }
          if (match && match[2]) {
            properties.push({
              name: this.field.parent?.relative && match[2].split(',').map(x => x.trim()).includes(this.field.parent.relative) ? 'disjoint.remove' : 'disjoint.add',
              enabled: this.terminal && this.field.parent?.relative,
            });
          } else if (match && match[3]) {
            properties.push({
              name: 'check.add',
              enabled: this.terminal && this.field.parent?.relative,
            });
          }
        } else if (section === 'entities') {
          const entity = new Set((editing.values.fields ?? '').split(',').map(x => x.trim()));
          properties.push({
            name: entity.has(this.field.key) ? 'entity.remove' : 'entity.add',
            enabled: this.terminal && this.field.key !== 'id',
          });
        } else if (section === 'indexes') {
          const fields = (editing.values.fields ?? '').split(',').map(x => x.trim());
          const index = new Set(fields);
          if (index.has(this.field.key)) {
            properties.push({
              name: 'index.remove',
              enabled: this.terminal && this.field.key !== 'id',
            });
          } else {
            properties.push({
              name: 'index.add',
              enabled: this.terminal && this.field.key !== 'id',
            });
            for (const value of fields) {
              properties.push({
                name: 'index.replace',
                enabled: this.terminal && this.field.key !== 'id',
                value,
              });
            }
          }
        }
      } else {
        if (section === 'properties') {
          properties.push({
            name: 'properties',
            enabled: IS_PROPERTY.has(src),
          });
        } else if (section === 'dependencies') {
          properties.push({
            name: 'dependencies',
            enabled: IS_DEPENDENCY.has(src) || (target === 'actions' && src === 'commands'),
          });
        } else if (section === 'navigations') {
          properties.push({
            name: 'navigations',
            enabled: NAVIGABLE.has(src),
          });
        } else if (section === 'workflows') {
          properties.push({
            name: 'workflows',
            enabled: NAVIGABLE.has(src),
          });
        }

        if (target === 'actions' && parent.values.description == null) {
          properties.push({
            name: 'target',
            enabled: IS_DEPENDENCY.has(src) || src === 'commands',
          });
        } else if (PROXIABLE.has(target) && parent.values.description == null) {
          properties.push({
            name: 'target',
            enabled: IS_DEPENDENCY.has(src),
          });
        } else if (target === 'actions') {
          properties.push({
            name: 'sink',
            enabled: IS_SINK.has(src),
          });
        }

        if (HAS_DATA_TYPE.has(target)) {
          properties.push({
            name: 'data_type',
            enabled: src === 'data_types',
          });
        }

        if (target === 'subprocesses') {
          properties.push({
            name: 'event_type',
            enabled: src === 'events',
          });
        } else if (HAS_EVENT_TYPE.has(target)) {
          properties.push({
            name: 'emits',
            enabled: IS_EVENT.has(src),
          });
        }

        if (HAS_RETURN_TYPE.has(target)) {
          properties.push({
            name: 'return_type',
            enabled: IS_PROPERTY.has(src),
          });
        }

        if (target === 'processes') {
          properties.push({
            name: 'state_type',
            enabled: src === 'aggregate_roots',
          });
        }

        if (target === 'subscriptions') {
          properties.push({
            name: 'source',
            enabled: src === 'events',
          });
        }

        if (target === 'subprocesses') {
          properties.push({
            name: 'sink',
            enabled: src === 'commands',
          });
        }

        if (target === 'contexts') {
          properties.push({
            name: 'view',
            enabled: src === 'views',
          });
        }
      }
      return properties;
    },
    fields() {
      const parent = this.context.get(this.parent);
      const target = parent == null ? null : parent.section.label;
      const selected = this.context.get(this.selected);
      const src = selected == null ? null : selected.section.label;

      const fields = [];
      if (this.field) {
        const enabled = !this.context.isPrimitive(this.field.typeid);
        fields.push({
          name: 'disjoint.create',
          enabled,
        });
        fields.push({
          name: 'distinct.create',
          enabled,
        });
        fields.push({
          name: 'check.create',
          enabled,
        });
      } else {
        if (HAS_PROPERTIES.has(target) || (PROXIABLE.has(target) && parent.values.description != null)) {
          fields.push({
            name: 'properties',
            enabled: IS_PROPERTY.has(src),
          });
        }

        if (PROXIABLE.has(target) && parent.values.description != null) {
          fields.push({
            name: 'dependencies',
            enabled: IS_DEPENDENCY.has(src) || (target === 'actions' && src === 'commands'),
          });
        }

        if (PROXIABLE.has(target)) {
          fields.push({
            name: 'navigations',
            enabled: NAVIGABLE.has(src),
          });
          fields.push({
            name: 'workflows',
            enabled: NAVIGABLE.has(src),
          });
        }
      }

      return fields;
    },
    terminal() {
      if (!this.field) {
        return false;
      }
      const obj = this.context.get(this.field.typeid);
      return !obj
        || obj.category === 'Lookup'
        || obj.category === 'Regex'
        || (obj.category === 'AggregateRoot' && this.field.parts.length);
    },
    ...mapState("api", ["help"]),
  },
};
</script>