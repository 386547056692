<template>
  <div>
    <ObjectAttribute
      :context="context"
      label="data type"
      :value="name"
      :primitive="primitive"
      :required="required"
      @update="$emit('update', {field: 'name', value: $event, link: true})">
    </ObjectAttribute>
    <Settings v-if="schema && item && item.name"
      :error="error"
      :sections="schema.sections"
      :categories="categories"
      @focus="$emit('settings', { context: parent.id, fields: null, tree: item.name })">
    </Settings>
  </div>
</template>

<script>
import Settings from './Settings.vue'
import ObjectAttribute from './ObjectAttribute.vue';

function assign(src, dst) {
  dst.primitive = src.parent.category === 'Query' || src.parent.category === 'View';
  dst.required = src.parent.category !== 'Action';
  dst.name = src.item?.name ?? '';
  return dst;
}

export default {
  name: 'Reference',
  props: {
    parent: Object,
    version: Number,
    item: Object,
    categories: Object,
    error: Object,
    context: Object,
    schema: Object,
  },
  components: {
    Settings,
    ObjectAttribute,
  },
  data() {
    return assign(this, {});
  },
  methods: {
  },
  computed: {
  },
  watch: {
    version() {
      assign(this, this);
    },
  },
}
</script>