<template>
  <EditableItem
    :terminal="false"
    :hidden="false"
    :readonly="readonly"
    :name="item.name"
    :placeholder="$label('objects.unnamed', { label: $label(`objects.${label}`) })"
    :editing="editing"
    :attributes="attributes"
    :error="error"
    v-on:focus="$emit('focus')" v-on:edit="$emit('edit')"
    v-on:delete="$emit('delete')" v-on:center="$emit('center')">
    <template v-if="editing">
      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">{{ $label('objects.name') }} <span class="uk-text-meta"> {{ error.field('/name')
          }}</span></span>
          <input ref="name" class="uk-form-controls uk-inline uk-input" type="text" v-model="name"
            pattern="[a-z](?:_?[a-z0-9]+)*" :placeholder="$command('objects.name', { label: $label(`objects.${label}`) })"
            v-on:change="$emit('update', { field: 'name', value: name.trim().replace(/[\s-()+\.]/g, '_').replace(/__+/g, '_').toLowerCase() })"
            spellcheck="false" autocorrect="off" autocapitalize="off" autocomplete="off" v-bind:disabled="readonly">
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help('objects.name') }}
          </p>
        </label>
      </div>

      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">{{ $label('objects.claim') }} <span class="uk-text-meta"> {{ error.field('/claim')
          }}</span></span>
          <input ref="claim" class="uk-form-controls uk-inline uk-input" type="text" v-model="claim" pattern="[a-z]{3}"
            :placeholder="$command('objects.claim')" v-on:change="$emit('update', { field: 'claim', value: claim })"
            spellcheck="false" autocorrect="off" autocapitalize="off" autocomplete="off" v-bind:disabled="readonly">
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help('objects.claim') }}
          </p>
        </label>
      </div>

      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">{{ $label('objects.provider') }} <span class="uk-text-meta"> {{
            error.field('/provider') }}</span></span>

          <select class="uk-form-controls uk-inline uk-select" v-model="provider"
            v-on:change="$emit('update', { field: 'realm', value: provider })" v-bind:disabled="readonly">
            <option v-for="provider in providers" :value="provider.id" :key="provider.id" :disabled="!provider.id">{{ provider.values.name ||
              "new provider" }}</option>
          </select>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help('objects.provider') }}
          </p>
        </label>
      </div>

      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">{{ $label('objects.description') }} <span class="uk-text-meta"> {{
            error.field('/description') }}</span></span>
          <textarea ref="description" class="uk-form-controls uk-inline uk-textarea uk-width-expand uk-flex-auto"
            style="resize: vertical" rows="5" v-model="description"
            :placeholder="$command('objects.description', { label: $label(`objects.${label}`) })"
            v-on:change="$emit('update', { field: 'description', value: description })" v-bind:disabled="readonly">
          </textarea>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help(`objects.${label}`) }}
          </p>
        </label>
      </div>

      <div class="uk-margin">
        <label class="uk-form-label">{{ $label(`objects.requirements.${label}`) }}</label>
        <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          {{ $help(`objects.requirements.${label}`) }}
        </p>
        <div class="uk-form-controls ">
          <ul class="uk-list" v-if="dependencies.length">
            <li v-for="dependency in dependencies" :key="dependency.id">
              <EditableItem :linking="true" :terminal="true" :readonly="readonly" :editing="false" :name="dependency.values.name"
                :placeholder="$label('objects.unnamed', { label: $label(`objects.${dependency.section.label}`) })"
                :attributes="dependency.attributes"
                v-on:focus="$emit('focus', { id: dependency.id })" v-on:edit="$emit('edit', { id: dependency.id })"
                v-on:delete="$emit('delete', { id: dependency.id })" v-on:center="$emit('center', { id: dependency.id })"
                v-on:unlink="$emit('unlink', dependency.id)">
                <p v-if="dependency.values.description">{{ dependency.values.description }}</p>
                <p class="uk-text-italic uk-text-center" v-else>
                  {{ $message('objects.missingattr', {
                    label: $label(`objects.${dependency.section.label}`), attr:
                      $label('objects.description')
                  }) }}
                </p>
              </EditableItem>
            </li>
          </ul>
          <div class="uk-text-italic uk-text-center" v-else>
            {{ $message('objects.missingattr', {
              label: $label(`objects.${label}`), attr:
                $label(`objects.requirements.${label}`)
            }) }}
          </div>
        </div>
      </div>
    </template>
    <p v-else-if="description">{{ description }}</p>
    <p class="uk-text-italic uk-text-center" v-else>
      {{ $message('objects.missingattr', { label: $label(`objects.${label}`), attr: $label('objects.description') }) }}
    </p>
  </EditableItem>
</template>

<script>
import { mapState } from 'vuex';
import EditableItem from '@/components/canvas/EditableItem.vue';

function assign(src, dst) {
  const dependencies = [];
  src.references.forEach((value, key) => {
    if (value) dependencies.push(src.context.get(key) ?? {
      id: key,
      values: {
        name: key.toString(),
        description: null,
      },
      section: {
        label: 'object',
      },
    });
  });
  dependencies.sort((a, b) => a.values.name.localeCompare(b.values.name));
  const selected = src.context.get(src.item.realm ?? '');
  const providers = selected ? [...src.categories['Provider']] : [{
    id: '',
    values: {
      name: src.item.realm ?? '-- select --',
    },
  }, ...src.categories['Provider']];
  const attributes = src.attribute ? [src.attribute] : [];
  if (src.group) attributes.push(src.group);

  dst.attributes = attributes;
  dst.name = src.item.name ?? '';
  dst.claim = src.item.claim ?? '';
  dst.provider = selected ? selected.id : (src.item.realm ?? '');
  dst.description = src.item.description ?? '';
  dst.dependencies = dependencies;
  dst.providers = providers;
  dst.error = {
    invalid: src.errors.length,
    msg: src.errors.length ? 'invalid data' : null,
    local: [],
    fields: new Map(),
    field(src) {
      return this.fields.get(src) ||'';
    },
  }
  for (const {property, detail} of src.errors) {
    switch (property) {
      case '/name':
      case '/claim':
      case '/description':
      case '/provider':
        dst.error.fields.set(property, detail);
        break;
      default:
        dst.error.local.push(detail);
        break;
    }
  }

  return dst;
}

export default {
  name: 'Realm',
  props: {
    version: Number,
    item: Object,
    category: String,
    label: String,
    errors: Array,
    editing: Boolean,
    context: Object,
    categories: Object,
    attribute: String,
    references: Map,
    readonly: Boolean,
  },
  components: {
    EditableItem
  },
  data() {
    return assign(this, {});
  },
  mounted() {
  },
  beforeDestroy() {
  },
  watch: {
    version() {
      assign(this, this);
    },
  },
  computed: {
    ...mapState('api', ['help']),
  },
  methods: {
  },
}
</script>
