<template>
  <EditableItem
    :terminal="true"
    :readonly="readonly"
    :hidden="hidden"
    :id="item.id"
    :name="name"
    placeholder="new option"
    :editing="editing"
    :attributes="attributes"
    :error="error"
    v-on:edit="$emit('edit')"
    v-on:delete="$emit('delete')"
    v-on:center="$emit('center', item.data_type)">
    <template v-if="editing">
      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">name <span class="uk-text-meta"> {{ error.field('/name') }}</span></span>
          <input
            ref="name"
            class="uk-form-controls uk-inline uk-input"
            type="text"
            pattern="[a-z](?:_?[a-z0-9]+)*"
            :placeholder="$command('objects.name', { label: 'option' })"
            autofocus
            v-on:change="$emit('update', {field: 'name', value: name.trim().replace(/[\s-()+\.]/g, '_').replace(/__+/g, '_').toLowerCase()})"
            v-model="name"
            spellcheck="false"
            autocorrect="off"
            autocapitalize="off"
            autocomplete="off"
            v-bind:disabled="readonly">
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Enter a short, unique, and memorable name. The name must start with a
            lowercase letter ("a" to "z"), and can contain lowercase letters, numbers
            ("0" to "9"), with an underscore ("_") between words.
          </p>
        </label>
      </div>
      <div class="uk-margin">
        <div class="uk-form-label">data type <span class="uk-text-meta"> {{ error.field('/data_type') }}</span></div>
        <div class="uk-form-controls">
          <div class="uk-flex uk-flex-middle">
            <select v-model="data_type" class="uk-select uk-form-width-small"
              v-on:change="$emit('update', {field: 'data_type', value: data_type})"
              v-bind:disabled="readonly">
                <option :value="item.value" v-for="item in context.primitives" :key="item.value">{{ item.description }}</option>
                <option value="LOOKUP" v-if="context.lookups()">lookup</option>
            </select>
            <span class="uk-flex-auto uk-margin-left uk-margin-right">
              {{ context.label(data_type) }}
            </span>
          </div>
        </div>
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          Select a data type for this option
        </p>
      </div>
      <div class="uk-margin">
        <label>
          <input class="uk-radio" type="radio" :value="0" v-model="cardinality"
            v-on:change="$emit('update', {field: 'cardinality', value: '0'})"
            v-bind:disabled="readonly">
          <span class="uk-margin-small-left uk-margin-small-right uk-form-label" style="display: inline-block">optional</span>
          <p class="uk-margin-remove-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Optional fields can have one or no value
          </p>
        </label>
        <label>
          <input class="uk-radio" type="radio" :value="1" v-model="cardinality"
            v-on:change="$emit('update', {field: 'cardinality', value: '1'})"
            v-bind:disabled="readonly">
          <span class="uk-margin-small-left uk-margin-small-right uk-form-label" style="display: inline-block">required</span>
          <p class="uk-margin-remove-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Required fields have exactly one value
          </p>
        </label>
      </div>
      <div class="uk-margin" v-if="custom">
        <div class="uk-form-label">metadata</div>
        <div class="uk-form-controls">
          <TypeMetadata :version="version"
            :context="context"
            :typeid="item.data_type"
            :special="special"
            :metadata="metadata"
            :help="help"
            :readonly="readonly"
            v-on:update="$emit('update', $event)"
            v-on:center="$emit('center', $event)">
          </TypeMetadata>
        </div>
      </div>
      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">example <span class="uk-text-meta"> {{ error.field('/example') }}</span></span>
          <input
            class="uk-form-controls uk-inline uk-input"
            type="text"
            placeholder="enter an example value"
            v-on:change="$emit('update', {field: 'example', value: example || null})"
            v-model="example"
            spellcheck="false"
            autocorrect="off"
            autocapitalize="off"
            autocomplete="off"
            v-bind:disabled="readonly">
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Enter an example value to help system administrators understand the option
          </p>
        </label>
      </div>
      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <div class="uk-form-label">description <span class="uk-text-meta"> {{ error.field('/description') }}</span></div>
          <textarea
            ref="description"
            class="uk-form-controls uk-inline uk-textarea uk-width-expand uk-flex-auto"
            style="resize: vertical"
            rows="5"
            v-model="description"
            :placeholder="$command('objects.description', { label: 'option' })"
            v-on:change="$emit('update', {field: 'description', value: description})"
            v-bind:disabled="readonly">
          </textarea>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Enter a brief summary of the data contained in this option. This information
            helps users understand how to interpret the field.
          </p>
        </label>
      </div>
    </template>
    <p v-else-if="description">{{ description }}</p>
    <p class="uk-text-italic uk-text-center" v-else>this property has no description</p>
  </EditableItem>
</template>

<script>
import { mapState } from 'vuex';
import EditableItem from '@/components/canvas/EditableItem.vue';
import TypeMetadata from '@/components/canvas/entities/TypeMetadata.vue';

function assign(src, dst) {
  const cardinality = src.item.cardinality ?? '1';
  const attributes = [];
  if (src.item.data_type) {
    let label = src.context.label(src.item.data_type);
    if (src.context.isPrimitive(src.item.data_type))
    {
      if (src.item.special)
      {
        label = `${label}(${src.item.special})`;
      }
      if (src.item.metadata)
      {
        label = `${label} ${src.item.metadata}`;
      }
    }
    switch (cardinality) {
      case '0':
        attributes.push(`${label} (optional)`);
        break;
      case '1':
        attributes.push(label);
        break;
      default:
        attributes.push(`Collection of ${label}`);
        break;
    }
  } else {
    attributes.push('awaiting data type');
  }
  dst.name = src.item.name || '';
  dst.example = src.item.example || '';
  dst.description = src.item.description || '';
  dst.data_type = src.item.data_type || '';
  dst.attributes = attributes;
  dst.cardinality = cardinality;
  dst.special = src.item.special ? {
    value: src.item.special,
    typeid: src.item.data_type,
  } : null;
  dst.metadata = src.item.metadata ? {
    value: src.item.metadata,
    typeid: src.item.data_type,
  } : null;
  dst.custom = (dst.special && dst.special.typeid === src.item.data_type)
    || (dst.metadata && dst.metadata.typeid === src.item.data_type)
    || src.item.data_type === 'STRING'
    || src.item.data_type === 'INTEGER'
    || src.item.data_type === 'FLOAT'
    || src.item.data_type === 'DECIMAL'
    || src.item.data_type === 'DATETIME'
    || src.item.data_type === 'LOOKUP'
    || src.item.data_type === 'ATTACHMENT';
  dst.error = {
    invalid: src.errors.length,
    msg: src.errors.length ? 'invalid data' : null,
    local: [],
    fields: new Map(),
    field(src) {
      return this.fields.get(src) ||'';
    },
  }

  dst.custom = (dst.special && dst.special.typeid === src.item.data_type)
    || (dst.metadata && dst.metadata.typeid === src.item.data_type)
    || src.item.data_type === 'STRING'
    || src.item.data_type === 'INTEGER'
    || src.item.data_type === 'FLOAT'
    || src.item.data_type === 'DECIMAL'
    || src.item.data_type === 'DATETIME'
    || src.item.data_type === 'LOOKUP'
    || src.item.data_type === 'ATTACHMENT';

  dst.hidden = !src.item.data_type || src.context.isPrimitive(src.item.data_type);
  for (const {property, detail} of src.errors) {
    switch (property) {
      case '/name':
      case '/data_type':
      case '/example':
      case '/description':
        dst.error.fields.set(property, detail);
        break;
      default:
        dst.error.local.push(detail);
        break;
    }
  }
  return dst;
}

export default {
  name: 'Option',
  props: {
    version: Number,
    item: Object,
    errors: Array,
    editing: Boolean,
    context: Object,
    categories: Object,
    attribute: String,
    readonly: Boolean,
  },
  components: {
    EditableItem,
    TypeMetadata,
  },
  data() {
    return assign(this, {});
  },
  mounted() {
    if (this.validate) {
      this.$refs.description.reportValidity();
      this.$refs.name.reportValidity();
    }
  },
  computed: {
    ...mapState('api', ['help']),
  },
  watch: {
    version() {
      assign(this, this);
    },
    validate() {
      if (this.validate) {
        this.$refs.description.reportValidity();
        this.$refs.name.reportValidity();
      }
    },
  }
}
</script>