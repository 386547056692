<template>
  <div>
    <ul class="uk-list">
      <li v-for="(mapping, index) in mappings" :key="index">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label" v-if="mapping.name">{{ mapping.name || `new ${ $label('objects.property') }` }} </span>
          <span class="uk-form-label uk-text-italic" v-else>unnamed</span>
          <select class="uk-form-controls uk-inline uk-select" v-model="mapping.value" v-on:change="doUpdate">
            <option value=""></option>
            <option v-for="(field, i) in fields" :value="field.value" :key="i">{{ field.label }}</option>
          </select>
        </label>
      </li>
    </ul>
    <p class="uk-margin-small-top uk-text-right uk-text-meta
          uk-margin-remove-bottom uk-animation-fade" v-if="help">
      Rename input fields for the proxied object.
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const REGEX = /\s*([a-zA-Z0-9_]+)\s*=\s*([a-zA-Z0-9_]*)?\s*/g;

function assign(src, dst) {
  const seen = new Set();
  if (src.item.context) {
    REGEX.lastIndex = 0;
    let match = REGEX.exec(src.item.context);
    while (match) {
      seen.add(match[2]);
      match = REGEX.exec(src.item.context);
    }
  }

  // Fields are the target fields in the destination object (ignoring already mapped context fields)
  const fields = [];
  for (const field of src.structure.fields) {
    if (seen.add(field.value)) {
      fields.push(field);
    }
  }

  // Mappings are all output fields (real or imagined) in the source object.
  const mappings = [];
  const named = new Map();
  for (const field of src.item.src ? src.structure.tree.find(x => x.value == src.item.src)?.children ?? [] : src.structure.tree) {
    if (!named.has(field.value)) {
      const mapping = {
        name: field.value,
        value: '',
      };
      named.set(field.value, mapping);
      mappings.push(mapping);
    }
  }

  const value = src.item.body ?? '';
  REGEX.lastIndex = 0;
  let match = REGEX.exec(value);
  while (match) {
    if (named.has(match[1])) named.get(match[1]).value = match[2];
    else if (match[2]) {
      const mapping = {
        name: match[1],
        value: match[2],
      };
      named.set(match[1], mapping);
      mappings.push(mapping);
      if (seen.add(match[2])) fields.push({ label: match[2], value: match[2] })
    }
    match = REGEX.exec(value);
  }
  dst.fields = fields;
  dst.mappings = mappings;
  return dst;
}

export default {
  name: 'MappingBody',
  props: {
    parent: Object,
    version: Number,
    item: Object,
    error: Object,
    editing: Boolean,
    context: Object,
    categories: Object,
    attribute: String,
    structure: Object,
  },
  components: {
  },
  data() {
    return assign(this, {});
  },
  mounted() {
  },
  computed: {
    ...mapState('api', ['help']),
  },
  watch: {
    version() {
      assign(this, this);
    },
  },
  methods: {
    doUpdate() {
      this.$emit('update', this.mappings.filter(x => x.value).map(x => `${x.name}=${x.value}`).join(' ') || null);
    },
  }
}
</script>