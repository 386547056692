<template>
  <select v-model="value" class="uk-select" @change="$emit('update', value || null)" :readonly="readonly">
      <option value="">default</option>
      <option value="INT">32-bit integer</option>
      <option value="BIGINT">64-bit integer</option>
      <option value="TIMEUUID">Version 1 UUID</option>
  </select>
</template>

<script>
export default {
  name: 'KeyType',
  props: {
    version: Number,
    item: Object,
    category: String,
    label: String,
    readonly: Boolean,
  },
  components: {
  },
  data() {
    return {
      value: this.item.key_type ?? '',
    }
  },
  methods: {
  },
  computed: {
  },
  watch: {
    version() {
      this.value = this.item.key_type ?? '';
    },
  },
}
</script>