<template>
  <section ref="container" class="uk-container uk-container-small uk-light" uk-overflow-auto>
    <dl class="uk-text-right" v-if="layer.upstream">
      <dt>page</dt>
      <dd><a :href="layer.upstream.url" target="_blank">{{ layer.upstream.title }}</a></dd>
    </dl>
    <h3 class="uk-margin-remove uk-inline">{{ $label(`title.changes`) }}</h3>
    <ol class="uk-list uk-light uk-margin-remove-top">
      <template v-for="step in steps">
      <li :key="step.category" :id="step.category" v-if="step.status != 'automerge'">
        <EditableItem :terminal="true"
          :hidden="true"
          :readonly="true"
          :name="$label(`title.${step.label}`)"
          :editing="step === active"
          :attributes="step.status ? [ step.status ] : [ 'awaiting merge' ]"
          :error="null"
          v-on:edit="$emit('edit', step)">
          <p class="uk-margin-remove">{{ $message('objects.affected', { count: step.pairs.length }) }}</p>
        </EditableItem>
      </li>
      </template>
    </ol>
  </section>
</template>

<script>
import UIkit from 'uikit';
import EditableItem from '@/components/canvas/EditableItem.vue';

export default {
  name: 'MergeContext',
  props: {
    context: Object,
    layer: Object,
    steps: Array,
    active: Object,
  },
  components: {
    EditableItem,
  },
  data() {
    return {
      scroll: UIkit.scroll(this.$refs.container, {offset: 160}),
      i: 0,
    }
  },
  async mounted() {
    this.$nextTick(() => {
      this.$emit('edit', this.steps[this.i]);
    });
  },
  methods: {
    scrollIntoView() {
      this.$nextTick(() => {
        if (this.selected && this.selected.category) {
          const selector = `#${this.selected.category}`;
          const target = document.querySelector(`${selector} [data-edit]`);
          if (target) {
            target.focus();
            this.scroll.scrollTo(selector);
          }
        }
      });
    },
    progress() {
      this.$nextTick(() => {

        this.$emit('edit', this.steps[this.i]);
      //   for (let j = 0; j < stage.pairs.length; j++) {
      //     const { upstream, downstream } = stage.pairs[j];
      //     if (downstream == null) {
      //       // Case 1: new object
      //       stage.changes.push({ upstream, downstream: null })
      //     } else if (upstream == null && downstream.upstream != null) {
      //       // Case 2: deleted in upstream
      //     } else if (upstream == null) {
      //       // Case 3: created in downstream
      //     } else if (downstream.upstream != null) {
      //       // Case 4: updated in both
      //     } else {
      //       // Case 5: created in both
      //     }

      //   }
      //   for (const { upstream, downstream } of stage.pairs) {
      //   }
      //   stage.status = 'ok';
      //   this.i++;
      //   if (this.i < this.steps.length) {
      //     this.progress();
      //   }
      });
    },
  }
}
</script>