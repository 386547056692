<template>
  <input
    class="uk-form-controls uk-inline uk-input"
    type="number"
    :placeholder="$message('objects.' + name)"
    min="1"
    v-model="modified"
    v-on:change="$emit('update', modified || null)">
</template>

<script>
export default {
  name: 'ObjectValue',
  props: {
    version: Number,
    name: String,
    item: Object,
  },
  data() {
    return {
      modified: this.item[this.name] || null,
    }
  },
  watch: {
    version() {
      this.modified = this.item[this.name] || null;
    },
  },
}
</script>