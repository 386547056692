<template>
  <div>
    <ul class="uk-list">
      <li v-for="(mapping, index) in mappings" :key="index">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label" v-if="mapping.name">{{ mapping.name || `new ${ $label('objects.property') }` }} </span>
          <span class="uk-form-label uk-text-italic" v-else>unnamed</span>
          <ObjectField
            :value="mapping.value"
            :disabled="!mapping.name"
            v-on:update="doUpdate(mapping, $event)"></ObjectField>
        </label>
      </li>
    </ul>
    <p class="uk-margin-small-top uk-text-right uk-text-meta
          uk-margin-remove-bottom uk-animation-fade" v-if="help">
      Rename input fields for the proxied object.
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ObjectField from './ObjectField.vue';

const REGEX = /s*([a-zA-Z0-9_]+)\s*=\s*([a-zA-Z0-9_]*)?\s*/g;

function assign(src, dst) {
  const mappings = [];
  const named = new Map();
  for (const field of src.structure.fields) {
    if (!named.has(field.value)) {
      const mapping = {
        name: field.value,
        value: '',
      };
      named.set(field.value, mapping);
      mappings.push(mapping);
    }
  }
  const value = src.item?.renamed ?? '';
  REGEX.lastIndex = 0;
  let match = REGEX.exec(value);
  while (match) {
    if (named.has(match[1])) named.get(match[1]).value = match[2];
    else if (match[2]) {
      const mapping = {
        name: match[1],
        value: match[2],
      };
      named.set(match[1], mapping);
      mappings.push(mapping);
    }
    match = REGEX.exec(value);
  }
  dst.mappings = mappings;
  return dst;
}

export default {
  name: 'Renamed',
  props: {
    version: Number,
    item: Object,
    structure: Object,
  },
  components: {
    ObjectField,
  },
  data() {
    return assign(this, {});
  },
  methods: {
    doUpdate(mapping, value) {
      mapping.value = value;
      this.$emit('update', this.mappings.filter(x => x.value).map(x => `${x.name}=${x.value}`).join(' '));
    },
  },
  computed: {
    ...mapState('api', ['help']),
  },
  watch: {
    version() {
      assign(this, this);
    },
  },
}
</script>