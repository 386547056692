<template>
  <li :class="{'uk-active': active === selector}">
    <a href="#" v-on:click.prevent="$emit('focus', selector)"><i :uk-icon="selector.icon" v-if="selector.icon"></i> {{ selector.layer.title }}</a>
    <ul v-if="selector.child">
      <NavHeaderItem
        :selector="selector.child"
        :active="active"
        v-on:focus="$emit('focus', $event)">
      </NavHeaderItem>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'NavHeaderItem',
  props: {
    selector: Object,
    active: Object,
  }
};
</script>