<template>
  <EditableItem
    :terminal="true"
    :readonly="readonly"
    :hidden="true"
    :id="item.id"
    :name="context.label(service)"
    placeholder="new workflow"
    :editing="editing"
    :attributes="[]"
    :error="error"
    v-on:edit="$emit('edit')"
    v-on:delete="$emit('delete')"
    v-on:center="$emit('center', item.data_type)">
    <template v-if="editing">
      <label class="uk-flex uk-flex-column">
        <div class="uk-form-label">service <span class="uk-text-meta"> {{ error.get('/service') }}</span></div>
        <div class="uk-form-controls">
          <ObjectAttribute :context="context" label="target" :value="service" :required="true" readonly="readonly"></ObjectAttribute>
        </div>
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          Select the related service
        </p>
      </label>
      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <div class="uk-form-label">description <span class="uk-text-meta"> {{ error.field('/description') }}</span></div>
          <textarea
            ref="description"
            class="uk-form-controls uk-inline uk-textarea uk-width-expand uk-flex-auto"
            style="resize: vertical"
            rows="5"
            v-model="description"
            :placeholder="$command('objects.description', { label: 'workflow' })"
            v-on:change="$emit('update', {field: 'description', value: description})"
            v-bind:disabled="readonly">
          </textarea>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Enter a brief summary of the pre-conditions for this workflow. This information
            helps users know when to follow the step.
          </p>
        </label>
      </div>
    </template>
    <p v-else-if="description">{{ description }}</p>
    <p class="uk-text-italic uk-text-center" v-else>this workflow has no description</p>
  </EditableItem>
</template>

<script>
import { mapState } from 'vuex';
import EditableItem from '@/components/canvas/EditableItem.vue';
import ObjectAttribute from './ObjectAttribute.vue';

function assign(src, dst) {
  dst.service = src.item.service || '';
  dst.description = src.item.description || '';
  dst.error = {
    invalid: src.errors.length,
    msg: src.errors.length ? 'invalid data' : null,
    local: [],
    fields: new Map(),
    field(src) {
      return this.fields.get(src) ||'';
    },
  }
  for (const {property, detail} of src.errors) {
    switch (property) {
      case '/service':
      case '/description':
        dst.error.fields.set(property, detail);
        break;
      default:
        dst.error.local.push(detail);
        break;
    }
  }
  return dst;
}

export default {
  name: 'Workflow',
  props: {
    version: Number,
    item: Object,
    errors: Array,
    editing: Boolean,
    context: Object,
    categories: Object,
    attribute: String,
    readonly: Boolean,
  },
  components: {
    EditableItem,
    ObjectAttribute,
  },
  data() {
    return assign(this, {});
  },
  mounted() {
  },
  computed: {
    ...mapState('api', ['help']),
  },
  watch: {
    version() {
      assign(this, this);
    },
    validate() {
    },
  }
}
</script>