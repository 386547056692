<template>
  <defs>
    <filter id="connector" x="-20%" y="-20%" width="200%" height="200%">
        <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
    </filter>
    <filter id='shadow' color-interpolation-filters="sRGB">
        <feDropShadow dx="2" dy="2" stdDeviation="1" flood-opacity="0.5"/>
    </filter>
    <g id="portal-element">
        <circle
            cx="35.706825" cy="23.865953" r="22.808876"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <ellipse
            cx="35.706825" cy="23.865953" rx="12.473604" ry="22.808876"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="35.706825" y1="1.0571147" x2="35.706825" y2="46.674793"
            style="fill:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="13.788958" y1="18.995316" x2="57.656322" y2="18.887377"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="13.621617" y1="28.023813" x2="57.746796" y2="28.023203"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="17.5256" y1="37.052311" x2="53.947746" y2="37.15905"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="18.068323" y1="9.9668188" x2="53.40501" y2="9.9324493"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <path
            d="m 5.466924,23.220764 c -2.16703,0 -3.94006,1.7533 -3.94006,3.94002 v 27.58026 c 0,2.16702 1.77303,3.94004 3.94006,3.94004 h 27.58025 c 2.16701,0 3.94002,-1.77302 3.94002,-3.94004 v -27.58026 c 0,-2.18672 -1.77301,-3.94002 -3.94002,-3.94002 h -19.70019 z"
            fill="currentColor"
            style="stroke-width:1;stroke-linecap:square;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"/>
    </g>
    <g id="data-element">
        <line
            x1="33.573124" y1="10.494431" x2="27.168583" y2="17.610458"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="20.221622" y1="25.329361" x2="13.817264" y2="32.445404"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="40.40955" y1="10.591339" x2="46.293461" y2="17.51355"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="26.895451" y1="25.559219" x2="33.846378" y2="34.440781"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="33.573124" y1="42.389542" x2="27.168583" y2="49.505569"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="40.40955" y1="42.48645" x2="46.293461" y2="49.408646"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            cx="37.046574" cy="6.634964" r="5.1922355"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            cx="23.695255" cy="21.46991" r="5.1922355"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            cx="10.343692" cy="36.304825" r="5.1922355"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            cx="49.656315" cy="21.46991" r="5.1922355"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            cx="37.046574" cy="38.53009" r="5.1922355"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            cx="23.695255" cy="53.365036" r="5.1922355"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            cx="49.656315" cy="53.365036" r="5.1922355"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
    </g>
    <g id="clipboard">
        <path
            fill="currentColor"
            style="stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 9.02526,13.34474 h 28 c 2.20914,0 4,1.79088 4,4 v 31.99998 c 0,2.20916 -1.79086,4 -4,4 h -28 c -2.20916,0 -4,-1.79084 -4,-4 V 17.34474 c 0,-2.20912 1.79084,-4 4,-4 z" />
        <circle
            fill="currentColor"
            style="stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
            r="3.5000055" cy="13.3" cx="23.025257" />
        <line
            style="stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1"
            y2="24.844715" x2="33.885487" y1="24.844715" x1="11.025257" />
        <line
            style="stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1"
            y2="31.339771" x2="33.885487" y1="31.339771" x1="11.025257" />
        <line
            style="stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1"
            y2="37.834797" x2="29.025257" y1="37.834797" x1="11.025257" />
        <line
            style="stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1"
            y2="44.329823" x2="29.025257" y1="44.329823" x1="11.025257" />
    </g>
    <g id="Portal" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <circle
            cx="30" cy="30" r="28.474609"
            fill="currentColor"
            style="stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <ellipse
            cx="30" cy="30" rx="15.572052" ry="28.474609"
            fill="currentColor"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="30" y1="1.5254364" x2="30" y2="58.474564"
            fill="currentColor"
            style="fill:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="2.6377258" y1="23.919495" x2="57.401764" y2="23.784744"
            fill="currentColor"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="2.4288177" y1="35.190674" x2="57.514709" y2="35.189911"
            fill="currentColor"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="7.3025513" y1="46.461853" x2="52.771973" y2="46.595108"
            fill="currentColor"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="7.9800873" y1="12.648315" x2="52.094421" y2="12.605408"
            fill="currentColor"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
    </g>
    <g id="Engine" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <path
            d="m 8.90014,14.20002 c -2.1208,-11.49055 7.9698,-13.7962 13.6,-11 5.6301,2.79623 20.2226,13.8 25.2,17.4 4.9773,3.6 4.0641,8.66415 1.8,12.4 -2.8009,4.6214 -7.4529,13.1283 -11.6,19.6 -4.1472,6.4717 -15.6038,6.72454 -19.4,0.6 -3.7963,-6.12453 -7.4793,-27.50943 -9.6,-39 z"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            style="stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            fill="currentColor"
            r="9" cy="10.999998" cx="17.500002" />
        <circle
            style="stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:stroke fill markers"
            fill="currentColor"
            r="4" cy="10.999998" cx="17.500002" />
        <circle
            style="stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            fill="currentColor"
            r="11.5" cy="46.499996" cx="28.000002" />
        <circle
            style="stroke-width:8;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:stroke fill markers"
            fill="currentColor"
            r="5" cy="46.499996" cx="28.000002" />
        <circle
            style="stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            fill="currentColor"
            r="7" cy="26.999998" cx="44.5" />
        <circle
            style="stroke-width:3.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:stroke fill markers"
            fill="currentColor"
            r="3" cy="26.999998" cx="44.5" />
    </g>
    <g id="Domain" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <path
            fill="currentColor"
            d="m 4.09367,24.91585 c 4.50196,1.11558 6.18271,7.65731 11.9312,7.65731 5.74849,0 7.39151,-5.31385 11.20617,-6.75466 -3.96185,-0.91393 -5.18692,-8.58795 -11.02817,-8.38187 -5.84115,0.20602 -7.31593,6.03858 -12.1092,7.47922 z"
            style="stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
        <path
            fill="currentColor"
            d="m 30,2.4305 c -1.64712,3.90386 -7.38038,5.71969 -7.38038,11.10137 0,5.27822 5.38298,7.26011 7.38038,10.50138 1.9974,-3.24127 7.38038,-5.22316 7.38038,-10.50138 C 37.38038,8.15019 31.64712,6.33436 30,2.4305 Z"
            style="stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
        <path
            fill="currentColor"
            d="m 14.19058,9.77195 c 0.83407,1.14454 -0.0568,3.00457 1.28783,4.13286 1.34469,1.12823 3.0287,-0.007 4.20372,0.40412 -0.7473,-0.99138 0.21554,-2.81144 -1.19119,-3.90974 -1.40674,-1.09826 -2.89647,-0.0234 -4.30036,-0.62724 z"
            style="stroke-width:1;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <path
            fill="currentColor"
            d="m 27.11937,29.46275 -2.74752,1.59726 3.14286,5.18315 0.21456,6.93935 c -1.13248,0.42415 -2.14881,1.08962 -2.974,1.94492 -0.0792,-0.002 -0.15668,-0.0119 -0.23641,-0.0119 -4.41729,0 -8.01411,3.59683 -8.01411,8.01411 0,1.73324 0.56011,3.33586 1.4999,4.64875 -0.23892,-0.70327 -0.36235,-1.44058 -0.36554,-2.18332 -2.7e-4,-3.09661 2.06841,-5.81174 5.05402,-6.6334 -0.1223,0.55537 -0.19071,1.13095 -0.19071,1.72241 0,1.73223 0.55912,3.3343 1.49793,4.64676 -0.23803,-0.70274 -0.36081,-1.43937 -0.36357,-2.18133 1.09187,-9.65519 12.51427,-7.31836 12.72644,0 -0.003,0.74196 -0.12553,1.47859 -0.36355,2.18133 0.9388,-1.31246 1.49793,-2.91453 1.49793,-4.64676 0,-0.59146 -0.0685,-1.16704 -0.19072,-1.72241 2.98561,0.82166 5.05429,3.53679 5.05402,6.6334 -0.003,0.74274 -0.12663,1.48005 -0.36555,2.18332 0.9398,-1.31289 1.49992,-2.91551 1.49992,-4.64875 0,-4.41728 -3.59683,-8.01411 -8.01411,-8.01411 -0.0797,0 -0.15718,0.0102 -0.23642,0.0119 -0.82519,-0.8553 -1.84152,-1.52077 -2.974,-1.94492 l 0.21455,-6.93935 3.14288,-5.18315 -2.74754,-1.59726 c 0,0 -0.7955,4.67998 -2.88063,5.20302 -2.08513,-0.52304 -2.88063,-5.20302 -2.88063,-5.20302 z"
            style="stroke-width:1;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
        <path
            fill="currentColor"
            d="m 55.90633,24.91585 c -4.50195,1.11558 -6.18271,7.65731 -11.9312,7.65731 -5.74848,0 -7.3915,-5.31385 -11.20616,-6.75466 3.96184,-0.91393 5.18691,-8.58795 11.02816,-8.38187 5.84115,0.20602 7.31593,6.03858 12.1092,7.47922 z"
            style="stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
        <path
            fill="currentColor"
            d="m 45.80942,9.77195 c -0.83407,1.14454 0.0568,3.00457 -1.28783,4.13286 -1.34468,1.12823 -3.02869,-0.007 -4.20372,0.40412 0.74731,-0.99138 -0.21554,-2.81144 1.1912,-3.90974 1.40673,-1.09826 2.89647,-0.0234 4.30035,-0.62724 z"
            style="stroke-width:1;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
    </g>
    <g id="DataType" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <use xlink:href="#data-element"/>
    </g>
    <g id="Prototype" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <line
            x1="24.02709" y1="14.37028" x2="18.895132" y2="20.072308"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="13.328542" y1="26.257427" x2="8.1967669" y2="31.959484"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="29.505056" y1="14.447917" x2="34.219776" y2="19.994671"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="18.676321" y1="26.441601" x2="24.246023" y2="33.558361"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="24.02709" y1="39.927685" x2="18.895132" y2="45.629711"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <line
            x1="29.505056" y1="40.005322" x2="34.219776" y2="45.552044"
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            cx="26.810354" cy="11.27769" r="4.1605139"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            cx="16.11199" cy="23.164867" r="4.1605139"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            cx="5.4134417" cy="35.052013" r="4.1605139"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            cx="36.914417" cy="23.164867" r="4.1605139"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            cx="26.810354" cy="36.835125" r="4.1605139"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            cx="16.11199" cy="48.722301" r="4.1605139"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <circle
            cx="36.914417" cy="48.722301" r="4.1605139"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <path
            d="m 58.2648,21.47259 c 0.54198,-0.54198 0.64282,-1.31664 0.22569,-1.73378 L 55.9877,17.23602 c -0.41711,-0.41715 -1.1918,-0.31628 -1.73378,0.2257 l -2.54314,2.54315 4.01087,4.01087 z M 35.35941,36.35622 34.75917,40.96735 39.37028,40.3671 54.74034,24.99704 50.72947,20.98617 Z"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
    </g>
    <g id="Event" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <circle
            cx="29.999998" cy="30" r="26.271221"
            fill="currentColor"
            style="stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1;stroke-miterlimit:4" />
        <line
            x1="30.41313" y1="14.853907" x2="30.41313" y2="31.501245"
            style="fill:none;stroke-width:6;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1;stroke-miterlimit:4" />
        <line
            y1="43.082035" x1="30.41313" y2="43.082035" x2="30.41313"
            style="fill:none;stroke-width:8;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1;stroke-miterlimit:4"/>
    </g>
    <g id="Command" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <path
            d="m 16.209895,16.889786 c -2.16703,0 -3.94006,1.7533 -3.94006,3.94002 v 27.58026 c 0,2.16702 1.77303,3.94004 3.94006,3.94004 h 27.58025 c 2.16701,0 3.94002,-1.77302 3.94002,-3.94004 v -27.58026 c 0,-2.18672 -1.77301,-3.94002 -3.94002,-3.94002 h -19.70019 z"
            fill="currentColor"
            style="stroke-width:1;stroke-linecap:square;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
        <path
            d="M 38.09922,19.15577 41.28025,22.3368 30,33.61705 18.71975,22.3368 21.90078,19.15577 c 0,0 4.39829,4.30906 5.84317,5.82061 0,-1.09576 0,-21.816 0,-21.816 h 4.5121 v 21.816 z"
            fill="currentColor"
            style="stroke-width:1;stroke-linecap:square;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none" />
    </g>
    <g id="Query" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <path
            d="m 16.209895,16.889786 c -2.16703,0 -3.94006,1.7533 -3.94006,3.94002 v 27.58026 c 0,2.16702 1.77303,3.94004 3.94006,3.94004 h 27.58025 c 2.16701,0 3.94002,-1.77302 3.94002,-3.94004 v -27.58026 c 0,-2.18672 -1.77301,-3.94002 -3.94002,-3.94002 h -19.70019 z"
            fill="currentColor"
            style="stroke-width:1;stroke-linecap:square;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
        <path
            d="M 38.09922,19.866396 41.28025,16.685366 30,5.4051163 18.71975,16.685366 l 3.18103,3.18103 c 0,0 4.39829,-4.30906 5.84317,-5.82061 0,1.09576 0,21.816 0,21.816 h 4.5121 v -21.816 z"
            fill="currentColor"
            style="stroke-width:1;stroke-linecap:square;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
    </g>
    <g id="AggregateRoot" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <path
            d="M 7 8 L 7 51 A 23 5.5 0 0 0 30 56.5 A 23 5.5 0 0 0 53 51 L 53 8 L 7 8 z "
            fill="currentColor"
            style="stroke-width:1;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
        <ellipse
            style="stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            fill="currentColor"
            cx="30" cy="8" rx="23" ry="5.5" />
        <path
            d="M 44.135324,22.430996 A 23.070091,5.6829464 0 0 1 30.258777,23.573966 23.070091,5.6829464 0 0 1 15.597433,22.278765"
            style="fill:none;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        <path
            d="M 34.58764,31.83328 A 23.070091,5.6829464 0 0 1 30.258777,31.934217 23.070091,5.6829464 0 0 1 15.597433,30.639016"
            style="fill:none;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
        <path
            d="M 28.031961,40.281028 A 23.070091,5.6829464 0 0 1 15.597433,39.012363"
            style="fill:none;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
        <path
            d="M 28.031961,48.641279 A 23.070091,5.6829464 0 0 1 15.597433,47.372613"
            style="fill:none;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
    </g>
    <g id="Process" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <path
            d="M 14.542833,45.457168 36.440486,30.000001 14.542833,14.542833 Z M 40.304778,14.542833 v 30.914335 h 6 V 14.542833 Z"
            fill="currentColor"
            style="stroke-width:1;stroke-dasharray:none;stroke-opacity:1" />
    </g>
    <g id="Interrupt" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <path
            d="M 20,14.542833 v 30.914335 h 6 V 14.542833 Z M 35,14.542833 v 30.914335 h 6 V 14.542833 Z"
            fill="currentColor"
            style="stroke-width:1;stroke-dasharray:none;stroke-opacity:1" />
    </g>
    <g id="UserInput" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <use xlink:href="#clipboard"/>
        <path
            d="m 58.2648,21.47259 c 0.54198,-0.54198 0.64282,-1.31664 0.22569,-1.73378 L 55.9877,17.23602 c -0.41711,-0.41715 -1.1918,-0.31628 -1.73378,0.2257 l -2.54314,2.54315 4.01087,4.01087 z M 35.35941,36.35622 34.75917,40.96735 39.37028,40.3671 54.74034,24.99704 50.72947,20.98617 Z"
            fill="currentColor"
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
    </g>
    <g id="Subprocess" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <path
            fill="currentColor"
            d="m 45.061175,15 h -30.12225 c -2.58504,0 -4.68815,2.0197 -4.68815,4.50217 v 20.99566 c 0,2.48247 2.10311,4.50217 4.68815,4.50217 h 30.12225 c 2.58501,0 4.68805,-2.0197 4.68805,-4.50217 V 19.50217 C 49.749225,17.0197 47.646185,15 45.061175,15 Z"
            style="stroke-dasharray:none;stroke-opacity:1;stroke-width:3;stroke-miterlimit:4"/>
        <path
            d="M 11.503776,17.229912 30,30.331989 48.496224,17.229912"
            style="fill:none;stroke-width:3.30231023;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>

        <circle
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            fill="currentColor"
            r="9.0000143" cy="40.628506" cx="14.207703" />
        <line
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            y2="43.36319" x2="17.718916" y1="40.628506" x1="14.207703" />
        <line
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            y2="35.478577" x2="17.267082" y1="40.628506" x1="14.207703" />
        <line
            style="fill:none;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            y2="40.628506" x2="14.207703" y1="40.628506" x1="14.207703" />
    </g>
    <g id="View" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <use xlink:href="#portal-element"/>
        <path
            style="stroke-width:1;stroke-linecap:square;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            fill="currentColor"
            d="m 27.356249,23.952617 3.18103,-3.18103 -11.28025,-11.28025 -11.28025,11.28025 3.18103,3.18103 c 0,0 4.39829,-4.30906 5.84317,-5.82061 0,1.09576 0,21.816 0,21.816 h 4.5121 v -21.816 z"/>
        </g>
        <g id="Action" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <use xlink:href="#portal-element"/>
        <path
            d="m 27.356249,25.486748 3.18103,3.18103 -11.28025,11.28025 -11.28025,-11.28025 3.18103,-3.18103 c 0,0 4.39829,4.30906 5.84317,5.82061 0,-1.09576 0,-21.8160003 0,-21.8160003 h 4.5121 V 31.307358 Z"
            fill="currentColor"
            style="stroke-width:1;stroke-linecap:square;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
    </g>
    <g id="Context" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <use xlink:href="#portal-element"/>
        <path
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:miter;stroke-dasharray:none;stroke-opacity:1"
            d="m 19.257027,37.36117 v 3.58975 H 9.5134367 v 3.58974"/>
        <path
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:miter;stroke-dasharray:none;stroke-opacity:1"
            d="m 19.257027,37.36117 v 3.58975 3.58974"/>
        <path
            style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:miter;stroke-dasharray:none;stroke-opacity:1"
            d="m 19.257027,37.36117 v 3.58975 h 9.74358 v 3.58974"/>
        <rect
            style="stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
            fill="currentColor"
            x="16.051899" y="30.950918" width="6.4102564" height="6.4102564"/>
        <rect
            style="stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
            fill="currentColor"
            x="6.3083115" y="44.540672" width="6.4102564" height="6.4102564"/>
        <rect
            style="stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
            fill="currentColor"
            x="16.051903" y="44.540672" width="6.4102564" height="6.4102564"/>
        <rect
            style="stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
            fill="currentColor"
            x="25.795494" y="44.540672" width="6.4102564" height="6.4102564"/>
    </g>
    <g id="Component" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <path
            d="M 9.2598273,9.655755 H 50.740173 c 2.490173,0 4.508733,2.070101 4.508733,4.623692 v 31.441106 c 0,2.553591 -2.01856,4.623692 -4.508733,4.623692 H 9.2598273 c -2.4900832,0 -4.5087332,-2.070101 -4.5087332,-4.623692 V 14.279447 c 0,-2.553591 2.01865,-4.623692 4.5087332,-4.623692 z"
            fill="currentColor"
            style="stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1;stroke-miterlimit:4" />
        <path
            fill="currentColor"
            d="m 9.2598273,9.655755 c -2.4900832,0 -4.5087332,2.070101 -4.5087332,4.623692 v 9.106509 l 24.9336469,0.160739 25.564165,-0.164354 v -9.102894 c 0,-2.553591 -2.01856,-4.623692 -4.508733,-4.623692 z m 2.5255947,4.470167 a 2.5743776,2.6400164 0 0 1 2.57491,2.63876 2.5743776,2.6400164 0 0 1 -2.57491,2.640562 2.5743776,2.6400164 0 0 1 -2.574909,-2.640562 2.5743776,2.6400164 0 0 1 2.574909,-2.63876 z m 8.360531,0 a 2.5743776,2.6400164 0 0 1 2.574908,2.63876 2.5743776,2.6400164 0 0 1 -2.574908,2.640562 2.5743776,2.6400164 0 0 1 -2.57491,-2.640562 2.5743776,2.6400164 0 0 1 2.57491,-2.63876 z m 8.360529,0 a 2.5743776,2.6400164 0 0 1 2.573149,2.63876 2.5743776,2.6400164 0 0 1 -2.573149,2.640562 2.5743776,2.6400164 0 0 1 -2.574909,-2.640562 2.5743776,2.6400164 0 0 1 2.574909,-2.63876 z"
            style="fill:none;fill-opacity:1;stroke:none;stroke-dasharray:none;stroke-opacity:1" />
    </g>
    <g id="Subscription" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <path
            d="m 41.152381,20.137071 h -30.12225 c -2.5850402,0 -4.6881502,2.0197 -4.6881502,4.50217 v 20.99566 c 0,2.48247 2.10311,4.50217 4.6881502,4.50217 h 30.12225 c 2.58501,0 4.68805,-2.0197 4.68805,-4.50217 v -20.99566 c 0,-2.48247 -2.10304,-4.50217 -4.68805,-4.50217 z"
            fill="currentColor"
            style="stroke-width:3;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
        <path
            style="fill:none;stroke-width:3;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="M 7.5949818,22.366983 26.091206,35.46906 44.58743,22.366983"/>
        <path
            style="fill:none;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1"
            d="m 14.995426,15.675281 h 36 v 22.43847 0"/>
        <circle
            style="stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            fill="currentColor"
            r="9.0000143" cy="17.862944" cx="45.658005" />
        <line
            y2="12.862943" x2="45.658005" y1="22.862944" x1="45.658005"
            style="fill:none;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1" />
        <line
            style="fill:none;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1"
            x1="40.658005" y1="17.862944" x2="50.658005" y2="17.862944"/>
    </g>
    <g id="PolicyGroup" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <use xlink:href="#clipboard" transform="translate(7,-2)"/>
    </g>
    <g id="Provider" transform="scale(2,2) translate(-30,-30)">
        <rect y="0" x="0" height="60" width="60" filter="url(#shadow)"/>
        <g transform="matrix(1 0 0 1 30.41 41.58)">
            <path
                style="stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                fill="currentColor"
                transform="translate(-24.5, -8.42)"
                d="M 48.99842 16.83733 L 0 16.83733 C 1.42413 9.830480000000001 5.78184 3.885250000000001 11.73348 0.34129000000000076 C 14.43988 4.264520000000001 18.96591 6.837330000000001 24.08921 6.837330000000001 C 29.35962 6.837330000000001 33.99798 4.1146400000000005 36.67355 8.881784197001252e-16 C 42.92724 3.495960000000001 47.52753 9.6004 48.998419999999996 16.83733 z"/>
        </g>
        <ellipse
            cx="30" cy="25" rx="12.5" ry="12.5"
            fill="currentColor"
            style="stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
    </g>
  </defs>
</template>

<script>
export default {
  name: 'SvgDefinitions',
}
</script>

<style>
</style>
