<template>
  <div>
    <div class="uk-flex uk-flex-middle">
      <select v-model="reference" class="uk-select uk-form-width-small" :disabled="readonly" :class="{'uk-disabled': readonly}">
          <option :value="reference">from canvas...</option>
      </select>
      <span class="uk-flex-auto uk-margin-left uk-margin-right" v-if="value">
      {{ context.label(value) }}
      </span>
      <span class="uk-flex-auto uk-margin-left uk-margin-right uk-text-italic" v-else>
      select event type...
      </span>
      <button class="uk-button uk-button-link" :class="{'uk-disabled': false}" v-if="value" v-on:click="$emit('center', value)">
        <i uk-icon="icon: location"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventType',
  props: {
    version: Number,
    item: Object,
    category: String,
    label: String,
    error: Object,
    context: Object,
    references: Map,
    readonly: Boolean,
  },
  components: {
  },
  data() {
    if (!this.references) {
      const value = this.item.event_type ?? '';
      return {
        value,
        reference: this.item.event_type || '$',
      }
    } else {
      let value = this.item.emits ?? ''
      for (const item of this.references) {
        if (!item[1]) {
          value = item[0];
          break;
        }
      }
      return {
        value,
        reference: value ?? '$',
      }
    }
  },
  methods: {
  },
  computed: {
  },
  watch: {
    version() {
      if (!this.references) {
        this.value = this.item.event_type ?? '';
        this.reference = this.item.event_type || '$';
      } else {
        let value = this.item.emits ?? ''
        for (const item of this.references) {
          if (!item[1]) {
            value = item[0];
            break;
          }
        }
        this.value = value;
        this.reference = this.item.emits ?? '$';
      }
    },
  },
}
</script>