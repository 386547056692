<template>
  <ul class="uk-list uk-margin-small-bottom uk-margin-small-top">
    <li v-if="serial !== null">
      <label>
        <input class="uk-checkbox" type="checkbox" v-model="serial" @change="$emit('update', { field: 'metadata', value })" :readonly="readonly">
        <span class="uk-margin-small-left uk-margin-remove-bottom uk-form-label" style="display: inline-block">
          {{ $label('objects.serial') }}
        </span>
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          {{ $help('objects.serial') }}
        </p>
      </label>
    </li>
    <li v-if="mutable !== null">
      <label>
        <input class="uk-checkbox" type="checkbox" v-model="mutable" @change="$emit('update', { field: 'metadata', value })" :readonly="readonly">
        <span class="uk-margin-small-left uk-margin-remove-bottom uk-form-label" style="display: inline-block">
          {{ $label('objects.mutable') }}
        </span>
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          {{ $help('objects.mutable') }}
        </p>
      </label>
    </li>
    <li v-if="escalate !== null">
      <label>
        <input class="uk-checkbox" type="checkbox" v-model="escalate" @change="$emit('update', { field: 'metadata', value })" :readonly="readonly">
        <span class="uk-margin-small-left uk-margin-remove-bottom uk-form-label" style="display: inline-block">
          {{ $label('objects.escalate') }}
        </span>
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          {{ $help('objects.escalate') }}
        </p>
      </label>
    </li>
    <li v-if="whitelist !== null">
      <label>
        <input class="uk-checkbox" type="checkbox" v-model="whitelist" @change="$emit('update', { field: 'metadata', value })" :readonly="readonly">
        <span class="uk-margin-small-left uk-margin-remove-bottom uk-form-label" style="display: inline-block">
          {{ $label('objects.whitelist') }}
        </span>
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          {{ $help('objects.whitelist') }}
        </p>
      </label>
    </li>
    <li v-if="service !== null">
      <label>
        <input class="uk-checkbox" type="checkbox" v-model="service" @change="$emit('update', { field: 'metadata', value })" :readonly="readonly">
        <span class="uk-margin-small-left uk-margin-remove-bottom uk-form-label" style="display: inline-block">
          {{ $label('objects.service') }}
        </span>
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          {{ $help('objects.service') }}
        </p>
      </label>
    </li>
    <li>
      <label>
        <input class="uk-checkbox" type="checkbox" v-model="validated" @change="$emit('update', { field: 'metadata', value })" :readonly="readonly">
        <span class="uk-margin-small-left uk-margin-remove-bottom uk-form-label" style="display: inline-block">
          {{ $label('objects.validated') }}
        </span>
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          {{ $help('objects.validated') }}
        </p>
      </label>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex';
const API = new Set(["Action", "View", "Context"])

function assign(src, dst) {
  const metadata = new Set((src.item.metadata ?? '').split(' ').map(x => x.trim().toLowerCase()));
  dst.validated = metadata.has('validated');
  dst.serial = src.category === 'Command' ? metadata.has('serial') : null;
  dst.service = src.category === 'Context' ? metadata.has('service') : null;
  dst.whitelist = API.has(src.category) ? metadata.has('whitelist') : null;
  dst.escalate = API.has(src.category) ? metadata.has('escalate') : null;
  dst.mutable = src.category === 'AggregateRoot' ? metadata.has('mutable') : null;
  dst.metadata = metadata;
  return dst;
}

export default {
  name: 'Metadata',
  props: {
    version: Number,
    item: Object,
    category: String,
    readonly: Boolean,
  },
  components: {
  },
  data() {
    return assign(this, {});
  },
  mounted() {
  },
  beforeDestroy() {
  },
  watch: {
    version() {
      assign(this, this);
    },
  },
  computed: {
    value() {
      const compiled = [];
      if (this.validated) compiled.push('validated');
      if (this.serial) compiled.push('serial');
      if (this.whitelist) compiled.push('whitelist');
      if (this.service) compiled.push('service');
      if (this.escalate) compiled.push('escalate');
      if (this.mutable) compiled.push('mutable');
      return compiled.join(' ');
    },
    ...mapState('api', ['help']),
  },
  methods: {
  },
}
</script>
