<template>
  <div>
    <label>
      <input class="uk-checkbox" type="checkbox" v-model="document" @change="document ? $emit('create') : $emit('delete')" :disabled="readonly">
      <span class="uk-margin-small-left uk-form-label" style="display: inline-block">
        enable
      </span>
      <p class="uk-margin-small-top uk-text-right uk-text-meta
            uk-margin-remove-bottom uk-animation-fade" v-if="help">
        Mark fields to be stored in a document
      </p>
    </label>
    <fieldset class="uk-fieldset" v-if="document">
      <label class="uk-flex uk-flex-column uk-margin-bottom">
        <span>name <span class="uk-text-meta">{{ error.field('/name') }}</span></span>
        <input
          class="uk-form-controls uk-inline uk-input"
          type="text"
          pattern="[a-z](?:_?[a-z0-9]+)*"
          v-model="name"
          :placeholder="$command('objects.name', {label: 'document'})"
          v-on:change="$emit('update', {field: 'name', value: name})"
          spellcheck="false"
          autocorrect="off"
          autocapitalize="off"
          autocomplete="off"
          :readonly="readonly">
        <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          {{ $help('objects.name') }}
        </p>
      </label>
      <label>
        <span class="uk-form-label">fields</span>
        <ul class="uk-list uk-text-right">
          <li v-for="(field, index) in fields" :key="index">{{ field }}</li>
          <li class="uk-text-italic">add or remove fields on canvas...</li>
        </ul>
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          Select the fields that will be included in the document, preventing normal indexing but potentially saving space and
          computational requirements
        </p>
      </label>
    </fieldset>
  </div>
</template>

<script>
import { mapState } from 'vuex';

function assign(src, dst) {
  dst.document = !!src.item;
  dst.name = src.item?.name ?? '';
  dst.fields = (src.item?.fields ?? '').split(',').map(x => x.trim()).filter(x => x);
  dst.error = {
    invalid: 0,
    msg: null,
    local: [],
    fields: new Map(),
    field(src) {
      return this.fields.get(src) ||'';
    },
  }
  if (src.errors) {
    dst.error.invalid = src.errors.length;
    dst.error.msg = src.errors.length ? 'invalid data' : null;
    for (const {property, detail} of src.errors) {
      switch (property) {
        case '/name':
          dst.error.fields.set(property, detail);
          break;
        default:
          dst.error.local.push(detail);
          break;
      }
    }
  }
  return dst;
}

export default {
  name: 'Document',
  props: {
    parent: Object,
    version: Number,
    item: Object,
    categories: Object,
    errors: Array,
    context: Object,
    schema: Object,
    readonly: Boolean,
  },
  components: {
  },
  data() {
    return assign(this, {});
  },
  methods: {
  },
  computed: {
    ...mapState('api', ['help']),
  },
  watch: {
    version() {
      assign(this, this);
    },
  },
}
</script>