<template>
    <svg ref="editor" class="tree" :viewBox="viewBox"
        preserveAspectRatio="xMinYMin meet"
        v-on:contextmenu.prevent="onContextMenu"
        v-on:wheel="onScroll"
        v-on:mousedown="startDrag"
        v-on:mouseup="endDrag"
        v-on:mousecancel="endDrag"
        v-on:mouseleave="endDrag"
        v-on:touchstart="startTouch"
        v-on:touchend="endTouch"
        v-on:touchleave="endTouch"
        v-on:touchcancel="endTouch">
        <g ref="viewport">
            <g ref="underlay">
            </g>
            <g ref="window">
            </g>
        </g>
    </svg>
</template>
<script>
import { tree } from '@/scripts/object.js';

const REGEX = /^(?:DISTINCT\s*\(\s*([a-z0-9_.]*)\s*\)|DISJOINT\s*\(\s*([a-z0-9_.,]*)\s*\)|CHECK\s*\((.*)\))(?:\s*IN\s*([a-z0-9_.]+))?\s*$/;

function inject(attributes, name, value, id) {
    if (attributes.has(name)) attributes.get(name).push([value, id]);
    else attributes.set(name, [ [value, id] ]);
    return [id, name];
}

function* annotate(item, attributes, context) {
    switch (item.category) {
        case 'AggregateRoot':
            for (const key of (item.values.summary ?? '*').split(' ').map(x => x.trim()).filter(x => x && x !== '*')) {
                yield inject(attributes, key, 'summary field', item.id);
            }
            for (const key of (item.values.search ?? '').split(' ').map(x => x.trim()).filter(x => x)) {
                yield inject(attributes, key, 'searchable field', item.id);
            }
            break;
        case 'Document':
            for (const key of (item.values.fields ?? '').split(',').map(x => x.trim()).filter(x => x)) {
                yield inject(attributes, key, 'document storage', item.id);
            }
            break;
        case 'Index':
            for (const key of (item.values.fields ?? '').split(',').map(x => x.trim()).filter(x => x)) {
                yield inject(attributes, key, `indexed by ${context.label(item.id)}`, item.id);
            }
            break;
        case 'Entity':
            for (const key of (item.values.fields ?? '').split(',').map(x => x.trim()).filter(x => x)) {
                yield inject(attributes, key, `lifecycle tracked by ${context.label(item.id)}`, item.id);
            }
            break;
        case 'Table':
            if (item.values.name) {
                yield inject(attributes, item.values.field, `stored in ${item.values.name}`, item.id);
            }
            if (item.values.prefix) {
                yield inject(attributes, item.values.field, `prefixed with '${item.values.prefix}'`, item.id);
            }
            break;
        case 'FieldConstraint':
            if (item.values.name) {
                yield inject(attributes, item.values.name, item.values.value || 'new field constraint', item.id);
            }
            break;
        case 'NamedConstraint': {
                const match = REGEX.exec(item.values.value ?? '');
                if (match) {
                    yield inject(attributes, match[4] ?? null, item.values.value, item.id);
                }
            }
            break;
    }
}

function compile(categories, schema, obj, annotations, context) {
    const lookup = new Map();
    if (obj) for (const [id, key] of annotate(obj, annotations, context)) {
        if (lookup.has(id)) lookup.get(id).add(key);
        else lookup.set(id, new Set([key]));
    }
    for (const item of schema.attributes.map(x => categories[x.category]).filter(x => x)) {
        for (const [id, key] of annotate(item, annotations, context)) {
            if (lookup.has(id)) lookup.get(id).add(key);
            else lookup.set(id, new Set([key]));
        }
    }
    for (const item of schema.sections.flatMap(x => categories[x.category])) {
        for (const [id, key] of annotate(item, annotations, context)) {
            if (lookup.has(id)) lookup.get(id).add(key);
            else lookup.set(id, new Set([key]));
        }
    }
    return lookup;
}

export default {
    name: 'SvgTree',
    props: {
        bus: Object,
        typeid: String,
        context: Object,
        categories: Object,
        item: Object,
        schema: Object,
        selected: Object,
        readonly: Boolean,
    },
    components: {
    },
    data() {
        return {
            pan: null,
            mouse: null,
            scale: 1,
            items: new Map(),
            annotations: new Map(),
            affected: new Map(),
            offset: null,
            tp: new Array(),
            pending: null,
            isTouch: false,
            tree: null,
        };
    },
    mounted() {
        this.bus.$on('created', this.onCreate);
        this.bus.$on('updated', this.onUpdate);
        this.bus.$on('deleted', this.onDelete);
        if (!this.pan) {
            this.pan = this.$refs.editor.createSVGTransform();
            this.$refs.viewport.transform.baseVal.insertItemBefore(this.pan, 0);
            this.mouse = this.$refs.editor.createSVGPoint();
        }
        this.redraw();
    },
    destroyed() {
        this.bus.$off('created', this.onCreate);
        this.bus.$off('updated', this.onUpdate);
        this.bus.$off('deleted', this.onDelete);
    },
    computed: {
        viewBox() {
            return `0 0 ${2*this.width} ${2*this.width}`;
        },
        width() {
            const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            return Math.round(Math.sqrt(w*w + h*h)/2);
        }
    },
    watch: {
        selected() {
            this.select();
        },
        typeid() {
            this.redraw();
        },
    },
    methods: {
        redraw() {
            while (this.$refs.underlay.lastChild) {
                this.$refs.underlay.removeChild(this.$refs.underlay.lastChild);
            }
            while (this.$refs.window.lastChild) {
                this.$refs.window.removeChild(this.$refs.window.lastChild);
            }
            this.pan.setTranslate(0, 0);

            this.items.clear();
            this.annotations.clear();
            this.offset = null;
            this.tp = new Array();
            this.pending = null;
            this.isTouch = false;
            this.affected = compile(this.categories, this.schema, this.item, this.annotations, this.context);
            this.tree = tree(this.$refs.editor, this.context, this.typeid, true);
            this.items.set(this.tree.viewport, this.tree);
            this.tree.setListener(vertex => this.$emit('selected', vertex));
            this.select();
            for (const item of this.annotations) {
                if (item[0]) {
                    this.tree.annotate(item[1].map(x => x[0]).sort(), ...item[0].split('.'));
                } else {
                    this.tree.annotate(item[1].map(x => x[0]).sort());
                }
            }
            this.$refs.window.appendChild(this.tree.viewport);
        },
        onCreate(item) {
            const affected = new Set();
            for (const [id, key] of annotate(item, this.annotations, this.context)) {
                if (this.affected.has(id)) this.affected.get(id).add(key);
                else this.affected.set(id, new Set([key]));
                affected.add(key);
            }

            for (const key of affected) {
                if (key) {
                    this.tree.annotate(this.annotations.get(key).map(x => x[0]).sort(), ...key.split('.'));
                } else {
                    this.tree.annotate(this.annotations.get(key).map(x => x[0]).sort());
                }
            }
        },
        onUpdate(item) {
            const affected = new Set();
            if (this.affected.has(item.id)) {
                for (const key of this.affected.get(item.id)) {
                    this.annotations.set(key, this.annotations.get(key).filter(x => x[1] !== item.id));
                    affected.add(key);
                }
            }
            const updated = new Set();
            for (const entry of annotate(item, this.annotations, this.context)) {
                updated.add(entry[1]);
                affected.add(entry[1]);
            }

            for (const key of affected) {
                if (key) {
                    this.tree.annotate(this.annotations.get(key).map(x => x[0]).sort(), ...key.split('.'));
                } else {
                    this.tree.annotate(this.annotations.get(key).map(x => x[0]).sort());
                }
            }
            this.affected.set(item.id, updated);
            this.select();
        },
        onDelete(item) {
            if (this.affected.has(item.id)) {
                for (const key of this.affected.get(item.id)) {
                    this.annotations.set(key, this.annotations.get(key).filter(x => x[1] !== item.id));
                    if (key) {
                        this.tree.annotate(this.annotations.get(key).map(x => x[0]).sort(), ...key.split('.'));
                    } else {
                        this.tree.annotate(this.annotations.get(key).map(x => x[0]).sort());
                    }
                }
                this.affected.delete(item.id);
            }
        },
        onContextMenu(evt) {
            const selected = this.tree.node(evt.target);
            if (selected && !selected.disabled && !this.readonly) {
                this.prepareMousePosition(evt.clientX, evt.clientY);
                var point = this.mouse.matrixTransform(this.pan.matrix.inverse());
                document.activeElement.blur();
                this.$emit('context', {
                    id: this.item.id,
                    x: point.x,
                    y: point.y,
                    captured: [],
                    group: '',
                    field: selected,
                });
            }
        },
        onScroll(evt) {
            if (!this.items.size) {
                return;
            }
            evt.preventDefault();
            let delta = evt.wheelDelta ? (0.0015 * evt.wheelDelta) : (-0.0033*evt.deltaY);
            if (evt.deltaMode == 1 && !evt.ctrlKey)
            {
                delta = delta * 3;
            }
            else if (evt.deltaMode != 1 && evt.ctrlKey)
            {
                delta = delta / 3;
            }
            this.prepareMousePosition(evt.clientX, evt.clientY);
            this.doScroll(Math.min(10 / this.pan.matrix.a, Math.max(0.1 / this.pan.matrix.a, Math.exp(delta))));
        },
        select() {
            if (!this.selected) {
                this.tree.select(false);
            } else if (this.selected.category === 'FieldConstraint') {
                this.tree.select(false, ...(this.selected.values.name ?? '').split('.'));
            } else if (this.selected.category === 'NamedConstraint') {
                const match = REGEX.exec(this.selected.values.value ?? '');
                if (match && match[4]) {
                    this.tree.select(false, ...match[4].split('.'))
                } else {
                    this.tree.select(false);
                }
            } else {
                this.tree.select(false);
            }
        },
        startDrag(evt) {
            if (this.isTouch || !this.items.size) {
                return;
            }

            evt.preventDefault();
            if (evt.buttons !== 1) {
                return;
            }

            this.prepareMousePosition(evt.clientX, evt.clientY);
            this.offset = this.mouse.matrixTransform(this.pan.matrix.inverse());
            this.$refs.editor.addEventListener('mousemove', this.drag);
        },
        drag(evt) {
            if (this.isTouch) {
                return;
            }

            evt.preventDefault();
            this.prepareMousePosition(evt.clientX, evt.clientY);
            this.doPan();
        },
        endDrag(evt) {
            if (this.isTouch) {
                this.isTouch = false;
                return;
            }

            evt.preventDefault();
            this.offset = null;
            this.$refs.editor.removeEventListener('mousemove', this.drag);
        },
        startTouch(evt) {
            if (!this.items.size) {
                return;
            }

            this.isTouch = true;
            this.tp.splice(-1, 0, ...evt.changedTouches);
            evt.preventDefault();
            this.resetTouchCoordinates(evt.touches);
            if (evt.touches.length === 1) {
                this.$refs.editor.addEventListener('touchmove', this.touch);
            }
        },
        touch(evt) {
            evt.preventDefault();

            if (evt.touches.length > 1) {
                // Note that this assumes a stable order for touches and tp
                // TODO: remove this assumption everywhere
                const x0 = evt.touches[0].clientX;
                const x1 = evt.touches[1].clientX;
                const y0 = evt.touches[0].clientY;
                const y1 = evt.touches[1].clientY;
                this.prepareMousePosition(0.5*(x0 + x1), 0.5*(y0 + y1));
                this.doPan();
                this.doScroll(Math.min(2, Math.max(0.01, this.scale * Math.sqrt(Math.pow(x1-x0, 2) + Math.pow(y1-y0, 2)))) / this.pan.matrix.a);
            } else {
                this.prepareMousePosition(evt.touches[0].clientX, evt.touches[0].clientY);
                this.doPan();
            }
        },
        endTouch(evt) {
            const exists = new Set();
            for (const obj of evt.touches) {
                exists.add(obj.identifier);
            }
            this.tp = this.tp.filter(obj => exists.has(obj.identifier));
            if (evt.touches.length >= 2) {
                this.resetTouchCoordinates(evt.touches);
                evt.preventDefault();
            } else if (evt.touches.length) {
                this.$refs.editor.removeEventListener('touchmove', this.touch);
                evt.preventDefault();
            }
        },
        prepareMousePosition(x, y) {
            const CTM = this.$refs.editor.getScreenCTM();
            this.mouse.x = (x - CTM.e) / CTM.a;
            this.mouse.y = (y - CTM.f) / CTM.d;
        },
        doScroll(factor) {
            this.pan.matrix.a *= factor;
            this.pan.matrix.b *= factor;
            this.pan.matrix.c *= factor;
            this.pan.matrix.d *= factor;
            this.pan.matrix.e = (1-factor)*this.mouse.x + factor*this.pan.matrix.e;
            this.pan.matrix.f = (1-factor)*this.mouse.y + factor*this.pan.matrix.f;
            this.$emit('translate', {
                scale: this.pan.matrix.a,
                x: this.pan.matrix.e,
                y: this.pan.matrix.f,
            });
        },
        doPan() {
            this.pan.matrix.e = this.mouse.x - this.pan.matrix.a*this.offset.x;
            this.pan.matrix.f = this.mouse.y - this.pan.matrix.d*this.offset.y;
            this.$emit('translate', {
                scale: this.pan.matrix.a,
                x: this.pan.matrix.e,
                y: this.pan.matrix.f,
            });
        },
        resetTouchCoordinates(touches) {
            if (this.tp.length == 1) {
                this.prepareMousePosition(touches[0].clientX, touches[0].clientY);
                this.offset = this.mouse.matrixTransform(this.pan.matrix.inverse());
            } else {
                const x = 0.5 * (touches[0].clientX + touches[1].clientX);
                const y = 0.5 * (touches[0].clientY + touches[1].clientY);
                this.prepareMousePosition(x, y);
                this.offset = this.mouse.matrixTransform(this.pan.matrix.inverse());
                this.scale = this.pan.matrix.a / Math.sqrt(Math.pow(
                    this.tp[1].clientX - this.tp[0].clientX,
                    2) + Math.pow(
                    this.tp[1].clientY - this.tp[0].clientY,
                    2));
            }
        },
    }
};
</script>

<style lang="less">
@import "../../assets/less/theme.less";
svg.tree {
    background-color: @global-muted-background;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
}
svg.tree {
    height: 100dvh;
}
svg.tree text {
    fill: @global-color;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}
svg.tree foreignObject {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: visible;
}
svg.tree .annotation {
    stroke: #3a4a49;
    fill: #3a4a49;
    stroke-linecap: square;
    stroke-width: 1;
}
</style>
