<template>
  <div>
    <ul class="uk-list uk-text-right">
        <li v-for="(field, index) in summary" :key="index">{{ field }}</li>
        <li class="uk-text-italic">add or remove fields on canvas...</li>
      </ul>
      <p class="uk-margin-small-top uk-text-right uk-text-meta
            uk-margin-remove-bottom uk-animation-fade" v-if="help">
        Select the fields that will be included in the default list projection
      </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'SummaryProjection',
  props: {
    version: Number,
    item: Object,
    options: Array,
  },
  data() {
    return {
      summary: (this.item.summary ?? '*').split(' ').map(x => x.trim()).filter(x => x),
    }
  },
  computed: {
    ...mapState('api', ['help']),
  },
  watch: {
    version() {
      this.summary = (this.item.summary ?? '*').split(' ').map(x => x.trim()).filter(x => x);
    }
  }
}
</script>