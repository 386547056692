<template>
  <EditableItem :terminal="terminal" :hidden="terminal" :readonly="readonly" :name="item.name"
    :placeholder="$label('objects.unnamed', { label: $label(`objects.${label}`) })" :editing="editing" :error="error"
    :attributes="attributes" v-on:focus="$emit('focus')" v-on:edit="$emit('edit')"
    v-on:delete="$emit('delete')" v-on:center="$emit('center')">
    <template v-if="editing">
      <div class="uk-margin-small">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">{{ $label('objects.name') }} <span class="uk-text-meta"> {{ error.field('/name')
          }}</span></span>
          <input ref="name" class="uk-form-controls uk-inline uk-input" type="text" v-model="name"
            pattern="[a-z](?:_?[a-z0-9]+)*" :placeholder="$command('objects.name', { label: $label(`objects.${label}`) })"
            v-on:change="$emit('update', { field: 'name', value: name.trim().replace(/[\s-()+\.]/g, '_').replace(/__+/g, '_').toLowerCase() })"
            spellcheck="false" autocorrect="off" autocapitalize="off" autocomplete="off" v-bind:disabled="readonly">
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help('objects.name') }}
          </p>
        </label>
      </div>
      <div class="uk-margin" v-if="proxiable">
        <label>
          <input class="uk-checkbox" type="checkbox" name="check1" v-model="proxied"
            v-on:change="proxied ? $emit('update', { field: 'description', value: null }) : $emit('restore', {field: 'description', value: ''})"
            v-bind:disabled="readonly">
          <span class="uk-form-label uk-margin-small-left" style="display: inline-block">{{ $label('objects.proxied') }}</span>
          <p class="uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help('objects.proxied') }}
          </p>
        </label>
      </div>
      <div class="uk-margin-small" v-if="!proxiable || !proxied">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">{{ $label('objects.description') }} <span class="uk-text-meta"> {{
            error.field('/description') }}</span></span>
          <textarea ref="description" class="uk-form-controls uk-inline uk-textarea uk-width-expand uk-flex-auto"
            style="resize: vertical" rows="5" v-model="description"
            :placeholder="$command('objects.description', { label: $label(`objects.${label}`) })"
            v-on:change="$emit('update', { field: 'description', value: description })"
            v-bind:disabled="readonly">
          </textarea>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help(`objects.${label}`) }}
          </p>
        </label>
      </div>
      <div class="uk-margin" v-if="dependencies">
        <label class="uk-form-label">{{ $label(`objects.requirements.${label}`) }}</label>
        <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          {{ $help(`objects.requirements.${label}`) }}
        </p>
        <div class="uk-form-controls ">
          <ul class="uk-list" v-if="dependencies.length">
            <li v-for="dependency in dependencies" :key="dependency.id">
              <EditableItem :linking="true" :terminal="true" :editing="false" :readonly="readonly" :name="dependency.values.name"
                :placeholder="$label('objects.unnamed', { label: $label(`objects.${dependency.section.label}`) })"
                :attributes="dependency.attributes"
                v-on:focus="$emit('focus', dependency)" v-on:edit="$emit('edit', dependency)"
                v-on:delete="$emit('delete', dependency)" v-on:center="$emit('center', dependency)"
                v-on:unlinked="$emit('unlinked', dependency)">
                <p v-if="dependency.values.description">{{ dependency.values.description }}</p>
                <p class="uk-text-italic uk-text-center" v-else>
                  {{ $message('objects.missingattr', {
                    label: $label(`objects.${dependency.section.label}`), attr:
                      $label('objects.description')
                  }) }}
                </p>
              </EditableItem>
            </li>
          </ul>
          <div class="uk-text-italic uk-text-center" v-else>
            {{ $message('objects.missingattr', {
              label: $label(`objects.${label}`), attr:
                $label(`objects.requirements.${label}`)
            }) }}
          </div>
        </div>
      </div>
    </template>
    <p v-else-if="description">{{ description }}</p>
    <p class="uk-text-italic uk-text-center" v-else>
      {{ $message('objects.missingattr', { label: $label(`objects.${label}`), attr: $label('objects.description') }) }}
    </p>
  </EditableItem>
</template>

<script>
import { mapState } from 'vuex';
import EditableItem from '@/components/canvas/EditableItem.vue';
const TERMINAL = new Set(['lookups', 'regexes', 'components']);
const INBOUND = new Set(['Context', 'View', 'Action']);

function assign(src, dst) {
  if (INBOUND.has(src.category)) {
    const dependencies = [];
    src.references.forEach((value, key) => {
      if (value) dependencies.push(src.context.get(key) ?? {
        id: key,
        values: {
          name: key.toString(),
          description: null,
        },
        section: {
          label: 'object',
        },
      });
    });
    dependencies.sort((a, b) => a.values.name.localeCompare(b.values.name));
    dst.dependencies = dependencies;
  } else {
    dst.dependencies = null;
  }
  dst.attributes = src.attribute ? [src.attribute] : [];
  dst.name = src.item.name ?? '';
  dst.description = src.item.description ?? '';
  dst.proxied = src.item.description == null;
  dst.metadata = new Set((src.item.metadata ?? '').split(' ').map(x => x.trim().toLowerCase()));
  dst.error = {
    invalid: src.errors.length,
    msg: src.errors.length ? 'invalid data' : null,
    local: [],
    fields: new Map(),
    field(src) {
      return this.fields.get(src) ||'';
    },
  }
  for (const {property, detail} of src.errors) {
    switch (property) {
      case '/name':
      case '/description':
        dst.error.fields.set(property, detail);
        break;
      default:
        dst.error.local.push(detail);
        break;
    }
  }
  return dst;
}

export default {
  name: 'Object',
  props: {
    version: Number,
    item: Object,
    category: String,
    label: String,
    errors: Array,
    editing: Boolean,
    context: Object,
    categories: Object,
    attribute: String,
    references: Map,
    readonly: Boolean,
  },
  components: {
    EditableItem,
  },
  data() {
    return assign(this, {});
  },
  mounted() {
  },
  beforeDestroy() {
  },
  watch: {
    version() {
      assign(this, this);
    },
  },
  computed: {
    proxiable() {
      return INBOUND.has(this.category);
    },
    terminal() {
      return TERMINAL.has(this.label);
    },
    ...mapState('api', ['help']),
  },
  methods: {
  },
}
</script>
