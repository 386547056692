<template>
  <div class="uk-flex uk-flex-middle">
    <select v-model="datatype" class="uk-select uk-form-width-small"
      :disabled="readonly || (required && !primitive)"
      :class="{'uk-disabled': readonly || (required && !primitive)}"
      v-on:change="maybeNotify">
        <option value="" v-if="!required">none</option>
        <option :value="reference">from canvas...</option>
        <template v-if="primitive">
        <option :value="item.value" v-for="item in primitives" :key="item.value">{{ item.description }}</option>
        </template>
    </select>
    <span class="uk-flex-auto uk-margin-left uk-margin-right" v-if="datatype && value">
    {{ context.label(value) }}
    </span>
    <span class="uk-flex-auto uk-margin-left uk-margin-right uk-text-italic" v-else-if="datatype || required">
    select a data type...
    </span>
    <span class="uk-flex-auto uk-margin-left uk-margin-right uk-text-italic" v-else>
      {{ label }} disabled
    </span>
    <button class="uk-button uk-button-link" :class="{'uk-disabled': !locatable}" v-if="locatable"
        v-on:click="$emit('center', datatype)">
      <i uk-icon="icon: location"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ObjectAttribute',
  props: {
    context: Object,
    label: String,
    value: String,
    primitive: Boolean,
    required: Boolean,
    validate: Boolean,
    readonly: Boolean,
  },
  data() {
    return {
      datatype: this.value || (this.required ? '$' : ''),
      reference: this.context.isPrimitive(this.value) || !this.value ? '$' : this.value,
    }
  },
  methods: {
    maybeNotify() {
      if (this.reference === '$') {
        this.$emit('update', this.datatype === '$' ? null : this.datatype);
      } else {
        this.$emit('update', this.datatype || null);
      }
    }
  },
  computed: {
    locatable() {
      return this.value && this.value === this.datatype && !this.context.isPrimitive(this.value);
    },
    primitives() {
      var primitives = [...this.context.primitives];
      if (this.context.lookups()) primitives.push({value:'LOOKUP', description: 'lookup'});
      return primitives.sort((a, b) => {a.description.localeCompare(b.description)});
    }
  },
  watch: {
    value() {
      this.datatype = this.value || (this.required ? '$' : '');
      if (this.value && !this.context.isPrimitive(this.value)) {
        this.reference = this.value;
      }
    },
  },
}
</script>