<template>
  <textarea
    class="uk-form-controls uk-inline uk-textarea uk-width-expand uk-flex-auto"
    style="resize: vertical"
    autofocus
    rows="5"
    v-model="modified"
    :placeholder="$message('objects.audit')"
    v-on:change="$emit('update', modified || null)"
    :readonly="readonly">
  </textarea>
</template>

<script>
export default {
  name: 'Audit',
  props: {
    version: Number,
    name: String,
    item: Object,
    readonly: Boolean,
  },
  data() {
    return {
      modified: this.item.audit || null,
    }
  },
  watch: {
    version() {
      this.modified = this.item.audit || null;
    },
  },
}
</script>