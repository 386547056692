<template>
  <section class="uk-container uk-container-small uk-light" uk-overflow-auto>
    <dl class="uk-text-right" v-if="page">
      <dt>page</dt>
      <dd><a :href="page.url" target="_blank">{{ page.title }}</a></dd>
    </dl>
    <section v-if="schema.settings && !proxy">
      <Settings
        :sections="schema.settings.sections"
        :categories="categories"
        @focus="$emit('settings', { id: item.id, schema: schema.settings, context: item.id, fields: null, tree: item.id })">
      </Settings>
    </section>
    <details class="uk-light" open v-if="schema.metrics && metrics && search === null">
      <summary class="uk-text-right">
        statistics
      </summary>
      <ul class="uk-list uk-text-right">

        <li>
          <label>
            default <input type="radio" name="stats" value="" v-model="sort"/>
          </label>
        </li>
        <li>
          <label>
            requests <input type="radio" name="stats" value="count" v-model="sort"/>
          </label>
        </li>
        <li>
          <label>
            warnings <input type="radio" name="stats" value="warnings" v-model="sort"/>
          </label>
        </li>
        <li>
          <label>
            errors <input type="radio" name="stats" value="errors" v-model="sort"/>
          </label>
        </li>
        <li>
          <label>
            percentile:
          </label>
          <label>
            50<sup>th</sup> <input type="radio" name="stats" value="duration_50" v-model="sort"/>
          </label>
          <label>
            75<sup>th</sup> <input type="radio" name="stats" value="duration_75" v-model="sort"/>
          </label>
          <label>
            95<sup>th</sup> <input type="radio" name="stats" value="duration_95" v-model="sort"/>
          </label>
          <label>
            99<sup>th</sup> <input type="radio" name="stats" value="duration_99" v-model="sort"/>
          </label>
        </li>
        <li>
          <label>
            minutes
            <input class="uk-form-controls uk-inline uk-input"
              type="text"
              name="duration"
              @change="$emit('refresh')"
              v-model.number="statistics.minutes"/>
          </label>
        </li>
      </ul>
      <hr class="uk-divider uk-divider-icon"/>
    </details>
    <section v-for="attribute in attributes" :key="attribute.label" class="uk-margin-small">
      <div class="uk-form-label">{{ $label(`objects.${attribute.label}`) }}</div>
      <component v-if="attribute.category"
        :is="attribute.category"
        :parent="item"
        :version="categories[attribute.category] ? categories[attribute.category].version : -1"
        :item="categories[attribute.category] ? categories[attribute.category].values : null"
        :context="context"
        :categories="categories"
        :errors="item.errors"
        :schema="attribute.settings"
        :readonly="item.readonly"
        @create="categories[attribute.category] ? $emit('restore', { id: categories[attribute.category].id }) : $emit('create', { section: attribute })"
        @update="categories[attribute.category] ? $emit(
          'update',
          {id: categories[attribute.category].id, field: $event.field, value: $event.value, link: $event.link}
        ) : $emit('create', {
          section: attribute,
          values: { [$event.field]: $event.value }
        })"
        @delete="$emit('delete', { id: categories[attribute.category].id })"
        @settings="$emit('settings', { id: categories[attribute.category].id, schema: attribute.settings, context: $event.context, fields: $event.fields, tree: $event.tree })">
      </component>
      <Metadata v-else-if="attribute.label === 'metadata'"
        :version="item.version"
        :item="item.values"
        :category="item.category"
        :label="item.label"
        :readonly="item.readonly"
        @update="$emit('update', {id: item.id, field: $event.field, value: $event.value})">
      </Metadata>
      <Access v-else-if="attribute.label === 'policy_groups'"
        :version="item.version"
        :item="item.values"
        :category="item.category"
        :label="item.label"
        :context="context"
        :options="context.policy_groups(item.id)"
        :readonly="item.readonly"
        @update="$emit('update', {id: item.id, field: $event.field, value: $event.value})">
      </Access>
      <Audit v-else-if="attribute.label === 'audit'"
        :version="item.version"
        :item="item.values"
        :category="item.category"
        :label="item.label"
        :readonly="item.readonly"
        @update="$emit('update', {id: item.id, field: 'audit', value: $event})">
      </Audit>
      <EventType v-else-if="attribute.label === 'event_type'"
        :version="item.version"
        :item="item.values"
        :category="item.category"
        :label="item.label"
        :context="context"
        :readonly="item.readonly"
        @update="$emit('update', {id: item.id, field: 'event_type', value: $event})">
      </EventType>
      <EventType v-else-if="attribute.label === 'emits'"
        :version="item.version"
        :item="item.values"
        :category="item.category"
        :label="item.label"
        :context="context"
        :readonly="item.readonly"
        :references="item.references">
      </EventType>
      <KeyType v-else-if="attribute.label === 'key_type'"
        :version="item.version"
        :item="item.values"
        :category="item.category"
        :label="item.label"
        :context="context"
        :readonly="item.readonly"
        @update="$emit('update', {id: item.id, field: 'key_type', value: $event})">
      </KeyType>
      <ObjectValue v-else-if="attribute.label === 'page_size' || attribute.label === 'cache_seconds'"
        :version="item.version"
        :name="attribute.label"
        :item="item.values"
        :readonly="item.readonly"
        @update="$emit('update', {id: item.id, field: attribute.label, value: $event})">
      </ObjectValue>
      <SummaryProjection v-else-if="attribute.label === 'summary'"
        :version="context.get(item.parent).version"
        :name="attribute.label"
        :item="context.get(item.parent).values"
        :readonly="context.get(item.parent).readonly"
        @update="$emit('update', {id: item.parent, field: attribute.label, value: $event})">
      </SummaryProjection>
      <SearchProjection v-else-if="attribute.label === 'search'"
        :version="context.get(item.parent).version"
        :name="attribute.label"
        :item="context.get(item.parent).values"
        :readonly="context.get(item.parent).readonly"
        @update="$emit('update', {id: item.parent, field: attribute.label, value: $event})">
      </SearchProjection>
      <Renamed v-else-if="attribute.label === 'renamed'"
        :version="item.version"
        :name="attribute.label"
        :item="item.values"
        :structure="active.structure"
        :readonly="item.readonly"
        @update="$emit('update', {id: item.id, field: attribute.label, value: $event})">
      </Renamed>
      <MappingSource v-else-if="attribute.label === 'src'"
        :version="item.version"
        :name="attribute.label"
        :item="item.values"
        :structure="active.structure"
        :readonly="item.readonly"
        @update="$emit('update', {id: item.id, field: attribute.label, value: $event})">
      </MappingSource>
      <MappingContext v-else-if="attribute.label === 'context'"
        :version="item.version"
        :name="attribute.label"
        :item="item.values"
        :structure="active.structure"
        :readonly="item.readonly"
        @update="$emit('update', {id: item.id, field: attribute.label, value: $event})">
      </MappingContext>
      <MappingBody v-else-if="attribute.label === 'body'"
        :version="item.version"
        :name="attribute.label"
        :item="item.values"
        :structure="active.structure"
        :readonly="item.readonly"
        @update="$emit('update', {id: item.id, field: attribute.label, value: $event})">
      </MappingBody>
    </section>
    <template v-if="search === null && !sort">
      <details v-for="section in sections" :key="section.title" open>
        <summary>
          <h3 class="uk-margin-remove-bottom uk-inline">{{ $label(`title.${section.label}`) }}</h3>
          <button class="uk-button uk-button-link uk-float-right uk-position-relative uk-position-z-index"
                v-on:click.prevent="$emit('paste', {id: item.id, section})"
                v-if="copy && item && item.id !== copy.id && copy.category == section.category">
                <i uk-icon="icon: move"></i>
          </button>
          <button v-else-if="item && !item.readonly && (section.category === 'Property' || section.category === 'Option')"
            class="uk-button uk-button-link uk-float-right uk-position-relative uk-position-z-index"
            v-on:click.prevent="$emit('copy', {id: item.id, category: section.category})">
            <i uk-icon="icon: copy"></i>
            <i uk-icon="icon: check" class="uk-meta" v-if="copy && item.id === copy.id"></i>
          </button>
        </summary>
        <p class="uk-margin-left uk-margin-remove-top uk-text-right uk-text-meta uk-animation-fade" v-if="help">
          {{ $help(`objects.${section.label}`) }}
        </p>
        <ul class="uk-clearfix uk-list uk-light uk-margin-remove-top">
          <template v-for="(child, i) in categories[section.category]">
          <li v-if="child.values" :key="child.id" :id="`editor-active-${child.id.replace('.', '-')}`"
              class="uk-position-relative uk-animation-fade uk-animation-fast"
              :draggable="!child.readonly && selected !== child && (section.category === 'Property' || section.category === 'Option')"
              @dragstart="startDrag($event, section.category, i)"
              @dragend="endDrag($event, section.category, i)"
              @drop='onDrop($event, section.category, i)'
              @dragover.prevent
              @dragenter.prevent>
              <button class="uk-button uk-button-link offset-left"
                v-on:click="tryShift(section.category, i)">
                <i uk-icon="icon: chevron-up"></i>
              </button>
            <component
              :is="`Acorn${section.category}`"
              :parent="item"
              :version="child.version"
              :item="child.values"
              :category="child.category"
              :label="child.section.label"
              :errors="child.errors"
              :editing="selected && selected.id === child.id"
              :context="context"
              :categories="categories"
              :references="child.references"
              :structure="active.structure"
              :schema="section.settings"
              :readonly="child.readonly"
              @edit="toggleEdit($event || child)"
              @center="$emit('center', $event || { id: child.id })"
              @focus="$emit('focus', $event || { id: child.id })"
              @update="$emit('update', {id: child.id, field: $event.field, value: $event.value, link: $event.link})"
              @restore="$emit('restore', { id: child.id, field: $event.field, value: $event.value })"
              @unlink="$emit('link', {id: child.id, src: $event, dst: child.id})"
              @delete="$emit('delete', {id: child.id})"
              @settings="$emit('settings', { id: child.id, schema: section.settings, context: $event.context, fields: $event.fields, tree: $event.tree })">
            </component>
            <ul class="uk-comment-meta uk-text-right" v-if="metrics && metrics.has(child.id)">
              <li>
                <label>
                  requests: {{ metrics.get(child.id).count }}
                </label>
              </li>
              <li>
                <label>
                  warnings: {{ metrics.get(child.id).warnings }}
                </label>
              </li>
              <li>
                <label>
                  errors: {{ metrics.get(child.id).errors }}
                </label>
              </li>
              <li>
                <label>
                  percentile:
                  {{ metrics.get(child.id).duration_50 }}ms (50<sup>th</sup>) <br/>
                  {{ metrics.get(child.id).duration_75 }}ms (75<sup>th</sup>) <br/>
                  {{ metrics.get(child.id).duration_95 }}ms (95<sup>th</sup>) <br/>
                  {{ metrics.get(child.id).duration_99 }}ms (99<sup>th</sup>)
                </label>
              </li>
            </ul>
          </li>
          </template>
          <li v-if="!readonly">
            <button class="uk-button uk-button-default uk-width-expand uk-placeholder uk-text-center uk-padding-small" @click.prevent="$emit('create', { section, values: { cardinality: '1' } })">
                {{ $message('objects.create', { type: $label(`objects.${section.label}`) }) }}
                <i class="uk-margin-small-left" uk-icon="icon: plus"></i>
            </button>
          </li>
        </ul>
      </details>
    </template>
    <section v-else>
        <ul class="uk-list uk-margin-bottom" v-if="results.length">
          <li v-for="child in results" :key="child.id" :id="`editor-active-${child.id.replace('.', '-')}`">
            <component
              :is="`Acorn${child.category}`"
              :parent="item"
              :version="child.version"
              :item="child.values"
              :category="child.category"
              :label="child.section.label"
              :errors="child.errors"
              :editing="selected && selected.id === child.id"
              :context="context"
              :categories="categories"
              :attribute="child.category"
              :references="child.references"
              :structure="active.structure"
              :schema="child.section.settings"
              :readonly="child.readonly"
              @edit="toggleEdit($event || child)"
              @focus="$emit('focus', $event || { id: child.id })"
              @center="$emit('center', $event || { id: child.id })"
              @update="$emit('update', {id: child.id, field: $event.field, value: $event.value, link: $event.link})"
              @restore="$emit('restore', { id: child.id, field: $event.field, value: $event.value })"
              @unlink="$emit('link', {id: child.id, src: $event, dst: child.id})"
              @delete="$emit('delete', {id: child.id})"
              @settings="$emit('settings', { id: child.id, schema: child.section.settings, context: $event.context, fields: $event.fields, tree: $event.tree })">
            </component>
            <ul class="uk-comment-meta uk-text-right" v-if="metrics && metrics.has(child.id)">
              <li>
                <label>
                  requests: {{ metrics.get(child.id).count }}
                </label>
              </li>
              <li>
                <label>
                  warnings: {{ metrics.get(child.id).warnings }}
                </label>
              </li>
              <li>
                <label>
                  errors: {{ metrics.get(child.id).errors }}
                </label>
              </li>
              <li>
                <label>
                  percentile:
                  {{ metrics.get(child.id).duration_50 }}ms (50<sup>th</sup>) <br/>
                  {{ metrics.get(child.id).duration_75 }}ms (75<sup>th</sup>) <br/>
                  {{ metrics.get(child.id).duration_95 }}ms (95<sup>th</sup>) <br/>
                  {{ metrics.get(child.id).duration_99 }}ms (99<sup>th</sup>)
                </label>
              </li>
            </ul>
          </li>
        </ul>
        <div class="uk-text-italic uk-text-center uk-light uk-margin-top" v-else>{{ $message('objects.noresults') }}</div>
    </section>
  </section>

</template>

<script>
import { mapState } from 'vuex';
import UIkit from 'uikit';
import AcornDomain from './entities/Realm.vue'
import AcornEngine from './entities/Realm.vue'
import AcornPortal from './entities/Realm.vue'
import AcornProvider from './entities/Provider.vue'
import AcornRegex from './entities/Regex.vue'
import AcornLookup from './entities/Object.vue'
import AcornConfiguration from './entities/Object.vue'
import AcornDataType from './entities/Object.vue'
import AcornPrototype from './entities/Object.vue'
import AcornEvent from './entities/Object.vue'
import AcornSubscription from './entities/Object.vue'
import AcornCommand from './entities/Object.vue'
import AcornQuery from './entities/Object.vue'
import AcornAggregateRoot from './entities/Object.vue'
import AcornProcess from './entities/Object.vue'
import AcornUserInput from './entities/Object.vue'
import AcornSubprocess from './entities/Object.vue'
import AcornInterrupt from './entities/Object.vue'
import AcornAction from './entities/Object.vue'
import AcornContext from './entities/Object.vue'
import AcornView from './entities/Object.vue'
import AcornPolicyGroup from './entities/PolicyGroup.vue'
import AcornOption from './entities/Option.vue'
import AcornProperty from './entities/Property.vue'
import AcornFieldConstraint from './entities/FieldConstraint.vue'
import AcornNamedConstraint from './entities/NamedConstraint.vue'
import AcornIndex from './entities/Index.vue'
import AcornEntity from './entities/Entity.vue'
import AcornTable from './entities/Table.vue'
import AcornComponent from './entities/Object.vue'
import AcornWorkflow from './entities/Workflow.vue'
import AcornDependency from './entities/Dependency.vue'
import AcornNavigation from './entities/Navigation.vue'
import Settings from './entities/Settings.vue'
import Metadata from './entities/Metadata.vue'
import Access from './entities/Access.vue'
import Audit from './entities/Audit.vue'
import EventType from './entities/EventType.vue'
import KeyType from './entities/KeyType.vue'
import Reference from './entities/Reference.vue'
import Proxy from './entities/Proxy.vue'
import ObjectValue from './entities/ObjectValue.vue'
import Document from './entities/Document.vue'
import Renamed from './entities/Renamed.vue'
import MappingSource from './entities/MappingSource.vue'
import MappingContext from './entities/MappingContext.vue'
import MappingBody from './entities/MappingBody.vue'
import SummaryProjection from './entities/SummaryProjection.vue'
import SearchProjection from './entities/SearchProjection.vue'
import MiniSearch from 'minisearch';

export default {
  name: 'Layer',
  props: {
    page: Object,
    categories: Object,
    item: Object,
    copy: Object,
    selected: Object,
    schema: Object,
    context: Object,
    search: String,
    active: Object,
    readonly: Boolean,
    metrics: Map,
    statistics: Object,
  },
  components: {
    AcornDomain,
    AcornEngine,
    AcornPortal,
    AcornProvider,
    AcornLookup,
    AcornRegex,
    AcornConfiguration,
    AcornDataType,
    AcornPrototype,
    AcornEvent,
    AcornSubscription,
    AcornCommand,
    AcornQuery,
    AcornAggregateRoot,
    AcornProcess,
    AcornUserInput,
    AcornSubprocess,
    AcornInterrupt,
    AcornAction,
    AcornContext,
    AcornView,
    AcornPolicyGroup,
    AcornProperty,
    AcornOption,
    AcornFieldConstraint,
    AcornNamedConstraint,
    AcornIndex,
    AcornEntity,
    AcornTable,
    AcornComponent,
    AcornWorkflow,
    AcornDependency,
    AcornNavigation,
    Document,
    Settings,
    Metadata,
    Access,
    Audit,
    EventType,
    KeyType,
    Reference,
    Proxy,
    ObjectValue,
    Renamed,
    MappingSource,
    MappingContext,
    MappingBody,
    SummaryProjection,
    SearchProjection,
},
  data() {
    return {
      scroll: UIkit.scroll(this.$refs.container, {offset: 160}),
      minisearch: null,
      sort: this.statistics.sort,
      results: [],
    };
  },
  mounted() {
    if (this.sort) {
      this.doSort(this.sort);
    }
    this.scrollIntoView();
  },
  beforeDestroy() {
  },
  watch: {
    selected() {
      if (this.selected) this.scrollIntoView();
    },
    search() {
      if (this.search === null) {
        this.minisearch = null;
        return;
      }

      this.sort = '';
      if (!this.minisearch) {
        this.minisearch = new MiniSearch({
          fields: ['name', 'description', 'category', 'subject', 'tenant', 'claim', 'group'],
          searchOptions: {
            boost: { name: 3, claim: 2, category: 2 },
            fuzzy: 0.2,
            prefix: true,
          },
        });
        for (const field in this.categories) {
          if (Array.isArray(this.categories[field])) this.minisearch.addAll(this.categories[field].filter(x => x.values).map(x => ({...x.values, category: x.category, id: x.id})));
        }
      }
      const results = this.minisearch.search(this.search);
      const scores = new Map();
      let max = 0;
      let min = Infinity;
      results.forEach(obj => {
          max = Math.max(max, obj.score);
          min = Math.min(min, obj.score);
          scores.set(obj.id, obj.score);
      });
      this.results = results.map(result => this.context.get(result.id)).filter(obj => !!obj);
      if (this.selected && this.results.findIndex(obj => obj.id == this.selected.id) === -1) {
        this.results.splice(0, 0, this.selected);
      }
      this.$emit("scale", {max, min, scores, key: ''});
    },
    statistics() {
      this.sort = this.statistics.sort;
    },
    sort() {
      if (this.sort) {
        this.doSort(this.sort);
      } else this.$emit("scale", {max: 0, min: Infinity, scores: new Map(), key: ''});
    },
    metrics() {
      if (this.metrics && this.sort) {
        this.doSort(this.sort);
      }
    },
  },
  computed: {
    proxy() {
      return this.schema.proxy && this.item.values.description == null;
    },
    attributes() {
      if (!this.schema.proxy) {
        return this.schema.attributes;
      } else if (this.item.values.description == null) {
        return this.schema.attributes.filter(x => this.schema.proxy[x.label] ?? true);
      } else {
        return this.schema.attributes.filter(x => !(this.schema.proxy[x.label] ?? false));
      }
    },
    sections() {
      if (!this.schema.proxy) {
        return this.schema.sections;
      } else if (this.item.values.description == null) {
        return this.schema.sections.filter(x => this.schema.proxy[x.label] ?? true);
      } else {
        return this.schema.sections.filter(x => !(this.schema.proxy[x.label] ?? false));
      }
    },
    ...mapState('api', ['help']),
  },
  methods: {
    startDrag: (evt, category, idx) => {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('item', idx);
      evt.dataTransfer.setData('category', category);
      evt.target.classList.add("dragging");
    },
    endDrag: (evt) => {
      evt.target.classList.remove("dragging");
    },
    onDrop (evt, category, dst) {
      const src = parseInt(evt.dataTransfer.getData('item'), 10);
      if (!isNaN(dst) && src !== dst && category === evt.dataTransfer.getData('category')) {
        this.$emit('shift', {category, src, dst});
      }
    },
    tryShift(category, src) {
      let dst = src;
      for (let i = 0; i < src; i++) {
        if (this.categories[category][i].values) dst = i;
      }
      if (dst < src) this.$emit('shift', {category, src, dst});
    },
    toggleEdit(item) {
      if (item === this.selected) {
        this.$emit('edit', null);
      } else {
        this.$emit('edit', item);
      }
    },
    doSort(key) {
      const results = [];
      for (const field in this.categories) {
        if (Array.isArray(this.categories[field])) results.push(...this.categories[field].filter(x => x.values && this.metrics.has(x.id) && this.metrics.get(x.id)[key] > 0));
      }
      const scores = new Map();
      let max = 0;
      let min = Infinity;
      results.forEach(obj => {
          const score = this.metrics.get(obj.id)[key]
          max = Math.max(max, score);
          min = Math.min(min, score);
          scores.set(obj.id, score);
      });
      this.results = results.sort((a, b) => scores.get(b.id) - scores.get(a.id));
      this.$emit("scale", {max, min, scores, key});
    },
    scrollIntoView() {
      this.$nextTick(() => {
        if (this.selected && this.selected.id) {
          const selector = `#editor-active-${this.selected.id.replace('.', '-')}`;
          const target = document.querySelector(`${selector} [data-edit]`);
          if (target) {
            target.focus();
            this.scroll.scrollTo(selector);
          }
        }
      });
    },
  },
}
</script>

<style>
.offset-left {
  position: absolute;
  top: 6px;
  left: -28px;
}
</style>