<template>
  <div>
    <ObjectAttribute :context="context" label="data type" :value="name" :primitive="false" :required="true"></ObjectAttribute>
    <Settings v-if="schema && item && item.target"
      :error="error"
      :sections="schema.sections"
      :categories="categories"
      @focus="$emit('settings', { context: parent.id, fields: item.target, tree: context.output(item.name) })">
      <template v-if="item.renamed">
        <li v-for="(line, i) in item.renamed.split(' ')" :key="i">{{ line.replace('=', ' &#8594; ') }}</li>
      </template>
      <li class="uk-text-italic" v-else>no renamed fields</li>
    </Settings>
  </div>
</template>

<script>
import Settings from './Settings.vue'
import ObjectAttribute from './ObjectAttribute.vue';

function assign(src, dst) {
  dst.name = src.item?.target ?? '';
  return dst;
}

export default {
  name: 'Proxy',
  props: {
    parent: Object,
    version: Number,
    item: Object,
    categories: Object,
    error: Object,
    context: Object,
    schema: Object,
  },
  components: {
    Settings,
    ObjectAttribute,
  },
  data() {
    return assign(this, {});
  },
  methods: {
  },
  computed: {
  },
  watch: {
    version() {
      assign(this, this);
    },
  },
}
</script>