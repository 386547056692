<template>
  <Lock :wiki="wiki" v-on:ready="loadAsync">
    <template slot="menu">
      <li v-if="active && !changes && consumer.hasStatistics && version">
        <button class="uk-icon-button"
          :class="{'uk-disabled': stats !== null, 'uk-button-primary': stats === null}"
          uk-icon="icon: refresh"
          v-on:click="doRefresh">
        </button>
      </li>
      <li v-if="fullscreenEnabled">
        <button class="uk-icon-button uk-button-primary"
          :class="{'uk-button-secondary': fullscreen}"
          :uk-icon="fullscreen ? 'icon: shrink' : 'icon: expand'"
          v-on:click="toggleFullscreen('model')">
        </button>
      </li>
      <li v-if="active && !changes && !context.readonly">
        <button class="uk-icon-button uk-button-primary"
          :uk-icon="undo.length ? 'icon: cloud-upload' : 'icon: refresh'"
          v-on:click="doUpload">
        </button>
      </li>
      <li v-if="active">
        <button class="uk-icon-button uk-button-primary"
          uk-icon="icon: location"
          v-on:click="doCenter({ id: selected ? selected.id : null })">
        </button>
      </li>
      <li v-if="active && !active.modes.length && !changes && !context.readonly">
        <button class="uk-icon-button"
          :class="{'uk-disabled': false, 'uk-button-primary': multi}"
          uk-icon="icon: move"
          v-on:click="multi = !multi">
        </button>
      </li>
      <li v-if="active && !active.modes.length && !changes && !context.readonly">
        <button class="uk-icon-button"
          :class="{'uk-disabled': !selected, 'uk-button-primary': selected}"
          uk-icon="icon: trash"
          v-on:click="doDelete(selected)">
        </button>
      </li>
    </template>
    <article class="uk-flex uk-flex-column uk-flex-middle" v-if="!merge && changes" id="changes">
      <SiteHeader class="uk-margin-top"></SiteHeader>
      <section class="uk-width-xlarge uk-padding">
        <h2>{{ path }} <span class="uk-text-meta" v-if="wiki">{{ wiki }}</span></h2>
        <Changelog :consumer="consumer" :context="context" :changes="changes"
          @merge="startMerge"
          @success="loadAsync"
          @select="doSelect"
          @cancel="doSelect(null)">
        </Changelog>
      </section>
      <div class="uk-flex-1"></div>
      <SiteFooter class="uk-flex-none uk-flex-bottom"></SiteFooter>
    </article>
    <article class="uk-grid uk-grid-collapse" uk-grid v-else-if="page">
      <div id="editor-model" class="uk-background-default uk-inline uk-width-1-1 uk-width-expand@l">
        <MergeDetail style="height: 100dvh;" v-if="merge"
          :context="context"
          :layer="merge.layer"
          :active="merge.active"
          @merge="doMerge">
        </MergeDetail>
        <SvgTree v-else-if="active.structure"
          :bus="bus"
          :context="context"
          :item="layer.item"
          :selected="selected"
          :typeid="active.structure.key"
          :schema="layer.schema"
          :categories="page.categories"
          :readonly="layer.readonly"
          @context="doModal">
        </SvgTree>
        <SvgCanvas v-else
          :bus="bus"
          :layer="mode"
          :context="context"
          :metrics="metrics"
          :search="layer.schema.search ? null : search"
          :scores="scores"
          :selected="selected"
          :selectable="!active.modes.length"
          :multi="multi"
          :readonly="layer.readonly"
          @context="doModal"
          @edit="doSelect"
          @focus="doFocus"
          @move="doMove"
          @link="doLink"
          @multimove="doMultiMove">
        </SvgCanvas>
        <div class="uk-position-top-left" style="pointer-events: none" :class="active.modes.length ? '' : 'uk-hidden@l'">
          <nav class="uk-navbar-container uk-padding-small" uk-navbar>
            <header class="uk-navbar-left uk-width-medium uk-width-large@m" style="pointer-events: all">
              <NavHeader :title="layer.title" :subtitle="layer.subtitle" :active="active" :selector="selector" :links="links" @focus="doActivate" class="uk-hidden@l"></NavHeader>
              <select v-model="mode" class="uk-select uk-width-medium" v-if="active.modes.length">
                <option v-for="opt in active.modes" :value="opt.value" :key="opt.value">{{ opt.label }}</option>
              </select>
            </header>
          </nav>
        </div>
        <div class="uk-position-bottom-left uk-padding-small" style="opacity: 0.8;">
          <a id="link-focus-detail" href="#editor-detail" uk-scroll class="uk-hidden@l uk-border-pill uk-button uk-button-primary uk-margin-small-right"
            v-on:click="if (defer) $event.preventDefault(); scrollToDetail();"
            :class="{'uk-button-secondary': page === null}">
            <i uk-icon="icon: pencil"></i>
          </a>
          <template v-if="merge && merge.active">
          <button class="uk-button uk-button-text" v-on:click="doRemote">remote</button>
          <button class="uk-button uk-button-text uk-margin-left uk-margin-right" v-on:click="doLocal">local</button>
          </template>
          <template v-else>
          <button
            class="uk-border-pill uk-button uk-button-secondary uk-margin-small-right"
            :class="{'uk-hidden': active === null || active.parent === null }"
            v-on:click="doQuickNav(true)">
            <i uk-icon="icon: chevron-up"></i>
          </button>
          <button
            class="uk-border-pill uk-button uk-button-secondary uk-margin-small-right"
            :class="{'uk-hidden': active === null || active.child === null}"
            v-on:click="doQuickNav(false)">
            <i uk-icon="icon: chevron-down"></i>
          </button>
          </template>

        </div>
      </div>
      <section ref="detail" id="editor-detail" class="uk-padding-top uk-height-viewport uk-width-large@l uk-flex-first@l uk-background-secondary" :key="layer.key">
        <header class="uk-background-secondary uk-panel uk-light" uk-sticky>
          <nav class="uk-navbar-container uk-navbar-transparent" uk-navbar="mode: click" v-if="merge">
            <div class="uk-navbar-left uk-margin-left uk-navbar-item">
              <MergeHeader :title="merge.title" :subtitle="merge.subtitle" @cancel="merge = null" class="nav-overlay"></MergeHeader>
            </div>
          </nav>
          <nav class="uk-navbar-container uk-navbar-transparent" uk-navbar="mode: click" v-else>
            <div class="uk-navbar-left uk-margin-left uk-navbar-item">
              <NavHeader :title="layer.title" :subtitle="layer.subtitle" :active="active" :selector="selector" :links="links" @focus="doActivate" class="nav-overlay"></NavHeader>
            </div>
            <div class="nav-overlay uk-navbar-right">
                <button ref="search" class="uk-navbar-toggle uk-flex-none uk-padding-small" uk-search-icon uk-toggle="target: .nav-overlay; animation: uk-animation-fade"  v-on:click="search = ''">
                </button>
            </div>
            <div class="nav-overlay uk-navbar-left uk-flex-1" hidden>
                <div class="uk-navbar-item uk-width-expand uk-padding-remove">
                    <form class="uk-search uk-search-navbar uk-width-1-1" v-on:submit.prevent>
                        <input class="uk-search-input" v-model="search" type="search" placeholder="search..." autofocus>
                    </form>
                </div>
                <button class="uk-navbar-toggle uk-padding-small" uk-close uk-toggle="target: .nav-overlay; animation: uk-animation-fade" v-on:click="search = null; scores = null;">
                </button>
            </div>
          </nav>
        </header>

        <MergeContext v-if="merge"
          :context="context"
          :layer="merge.layer"
          :active="merge.active"
          :steps="merge.steps"
          @edit="merge.active = $event">
        </MergeContext>
        <Layer v-else
          :context="context"
          :search="layer.schema.search ? search : null"
          :schema="layer.schema"
          :item="layer.item"
          :selected="defer ? null : selected"
          :copy="copy"
          :page="page.upstream"
          :categories="page.categories"
          :active="active"
          :readonly="layer.readonly"
          :statistics="statistics"
          :metrics="metrics"
          @edit="selected = $event"
          @refresh="doRefresh"
          @settings="doSettings"
          @focus="doFocus"
          @center="doCenter"
          @create="doCreate"
          @update="doUpdate"
          @shift="doShift"
          @restore="doRestore"
          @link="doLink"
          @delete="doDelete"
          @copy="doCopy"
          @paste="doPaste"
          @scale="scores = $event; statistics.sort = scores.key">
        </Layer>

        <ContextModal v-if="modal"
          :key="modal.key"
          :context="context"
          :title="modal.id == null ? layer.title : context.label(modal.id)"
          :subtitle="modal.id == null ? layer.subtitle : context.category(modal.id)"
          :schema="layer.schema"
          :parent="page.id"
          :selected="modal.selected"
          :field="modal.field"
          :editing="modal.editing"
          :x="modal.x"
          :y="modal.y"
          :captured="modal.captured"
          :group="modal.group"
          @create="doCreate"
          @update="doUpdate"
          @migrate="doMigrate"
          @group="doGroup"
          @link="doLink"
          @close="modal = null">
        </ContextModal>

        <footer class="uk-position-z-index uk-position-fixed uk-overlay uk-position-bottom-right uk-padding-small" style="opacity: 0.8;">
          <div class="uk-flex uk-flex-center">
            <button class="uk-icon-button" v-if="!context.readonly && !merge"
              :class="{'uk-disabled': !undo.length, 'uk-button-primary': undo.length}"
              uk-icon="icon: history"
              v-on:click="doUndo()">
            </button>
            <button class="uk-icon-button uk-margin-small-left" v-if="!context.readonly && !merge"
              :class="{'uk-disabled': !redo.length, 'uk-button-primary': redo.length}"
              uk-icon="icon: future"
              v-on:click="doRedo()">
            </button>
            <button class="uk-button uk-button-primary"
              :disabled="!merge.active.status"
              v-on:click="doMerge" v-if="merge && merge.active">
              merge
            </button>
            <a id="link-focus-model" href="#editor-model"
              uk-scroll
              class="uk-icon-button uk-button-primary uk-hidden@l uk-margin-small-left"
              uk-icon="icon: chevron-up"
              v-on:click="scrollToModel">
            </a>
          </div>
        </footer>
      </section>
    </article>
  </Lock>
</template>

<script>
import Vue from "vue";
import UIkit from 'uikit';
import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';
import Lock from '@/components/Lock.vue';
import Changelog from '@/components/canvas/Changelog.vue';
import Layer from '@/components/canvas/Layer.vue';
import MergeHeader from '@/components/canvas/conflict/MergeHeader.vue';
import MergeContext from '@/components/canvas/conflict/MergeContext.vue';
import MergeDetail from '@/components/canvas/conflict/MergeDetail.vue';
import NavHeader from '@/components/canvas/NavHeader.vue';
import SvgTree from '@/components/canvas/SvgTree.vue';
import SvgCanvas from '@/components/canvas/SvgCanvas.vue';
import ContextModal from '@/components/canvas/ContextModal.vue';
import { mapActions, mapState } from "vuex";
import { createContext } from "@/scripts/canvas.js";

function* inject(schema, fields, context) {
  for (const child of schema) {
    const field = {
      value: child.name,
      label: child.name,
      children: null,
      category: null,
      data_type: child.data_type,
      cardinality: child.cardinality,
    };
    fields.push(field);
    if (context.has(child.data_type)) {
      yield {
        child,
        field,
      };
    }
  }
}

function batch(service, wiki, path, promises, context, ...items) {
  const promise = service.getFromJsonAsync(
    `/acorn/consumer/module/${wiki}/${path}/parse?fragments=${items.map(x => x.fragment).join(',')}`
  ).then(value => new Map(value.rows.map(x => [x.fragment, x])));
  for (const item of items) {
    promises.set(item.id, promise.then(value => context.layer(
      value.get(item.fragment),
      item.parent,
      item.id,
      item.category,
      `${item.fragment}/`)))
  }
  try {
    return Promise.all(items.map(x => promises.get(x.id)));
  } catch (err) {
    for (const item of items) {
      promises.delete(item.id);
    }
    throw err;
  }
}

async function load(service, wiki, path, promises, key, context) {
  const item = context.get(key);
  if (!promises.has(item.id)) {
    const layer = context.layers.get(item.parent);
    const promise = item.fragment ? service.getFromJsonAsync(
      `/acorn/consumer/module/${wiki}/${path}/parse?fragments=${item.fragment}`
    ) : Promise.resolve();
    // TODO: cope with renamed objects
    promises.set(item.id, promise.then(value => context.layer(
      value?.rows[0],
      layer.id,
      item.id,
      item.category,
      `${item.fragment}/`)));
  }

  try {
    await promises.get(item.id);
  } catch (err) {
    promises.delete(item.id);
    throw err;
  }

  return item;
}

async function hydrate(service, wiki, path, promises, context, key) {
  const page = await promises.get(key);
  while (context === context && page.pending.length) {
    const items = [];
    while (page.pending.length && items.length < 50) {
      const id = page.pending.pop();
      const obj = context.get(id);
      if (!promises.has(id)) items.push(obj);
    }
    for (const layer of await batch(service, wiki, path, promises, context, ...items)) {
      if (layer.pending.length) await hydrate(service, wiki, path, promises, context, layer.id);
    }
  }
}

async function collect(service, wiki, path, promises, context, capture, ...changes) {
    for (const change of changes) {
      if (change.deferred) {
        // Load the referenced page
        const item = await load(service, wiki, path, promises, change.obj, context);

        // Walk up the tree, loading all pages that may reference this.
        let obj = item;
        while (obj.parent != null) {
          obj = context.get(obj.parent);
          for (const target of obj.references.keys()) {
            if (!obj.references.get(target)) {
              const relation = context.get(target);
              if (relation?.upstream) {
                const parent = await load(service, wiki, path, promises, relation.id, context);
                await hydrate(service, wiki, path, promises, context, parent.id);
              }
            }
          }
        }

        // Finally, analyse the current item for changes if relevant.
        if (context.layers.get(item.id).upstream) {
          capture.push(change);
          await hydrate(service, wiki, path, promises, context, item.id);
          await collect(service, wiki, path, promises, context, capture, ...change.deferred());
        }
      } else {
        capture.push(change);
      }
    }
    return capture;
}

function* flatten(fields, path, prefix) {
  for (let child of fields) {
    yield {
      ...child,
      label: `${path}/${child.label}`,
      value: `${prefix}${child.value}`,
    };
    if (child.children) {
      yield* flatten(
        child.children,
        `${path}/${child.label}`,
        `${prefix}${child.value}.`);
    }
  }
}

export default {
  name: "Canvas",
  props: {
    wiki: String,
    path: String,
    label: String,
    version: String,
  },
  components: {
    Lock,
    SiteHeader,
    SiteFooter,
    Changelog,
    Layer,
    NavHeader,
    MergeHeader,
    MergeContext,
    MergeDetail,
    SvgTree,
    SvgCanvas,
    ContextModal,
  },
  data() {
    return {
      fullscreenEnabled: document.fullscreenEnabled,
      fullscreen: !!document.fullscreenElement,
      consumer: null,
      stats: null,
      metrics: null,
      copy: null,
      mode: null,
      page: null,
      layer: null,
      active: null,
      selector: null,
      search: null,
      scores: null,
      bus: new Vue(),
      undo: [],
      redo: [],
      context: null,
      promises: new Map(),
      selected: null,
      loading: null,
      defer: false,
      multi: false,
      modal: null,
      merge: null,
      statistics: {
        minutes: 60 * 12,
        sort: '',
      },
      links: [
        {
          to: { name: "project", params: { wiki: this.wiki, path: this.path } },
          icon: "cog",
          label: "settings",
        },
        {
          to: { name: "projects", params: { wiki: this.wiki } },
          icon: "home",
          label: "projects",
        },
      ],
      changes: null,
      i: 0,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    if ((this.undo.length)) {
      const answer = window.confirm(
        "Do you really want to leave? Your changes will not be saved!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  computed: {
    ...mapState("api", ["service"]),
  },
  watch: {
    mode() {
      this.loading = this.drainAsync(this.promises, this.context, this.mode);
    },
  },
  methods: {
    doModal({ id, x, y, captured, group, field }) {
      if (id != null && this.page.id != null) {
        // Selecting an object anywhere except in the top level
        this.modal = { key: this.i++, selected: id, x, y, captured, group, editing: this.selected?.id ?? null, field };
      } else if (id == null && this.page.parent == null) {
        // Adding a new object in the top two levels
        this.modal = { key: this.i++, selected: id, x, y, captured, group, editing: this.selected?.id ?? null, field };
      }
    },
    async doRefresh() {
      try {
        let { endpoints } = await this.consumer.asStatisticsUri()
          .withParams(this.version, this.statistics.minutes)
          .getAsync();
        const metrics = new Map(
          endpoints.map((obj) => [`${obj.realm}.${obj.name}`, obj])
        );
        endpoints.forEach((obj) => {
          if (!metrics.has(obj.realm)) {
            const cloned = { ...obj };
            cloned.name = cloned.realm;
            cloned.realm = null;
            metrics.set(obj.realm, cloned);
          } else {
            var parent = metrics.get(obj.realm);
            parent.duration_99 = Math.max(
              parent.duration_99,
              obj.duration_99
            );
            parent.count += obj.count;
            parent.warnings += obj.warnings;
            parent.errors += obj.errors;
          }
        });
        this.stats = null;
        this.metrics = metrics.size > 0 ? metrics : null;
      } catch (err) {
        this.stats = null;
      }
    },
    async doUpload() {
      const moved = new Set();
      const changes = [];
      await collect(this.service, this.wiki, this.path, this.promises, this.context, changes, ...this.context.phase1(moved))
      this.changes = await collect(this.service, this.wiki, this.path, this.promises, this.context, changes, ...this.context.phase2(moved));
    },
    async drainAsync(promises, context, mode) {
      try {
        const page = await promises.get(mode);

        while (context === this.context && page.pending.length && mode === this.mode) {
          const items = [];
          while (page.pending.length && items.length < 50) {
            const id = page.pending.pop();
            const obj = context.get(id);
            if (!promises.has(id)) items.push(obj);
          }
          await batch(this.service, this.wiki, this.path, promises, context, ...items);
        }
      } finally {
        if (context === this.context && mode === this.mode) this.loading = null;
      }
    },
    async loadAsync( result ) {
      [this.page, this.context, this.consumer, this.promises] = await (this.label || this.version ? this.loadTag() : this.loadWorkingCopy(result));
      this.layer = this.context.view(null);
      this.active = {
        parent: null,
        page: this.page,
        layer: this.layer,
        child: null,
        structure: null,
        modes: [],
        icon: null,
      };
      this.undo = [];
      this.redo = [];
      this.mode = null;
      this.selector = this.active;
      this.search = null;
      this.scores = null;
      this.copy = null;
      this.scrollToModel();
      this.doSelect(null);
      this.loading = this.drainAsync(this.promises, this.context, null);
      this.stats = this.consumer.hasStatistics && this.version ? this.doRefresh() : null;
      this.$nextTick(() => {
        this.defer = document.getElementById('link-focus-detail')?.checkVisibility();
      });
    },
    async loadWorkingCopy(result) {
      const context = createContext(this.bus, this.path, false);
      const module = await this.service.getFromJsonAsync(`/acorn/consumer/module/${this.wiki}/${this.path}`);
      if (result?.errors.length) {
        context.decorate(result.model, result.errors);
      }
      const page = context.layer(module.home, null, null, null, '');
      const promises = new Map();
      for (const [key, layer] of context.inject(...module.requirements)) {
        promises.set(key, Promise.resolve(layer));
      }
      return [
        page,
        context,
        this.service.from(module),
        promises,
      ]
    },
    async loadTag() {
      const context = createContext(this.bus, this.path, true);
      const params = [];
      if (this.label) {
        params.push(`label=${this.label}`);
      }
      if (this.version) {
        params.push(`version=${this.version}`);
      }
      const tag = await this.service.getFromJsonAsync(
        `/acorn/consumer/module/${this.wiki}/${this.path}/tag?${params.join(
          "&"
        )}`
      );
      const promises = new Map();
      for (const [key, layer] of context.inject(tag.model, ...tag.requirements)) {
        promises.set(key, Promise.resolve(layer));
      }
      return [
        await promises.get(null),
        context,
        this.service.from(tag),
        promises,
      ]
    },
    async resolveAsync(key) {
      const fields = [];
      if (key && this.context.has(key)) {
        const context = this.context;
        const item = await load(this.service, this.wiki, this.path, this.promises, key, context);
        const captured = new Map([[key, fields]]);
        const stack = [...inject(context.fields(item.id), fields, context)];
        const pending = new Map();
        let j = 0;
        do {
          while (stack.length) {
            if (pending.size > 10) {
              await Promise.race(pending.values());
            }

            const { child, field } = stack.pop();
            if (captured.has(child.data_type)) {
              field.children = captured.get(child.data_type);
              field.category = context.get(child.data_type).category;
            } else {
              field.children = [];
              field.category = context.get(child.data_type).category;
              captured.set(child.data_type, field.children);
              if (child.children) {
                stack.push(...inject(child.children, field.children))
              } else {
                const promise = load(
                  this.service,
                  this.wiki,
                  this.path,
                  this.promises,
                  child.data_type,
                  context).then(obj => stack.push(...inject(context.fields(obj.id), field.children, context)));
                const k = j++;
                pending.set(k, promise.finally(() => pending.delete(k)));
              }
            }
          }
          await Promise.all(pending.values());
        } while (stack.length);
      }
      return [...flatten(fields, '', '')];
    },
    async doSettings({ id, schema, context, fields, tree }) {
      const structure = {
        context: [],
        fields: await this.resolveAsync(fields),
        tree: await this.resolveAsync(tree),
        key: tree,
      };
      if (this.context.has(context)) {
        const item = this.context.get(context);
        for (const ref of item.references) {
          if (ref[1]) {
            structure.context = await this.resolveAsync(ref[0]);
            break;
          }
        }
      }
      if (this.context.has(fields)) {
        const item = this.context.get(fields);
        for (const ref of item.references) {
          if (ref[1]) {
            structure.fields = [...await this.resolveAsync(ref[0]), ...structure.fields];
            break;
          }
        }
      }
      this.layer = this.context.view(id, schema);
      this.active.child = {
        parent: this.active,
        page: this.page,
        layer: this.layer,
        child: null,
        structure,
        modes: [],
        icon: null,
      };
      this.active = this.active.child;
      this.mode = null;
      this.search = null;
      this.scores = null;
    },
    async doFocus({ id }) {
      // TODO: deeper links, cope with new objects, handle duplicate names
      const context = this.context;
      const promises = this.promises;
      const item = await load(this.service, this.wiki, this.path, promises, id, context);
      const modes = [];
      if (item.parent == null) {
        // Case 1: load only direct dependencies
        const dependencies = [];
        for (const dependency of item.section.dependencies) {
          if (context.has(item.values[dependency])) {
            dependencies.push(context.get(item.values[dependency]).id);
          }
        }
        await Promise.all(dependencies.map(id => load(this.service, this.wiki, this.path, promises, id, context)));
      } else {
        // Case 2: load direct dependencies, references, and parent references
        const dependencies = [...item.references.keys()];
        for (const dependency of item.section.dependencies) {
          if (context.has(item.values[dependency])) {
            dependencies.push(context.get(item.values[dependency]).id);
          }
        }

        const parent = this.context.get(item.parent);
        modes.push({
          label: parent.values.name ?? this.$label('objects.unnamed', { label: this.$label(`objects.${parent.section.label}`) }),
          value: parent.id,
        });

        for (const ref of parent.references) {
          if (ref[1]) {
            const mode = context.get(ref[0]);
            modes.push({
              label: mode.values.name ?? this.$label('objects.unnamed', { label: this.$label(`objects.${mode.section.label}`) }),
              value: mode.id,
            });
            dependencies.push(mode.id);
          }
        }
        await Promise.all(dependencies.map(id => load(this.service, this.wiki, this.path, promises, id, context)));
      }
      this.page = await promises.get(item.id);
      this.layer = context.view(item.id);
      this.active.child = {
        parent: this.active,
        page: this.page,
        layer: this.layer,
        child: null,
        structure: null,
        modes,
        icon: null,
      };
      this.active = this.active.child;
      this.mode = item.parent == null ? item.id : item.parent;
      this.selected = null;
      this.search = null;
      this.scores = null;
    },
    async doCenter({ id }) {
      this.context.center(id);
    },
    doCreate({ section, group, position, values }) {
      if (this.redo.length) {
        this.redo = [];
      }
      const action = this.context.create(this.page.id, section, group, position, values);
      this.redo.push({ layer: this.layer, page: this.page, action, active: this.active, mode: this.mode });
      this.doRedo(true);
    },
    doGroup({ captured, name }) {
      if (this.redo.length) {
        this.redo = [];
      }
      const action = this.context.group(captured, name);
      this.redo.push({ layer: this.layer, page: this.page, action, active: this.active, mode: this.mode });
      this.doRedo(true);
    },
    doShift({ category, src, dst }) {
      if (this.redo.length) {
        this.redo = [];
      }
      const action = this.context.shift(this.page.id, category, src, dst);
      this.redo.push({ layer: this.layer, page: this.page, action, active: this.active, mode: this.mode });
      this.doRedo(true);
    },
    doUpdate({ id, field, value, link }) {
      if (this.redo.length) {
        this.redo = [];
      }
      const action = this.context.update(id, field, value, link);
      this.redo.push({ layer: this.layer, page: this.page, action, active: this.active, mode: this.mode });
      this.doRedo(true);
    },
    doMigrate({ parent, captured, target }) {
      if (this.redo.length) {
        this.redo = [];
      }
      const action = this.context.migrate(parent, captured, target);
      this.redo.push({ layer: this.layer, page: this.page, action, active: this.active, mode: this.mode });
      this.doRedo(true);
    },
    doRestore({ id, field, value }) {
      if (this.redo.length) {
        this.redo = [];
      }
      const action = this.context.restore(id, field, value);
      this.redo.push({ layer: this.layer, page: this.page, action, active: this.active, mode: this.mode });
      this.doRedo(true);
    },
    doLink({ id, src, dst, reset }) {
      if (this.redo.length) {
        this.redo = [];
      }
      const action = this.context.link(id, src, dst, reset);
      this.redo.push({ layer: this.layer, page: this.page, action, active: this.active, mode: this.mode });
      this.doRedo(true);
    },
    doMove({ id, x, y, group }) {
      if (this.redo.length) {
        this.redo = [];
      }
      const action = this.context.move(id, x, y, group);
      this.redo.push({ layer: this.layer, page: this.page, action, active: this.active, mode: this.mode });
      this.doRedo(true);
    },
    doMultiMove({ positions }) {
      if (this.redo.length) {
        this.redo = [];
      }
      const action = this.context.multimove(positions);
      this.redo.push({ layer: this.layer, page: this.page, action, active: this.active, mode: this.mode });
      this.doRedo(true);
    },
    doDelete({ id }) {
      if (this.redo.length) {
        this.redo = [];
      }
      const action = this.context.delete(id);
      this.redo.push({ layer: this.layer, page: this.page, action, active: this.active, mode: this.mode });
      this.doRedo(true);
    },
    doCopy({ id, category }) {
      const value = this.context.layers.get(id).categories[category].filter(x => x.values).map(x => x.values);
      if (value.length) {
        this.copy = {
          id,
          category,
          value: JSON.stringify(value),
        };
      }
    },
    doPaste({ id, section }) {
      if (this.redo.length) {
        this.redo = [];
      }
      var value = JSON.parse(this.copy.value);
      const action = this.context.paste(id, section, value);
      this.redo.push({ layer: this.layer, page: this.page, action, active: this.active, mode: this.mode });
      this.doRedo(true);
      this.copy = null;
    },
    doUndo() {
      const { layer, page, action, active, mode } = this.undo.pop();
      const { selected, model } = action.undo();
      this.page = page;
      this.layer = layer;
      this.selector = active;
      while (this.selector.parent) {
        this.selector.parent.child = this.selector;
        this.selector = this.selector.parent;
      }
      this.active = active;
      this.mode = mode;
      // TODO: focus selected element
      this.redo.push({ layer, page, action, active, mode });
      if (model) {
        this.scrollToModel();
        // TODO: center canvas on original position
      } else if (selected) {
        this.scrollToDetail();
      }
      this.doSelect(selected);
    },
    doRedo(suppress) {
      const { layer, page, action, active, mode } = this.redo.pop();
      const { selected, model } = action.redo();
      if (!suppress) {
        this.page = page;
        this.layer = layer;
        this.selector = active;
        while (this.selector.parent) {
          this.selector.parent.child = this.selector;
          this.selector = this.selector.parent;
        }
        this.active = active;
      } else {
        this.active.child = null;
      }
      this.mode = mode;
      this.undo.push({ layer, page, action, active, mode });
      if (!suppress && model) {
        this.scrollToModel();
        // TODO: center canvas on original position
      } else if (!suppress && selected) {
        this.scrollToDetail();
      }
      this.doSelect(selected);
    },
    async startMerge(merge) {
      if (!merge.active) {
        const moved = new Set();
        const changes = [];
        await collect(this.service, this.wiki, this.path, this.promises, this.context, changes, ...this.context.phase1(moved))
        this.changes = await collect(this.service, this.wiki, this.path, this.promises, this.context, changes, ...this.context.phase2(moved));
      } else {
        this.merge = merge;
      }
    },
    async doMerge() {
      for (const step of this.merge.steps) {
        if (!step.status) {
          this.merge.active = step;
          return;
        }
      }

      if (this.redo.length) {
        this.redo = [];
      }
      this.context.merge(this.merge.layer, this.merge.steps, this.merge.unknown, this.merge.upstream);
      const moved = new Set();
      const changes = [];
      await collect(this.service, this.wiki, this.path, this.promises, this.context, changes, ...this.context.phase1(moved))
      this.changes = await collect(this.service, this.wiki, this.path, this.promises, this.context, changes, ...this.context.phase2(moved));
      this.merge = null;
    },
    doRemote() {
      for (const pair of this.merge.active.pairs) {
        if (pair.action != 'automerge') pair.action = 'remote';
      }
      this.merge.active.status = 'remote';
    },
    doLocal() {
      for (const pair of this.merge.active.pairs) {
        if (pair.action != 'automerge') pair.action = 'local';
      }
      this.merge.active.status = 'local';
    },
    doQuickNav(isUp)
    {
      if (isUp)
      {
        const current = this.active;
        this.active = this.active.parent ?? this.active;
        this.page = this.active.page;
        this.layer = this.active.layer;
        this.mode = this.layer.item?.parent == null ? this.layer.item?.id ?? null : this.layer.item.parent;
        this.search = null;
        this.scores = null;
        this.doSelect(current.layer.item);
      }
      else if (this.active.child != null)
      {
        this.active = this.active.child;
        this.page = this.active.page;
        this.layer = this.active.layer;
        this.mode = this.layer.item?.parent == null ? this.layer.item?.id ?? null : this.layer.item.parent;
        this.search = null;
        this.scores = null;
        this.doSelect(null);
      }
    },
    doActivate(active) {
      this.active = active;
      this.page = this.active.page;
      this.layer = this.active.layer;
      this.search = null;
      this.scores = null;
      this.doSelect(null);
    },
    doSelect(selected) {
      document.activeElement.blur();
      this.changes = null;
      this.selected = selected;
    },
    scrollToDetail() {
      this.defer = false;
      if (this.fullscreen && document.getElementById('link-focus-model').offsetParent) {
        document.getElementById('editor-detail').requestFullscreen();
      }
    },
    scrollToModel() {
      var app = document.getElementById('app');
      this.defer = document.getElementById('link-focus-detail')?.checkVisibility();
      if (this.fullscreen && document.fullscreenElement !== app) {
        document.exitFullscreen().then(() => {
          var header = this.$refs.detail.firstChild;
          this.$refs.detail.insertBefore(header, header)
          return app.requestFullscreen();
        });
      } else if (!this.fullscreen && document.getElementById('link-focus-model')?.offsetParent) {
        UIkit.scroll('#link-focus-model').scrollTo();
      }
    },
    toggleFullscreen() {
      if (this.fullscreen && document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        document.getElementById('app').requestFullscreen();
      }

      this.fullscreen = !this.fullscreen;
    },
    ...mapActions("api", ["prepareMerge"]),
  },
};
</script>

<style lang="less">
@import "../assets/less/theme.less";
#changes {
  min-height: 100vh;
}
#changes {
  min-height: 100dvh;
}
#editor-detail {
  min-height: 100vh;
}
#editor-detail {
  min-height: 100dvh;
}

#editor-detail:fullscreen {
  overflow-y: scroll;
}

nav {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media (min-width: @breakpoint-large) {
  #changes {
    height: 100vh;
    overflow-y: scroll;
  }
  #changes {
    height: 100dvh;
  }
  #editor-detail {
    height: 100vh;
    overflow-y: scroll;
  }
  #editor-detail {
    height: 100dvh;
  }
  html {
    overflow-y: hidden;
  }
}
</style>