<template>
  <EditableItem
    :terminal="true"
    :readonly="readonly"
    :hidden="true"
    :id="item.id"
    :name="name"
    placeholder="new field constraint"
    :editing="editing"
    :attributes="attributes"
    :error="error"
    v-on:edit="$emit('edit')"
    v-on:delete="$emit('delete')"
    v-on:center="$emit('center', item.data_type)">
    <template v-if="editing">
      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">path <span class="uk-text-meta"> {{ error.field('/name') }}</span></span>
          <select class="uk-form-controls uk-inline uk-select" v-model="name"
              v-on:change="$emit('update', {field: 'name', value: name})"
              v-bind:disabled="readonly">
            <option value="" v-if="!name">-- select --</option>
            <template v-for="(field, i) in structure.tree">
              <option :value="field.value" :key="i">{{ field.label }}</option>
            </template>
          </select>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Choose the scope where this named constraint applies
          </p>
        </label>
      </div>
      <div class="uk-margin" v-if="inline || (selected && selected.category === 'AggregateRoot')">
        <label>
          <input class="uk-checkbox" type="checkbox" name="inline" v-model="inline" v-on:change="doUpdate" v-bind:disabled="readonly">
          <span class="uk-margin-small-left uk-form-label" style="display: inline-block">
            inline
          </span>
          <p class="uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Inline fields receive the entire aggregate root, preserving entity information
          </p>
        </label>
      </div>
      <div class="uk-margin" v-if="set || (selected && !selected.cardinality)">
        <label>
          <input class="uk-checkbox" type="checkbox" name="set" v-model="set" v-on:change="doUpdate" v-bind:disabled="readonly">
          <span class="uk-margin-small-left uk-form-label" style="display: inline-block">set</span>
          <p class="uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            A set can only hold distinct values. Repeated values produce an error
          </p>
        </label>
        <fieldset class="uk-fieldset" v-if="set && (key || (selected && selected.children))">
          <label class="uk-flex uk-flex-column">
            <span class="uk-form-label">path</span>
            <select class="uk-form-controls uk-inline uk-select" v-model="key" v-on:change="doUpdate" v-bind:disabled="readonly">
              <option value="" v-if="!key" disabled>-- select --</option>
              <template v-if="selected">
                <option :value="field.value" v-for="(field, i) in selected.children" :key="i">{{ field.label }}</option>
              </template>
            </select>
            <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                  uk-margin-remove-bottom uk-animation-fade" v-if="help">
              Choose the field to use as the unique key of the object.
            </p>
          </label>
        </fieldset>
      </div>
      <div class="uk-margin" v-if="apply || prototypes.length">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">apply</span>
          <select class="uk-form-controls uk-inline uk-select" v-model="apply" v-on:change="doUpdate" v-bind:disabled="readonly">
            <option value="">-- disable --</option>
            <template v-for="prototype in prototypes">
              <option :value="prototype.id" :key="prototype.id">{{ prototype.values.name }}</option>
            </template>
          </select>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Prototypes can be applied to constrain object fields
          </p>
        </label>
      </div>
      <div class="uk-margin" v-if="expression || !selected">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">{{ $label('objects.expression') }} <span class="uk-text-meta"> {{ error.field('/value') }}</span></span>
          <textarea
            ref="expression"
            class="uk-form-controls uk-inline uk-textarea uk-width-expand uk-flex-auto"
            style="resize: vertical"
            rows="5"
            v-model="expression"
            :placeholder="$command('objects.expression', { label: 'field' })"
            v-on:change="doUpdate"
            v-bind:disabled="readonly">
          </textarea>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help('objects.expression') }}
          </p>
        </label>
      </div>
      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <div class="uk-form-label">description <span class="uk-text-meta"> {{ error.field('/description') }}</span></div>
          <textarea
            ref="description"
            class="uk-form-controls uk-inline uk-textarea uk-width-expand uk-flex-auto"
            style="resize: vertical"
            rows="5"
            v-model="description"
            :placeholder="$command('objects.description', { label: 'field_constraint' })"
            v-on:change="$emit('update', {field: 'description', value: description})"
            v-bind:disabled="readonly">
          </textarea>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            Describe the net effect of all constraints applied to this field, if any are required. This helps produce relevant error messages.
          </p>
        </label>
      </div>
    </template>
    <p v-else-if="description">{{ description }}</p>
    <p class="uk-text-italic uk-text-center" v-else>this field constraint has no description</p>
  </EditableItem>
</template>

<script>
import { mapState } from 'vuex';
import EditableItem from '@/components/canvas/EditableItem.vue';

const REGEX = /(UNSAFE)|(INLINE)|APPLY\s*\(\s*([a-z0-9_.]*)\s*\)|(SET)(?:\s*\(\s*([a-z0-9_.]*)\s*\))?|CHECK\s*\((.*)\)/g;

function assign(src, dst) {
  dst.attributes = src.attribute ? [src.attribute] : [];
  dst.name = src.item.name ?? '';
  dst.description = src.item.description ?? '';
  dst.selected = src.structure.tree.find(x => x.value === dst.name);
  if (dst.selected && dst.selected.children) {
    dst.prototypes = src.context.prototypes(src.parent.id, dst.selected.data_type);
  } else dst.prototypes = [];

  dst.expression = '';
  dst.set = false;
  dst.key = '';
  dst.inline = false;
  dst.apply = '';
  const value = src.item.value ?? '';
  REGEX.lastIndex = 0;
  let match = REGEX.exec(value)
  while (match) {
    if (match[1]) {
      dst.unsafe = true;
    }
    if (match[2]) {
      dst.inline = true;
    }
    if (match[3]) {
      dst.apply = src.context.get(match[3].replace('.', '/'))?.id ?? match[3];
    }
    if (match[5]) {
      dst.set = true;
      dst.key = match[5];
    } else if (match[4]) {
      dst.set = true;
    }
    if (match[6]) {
      dst.expression = match[6];
    }
    match = REGEX.exec(value);
  }
  dst.error = {
    invalid: src.errors.length,
    msg: src.errors.length ? 'invalid data' : null,
    local: [],
    fields: new Map(),
    field(src) {
      return this.fields.get(src) ||'';
    },
  }
  for (const {property, detail} of src.errors) {
    switch (property) {
      case '/name':
      case '/description':
        dst.error.fields.set(property, detail);
        break;
      default:
        dst.error.local.push(detail);
        break;
    }
  }
  return dst;
}

export default {
  name: 'FieldConstraint',
  props: {
    parent: Object,
    version: Number,
    item: Object,
    errors: Array,
    editing: Boolean,
    context: Object,
    categories: Object,
    attribute: String,
    structure: Object,
    readonly: Boolean
  },
  components: {
    EditableItem,
  },
  data() {
    return assign(this, {});
  },
  mounted() {
  },
  computed: {
    ...mapState('api', ['help']),
  },
  watch: {
    version() {
      assign(this, this);
    },
    editing() {
      this.selected = this.structure.tree.find(x => x.value === this.name);
      if (this.selected && this.selected.children) {
        this.prototypes = this.context.prototypes(this.parent.id, this.selected.data_type);
      } else this.prototypes = [];
    },
  },
  methods: {
    doUpdate() {
      const parts = [];
      if (this.unsafe) parts.push('UNSAFE');
      if (this.inline) parts.push('INLINE');
      if (this.apply) parts.push(`APPLY(${this.context.fqp(this.apply)})`);
      if (this.expression) parts.push(`CHECK(${this.expression})`);
      if (this.set && this.key) parts.push(`SET(${this.key})`);
      else if (this.set) parts.push('SET');
      this.$emit('update', {field: 'value', value: parts.join(' ')})
    }
  }
}
</script>