<template>
  <div class="uk-flex uk-flex-middle">
    <ul class="uk-list uk-text-muted uk-margin-remove uk-flex-auto">
      <li class="" v-for="(msg, index) in error && error.local" :key="index">
        <i uk-icon="warning"></i>
        {{ msg }}
      </li>
      <slot></slot>
      <li v-for="section in sections" :key="section.category">{{ $message(
        'objects.count', {
          count: categories[section.category].length,
          singleton: $label(`objects.singleton.${section.label}`),
          plural: $label(`objects.plural.${section.label}`)
        }) }}</li>
    </ul>
    <hr class="uk-divider-vertical uk-margin-remove-top uk-margin-remove-bottom uk-margin-small-right uk-margin-small-left uk-flex-none"/>
    <button class="uk-button uk-button-link uk-flex-none" v-on:click="$emit('focus')" :disabled="disabled" :class="{'uk-disabled': disabled}">
      <i uk-icon="icon: cog"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Settings',
  props: {
    error: Object,
    sections: Array,
    categories: Object,
    disabled: Boolean,
  },
}
</script>
