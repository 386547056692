<template>
    <section>
        <ul class="uk-list uk-margin-remove-top">
            <li v-for="(change, i) in entries" :key="i" class="uk-flex uk-flex-middle">
                <i uk-icon="icon: check; ratio: 1.5" class="uk-margin-small-right uk-flex-none" v-if="change.complete"></i>
                <i uk-icon="icon: warning; ratio: 1.5" class="uk-margin-small-right uk-flex-none" v-else-if="change.error"></i>
                <span class="uk-margin-small-right uk-flex-none" uk-spinner="ratio: 1" v-else-if="active === change"></span>
                <article class="uk-comment">
                    <header class="uk-comment-header uk-grid-medium uk-flex-middle uk-margin-remove-bottom" uk-grid>
                        <div class="uk-width-expand">
                            <h4 class="uk-comment-title uk-margin-remove">{{ change.title }} <span class="uk-text-meta">{{ change.subtitle }}</span></h4>
                            <ul class="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
                                <li><a>Model</a></li>
                            </ul>
                        </div>
                    </header>
                    <div class="uk-comment-body" v-if="change.error">
                    <p>{{ change.error }}</p>
                    </div>
                </article>
            </li>
        </ul>
        <form class="uk-flex uk-flex-right uk-flex-row-reverse uk-flex-middle" @submit.prevent="apply">
            <button class="uk-button uk-button-primary uk-border-rounded uk-flex-none" v-bind:disabled="!conflict && (active || error || pending)">
                {{ conflict ? 'merge' : 'apply' }}
            </button>
            <div uk-spinner v-if="(active || pending) && !error" class="uk-margin-small-right uk-flex-none"></div>
            <template v-if="error">
            <span class="uk-text-muted uk-text-right uk-margin-small-right uk-margin-small-left">
                {{ error }}
            </span>
            <i class="uk-icon uk-flex-none" uk-icon="icon: warning"></i>
            </template>
            <span class="uk-flex-auto"></span>
            <div class="uk-flex-none">
                <button class="uk-button uk-button-text uk-border-rounded" type="button" @click="$emit('cancel')">
                    model
                </button>
            </div>
        </form>
    </section>
</template>
<script>
import ConsumerService from '@/sdk/consumer.js'

function compile(context, changes, entries, service) {
    let error = false;
    for (const change of changes) {
        error |= !!change.error;
        switch (change.type) {
            case "rename":
                {
                    const obj = context.get(change.obj);
                    const layer = context.layers.get(obj.id);
                    entries.push({
                        title: `move ${context.label(obj.id)}`,
                        subtitle: `${obj.fragment} → ${change.fragment}`,
                        complete: false,
                        error: service.hasMove ? change.error : 'Moving pages is currently disabled for the wiki',
                        change,
                        obj,
                        layer,
                        async action() {
                            this.obj.fragment = this.change.fragment;
                            const version = this.layer.version;
                            this.layer.upstream = await service.asMoveUri().postAsync(this.layer.upstream.docid, this.change.fragment);
                            this.layer.flushed = version;
                        },
                    });
                }
                break;
            case "create": {
                const layer = context.layers.get(change.layer);
                entries.push({
                    title: 'create page',
                    subtitle: change.fragment,
                    complete: false,
                    error: service.hasCreate ? change.error : 'Creating pages is currently disabled for the wiki',
                    change,
                    obj: null,
                    layer,
                    async action() {
                        const version = this.layer.version;
                        this.layer.upstream = await service.asCreateUri().postAsync(this.change.fragment, [...context.flush(this.layer.id)]);
                        this.layer.flushed = version;
                    },
                });
                break;
            }
            case "edit":
                {
                    const layer = context.layers.get(change.layer);
                    entries.push({
                        title: change.fragment ? `edit page ${change.fragment}` : 'edit page',
                        subtitle: layer.upstream.title,
                        complete: false,
                        error: service.hasEdit ? change.error : 'Editing pages is currently disabled for the wiki',
                        change,
                        obj: null,
                        layer,
                        async action() {
                            const version = this.layer.version;
                            this.layer.upstream = await service.asEditUri().postAsync(this.layer.upstream.docid, this.layer.upstream.version, [...context.flush(this.layer.id)]);
                            this.layer.flushed = version;
                        },
                    });
                }
                break;
            default:
                error = true;
                entries.push({
                    title: "unknown command",
                    subtitle: change.type,
                    complete: false,
                    error: "Please contact support",
                    obj: null,
                    layer: null,
                    action() {
                        throw this.error;
                    },
                });
                break;
        }
    }
    return error;
}

function* merge(schema, upstream, downstream) {
    for (const attr of schema.attributes) {
        if (attr.category) {
          yield automerge({
            label: attr.label,
            category: attr.category,
            attribute: attr,
            section: null,
            fragment: null,
            local: downstream[attr.category] ? [ downstream[attr.category] ] : [],
            remote: upstream.has(attr.category) ? [ upstream.get(attr.category)[0] ] : [],
            matched: downstream[attr.category] ? new Map([[downstream[attr.category], 0] ]) : new Map(),
            pairs: [{
                local: downstream[attr.category] ? 0 : null,
                remote: upstream.has(attr.category) ? 0 : null,
                action: null,
                values: [],
            }],
            status: null,
          });
          upstream.delete(attr.category);
        }
        if (attr.settings) yield* merge(attr.settings, upstream, downstream);
    }

    for (const section of schema.sections) {
        const local = downstream[section.category];
        const remote = upstream.get(section.category) ?? [];
        const digests = new Map(remote.map(({digest}, i) => [digest, i]));
        const matched = new Map();
        // TODO: Include fragment and pageid in matching algorithm
        for (const obj of local) {
          if (obj.upstream && digests.has(obj.upstream.digest)) {
            matched.set(obj.id, digests.get(obj.upstream.digest));
            digests.delete(obj.upstream.digest);
          }
        }

        // Order-preserving match that flushes unknown upstream values whenever an unmatched object is found
        const pairs = [];
        const seen = new Set(matched.values());
        let expected = 0;
        for (let i = 0; i < local.length; i++) {
            const obj = local[i];
            if (matched.has(obj.id)) {
                pairs.push({local: i, remote: matched.get(obj.id), action: null, values: []})
            } else {
                if (obj.upstream) {
                    while (seen.has(expected)) expected++;
                    for (; expected < remote.length && !seen.has(expected); expected++) {
                        pairs.push({local: null, remote: expected, action: null, values: []});
                    }
                }
                pairs.push({local: i, remote: null, action: null, values: []})
            }
        }
        while (seen.has(expected)) expected++;
        for (; expected < remote.length; expected++) {
            if (!seen.has(expected)) pairs.push({local: null, remote: expected, action: null, values: []});
        }
        yield automerge({
            label: section.label,
            category: section.category,
            attribute: null,
            section,
            local,
            remote,
            matched,
            pairs,
            status: null,
        });
        if (section.settings) yield* merge(section.settings, upstream, downstream);
      }
    if (schema.settings) {
      yield* merge(schema.settings, upstream, downstream);
    }
}

function automerge(step) {
    step.status = 'automerge';
    for (const pair of step.pairs) {
        const keys = new Set();
        const remote = new Map();
        const local = new Map();
        if (pair.remote != null) {
            // Selecting remote means prioritizing remote values if they are different from the originally read values
            for (const { name, value } of step.remote[pair.remote].properties) {
                keys.add(name);
                remote.set(name, value);
                local.set(name, value);
            }
        }

        if (pair.local != null && step.local[pair.local].values) {
            const obj = step.local[pair.local];
            // Ensure all deleted values have been populated
            if (obj.upstream) {
                for (const { name } of obj.upstream.properties) {
                    keys.add(name);
                    local.set(name, obj.values[name] ?? null);
                }
            }
            for (const name in obj.values) {
                keys.add(name);
                local.set(name, obj.values[name]);
            }
        } else local.clear();

        let matched = true;
        for (const key of [...keys].sort()) {
            const value = {
                key,
                remote: remote.get(key) ?? null,
                local: local.get(key) ?? null,
            };
            matched &= value.remote === value.local;
            pair.values.push(value)
        }
        if (matched) pair.action = 'automerge';
        else step.status = null;
    }
    return step;
}

export default {
    name: 'Changelog',
    props: {
        consumer: ConsumerService,
        context: Object,
        changes: Array,
    },
    components: {
    },
    data() {
        const entries = [];
        return {
            active: null,
            error: compile(this.context, this.changes, entries, this.consumer) ? 'please check your changes' : null,
            entries,
            conflict: false,
            pending: null,
        };
    },
    mounted() {
    },
    computed: {
    },
    watch: {
        changes() {
            const entries = [];
            this.active = null;
            this.error = compile(this.context, this.changes, entries, this.consumer) ? 'please check your changes' : null,
            this.entries = entries;
        },
    },
    methods: {
        async apply() {
            if (this.conflict) {
                this.error = null;
                const layer = this.context.layers.get(this.active.change.layer);
                const result = await this.consumer.asParseUri().withParams(layer.upstream.docid).getAsync();
                const page = result.rows[0];
                const upstream = new Map();
                if (page) {
                    for (const chunk of page.chunks) {
                        if (upstream.has(chunk.category)) {
                            upstream.get(chunk.category).push(chunk);
                        } else {
                            upstream.set(chunk.category, [ chunk ]);
                        }
                    }
                }
                const steps = [];
                let active = null;
                for (const step of merge(layer.schema, upstream, layer.categories)) {
                    steps.push(step);
                    if (!active && !step.status) active = step;
                }
                this.$emit('merge', {
                    title: layer.id ? this.context.label(layer.id) : layer.upstream.title,
                    subtitle: 'merge',
                    layer,
                    active,
                    steps,
                    upstream: page,
                });
                this.conflict = false;
                return;
            } else if (this.active) {
                return;
            }

            try {
                for (const entry of this.entries) {
                    if (!entry.complete) {
                        this.active = entry;
                        this.pending = entry.action();
                        await this.pending;
                        entry.complete = true;
                    }
                }
                if (this.consumer.hasCompile) {
                    this.pending = this.consumer.asCompileUri().postAsync();
                    const result = await this.pending;
                    this.pending = null;
                    this.$emit('success', result)
                } else {
                    this.pending = null;
                    this.$emit('success')
                }
            } catch (err) {
                console.warn(err)
                this.error = err.toString();
                this.conflict = err?.status === 409;
                this.pending = null;
            }
        },
    },
};
</script>
