<template>
  <EditableItem
    :terminal="true"
    :readonly="readonly"
    :hidden="true"
    :id="item.id"
    :name="context.label(service)"
    placeholder="new navigation"
    :editing="editing"
    :attributes="[]"
    :error="error"
    v-on:edit="$emit('edit')"
    v-on:delete="$emit('delete')"
    v-on:center="$emit('center', item.data_type)">
    <template v-if="editing">
      <div class="uk-margin-small">
        <label>
          <input
            class="uk-checkbox"
            type="checkbox"
            v-model="explicit"
            v-on:change="$emit('update', {field: 'explicit', value: explicit ? '1' : null})"
            v-bind:disabled="readonly">
          <span class="uk-margin-small-left uk-form-label" style="display: inline-block">{{ $label('objects.explicit') }} <span class="uk-text-meta"> {{ error.field('/explicit') }}</span></span>
        </label>
        <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          {{ $help('objects.explicit') }}
        </p>
      </div>
      <label class="uk-flex uk-flex-column">
        <div class="uk-form-label">service <span class="uk-text-meta"> {{ error.field('/service') }}</span></div>
        <div class="uk-form-controls">
          <ObjectAttribute :context="context" label="target" :value="service" :required="true" :readonly="readonly"></ObjectAttribute>
        </div>
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          Select the related service
        </p>
      </label>
      <Settings v-if="schema && item"
        :sections="schema.sections"
        :categories="categories"
        @focus="$emit('settings', { context: parent.id, fields: service, tree: context.output(parent.id) })">
        <li>data sourced from {{ src || 'top level' }}</li>
        <li v-if="item.explicit">explicit mapping</li>
      </Settings>
    </template>
    <p class="uk-text-center" v-else-if="service">data sourced from {{ src || 'top level' }}</p>
    <p class="uk-text-italic uk-text-center" v-else>no target selected</p>
  </EditableItem>
</template>

<script>
import { mapState } from 'vuex';
import EditableItem from '@/components/canvas/EditableItem.vue';
import ObjectAttribute from './ObjectAttribute.vue';
import Settings from './Settings.vue'

function assign(src, dst) {
  dst.service = src.item.dst || '';
  dst.explicit = src.item.explicit == '1';
  dst.src = src.item.src || '';
  dst.error = {
    invalid: src.errors.length,
    msg: src.errors.length ? 'invalid data' : null,
    local: [],
    fields: new Map(),
    field(src) {
      return this.fields.get(src) ||'';
    },
  }
  for (const {property, detail} of src.errors) {
    switch (property) {
      case '/explicit':
      case '/service':
        dst.error.fields.set(property, detail);
        break;
      default:
        dst.error.local.push(detail);
        break;
    }
  }
  return dst;
}

export default {
  name: 'Navigation',
  props: {
    parent: Object,
    version: Number,
    item: Object,
    errors: Array,
    editing: Boolean,
    context: Object,
    categories: Object,
    attribute: String,
    schema: Object,
    readonly: Boolean,
  },
  components: {
    EditableItem,
    ObjectAttribute,
    Settings,
  },
  data() {
    return assign(this, {});
  },
  mounted() {
  },
  computed: {
    ...mapState('api', ['help']),
  },
  watch: {
    version() {
      assign(this, this);
    },
    validate() {
    },
  }
}
</script>