<template>
  <ul class="uk-navbar-nav">
    <li>
      <h2 class="uk-margin-remove-bottom" :class="{'uk-light': inverse}">
        <i uk-icon="list"></i>
        {{ title }}
        <span class="uk-text-meta" v-if="subtitle">{{ subtitle }}</span>
      </h2>
      <div class="uk-navbar-dropdown uk-width-medium">
        <ul class="uk-nav uk-navbar-dropdown-nav">
          <li class="uk-nav-header">Layer</li>
          <li class="uk-parent" :class="{'uk-active': active === selector}" v-if="selector">
            <a href="#" v-on:click.prevent="$emit('focus', selector)"><i :uk-icon="selector.icon" v-if="selector.icon"></i> {{ selector.layer.title }}</a>
            <ul class="uk-nav-sub" v-if="selector.child">
              <NavHeaderItem
                :selector="selector.child"
                :active="active"
                v-on:focus="$emit('focus', $event)">
              </NavHeaderItem>
            </ul>
          </li>
          <li class="uk-nav-divider"></li>
          <li v-for="(link, idx) in links" :key="idx"><router-link :to="link.to"><i :uk-icon="link.icon"></i> {{ link.label }}</router-link></li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
import NavHeaderItem from '@/components/canvas/NavHeaderItem.vue';

export default {
  name: 'NavHeader',
  props: {
    title: String,
    subtitle: String,
    active: Object,
    selector: Object,
    inverse: Boolean,
    links: Array,
  },
  components: {
    NavHeaderItem,
  }
};
</script>