<template>
  <article class="uk-comment">
    <header class="uk-comment-header uk-grid-medium uk-flex-middle uk-margin-small-bottom" uk-grid>
        <div class="uk-width-expand">
            <h4 class="uk-comment-title uk-margin-remove uk-text-truncate" v-if="name">
              <i uk-icon="warning" v-if="error && error.invalid"></i>
              {{ name }}
              <span class="uk-text-meta" v-if="error && error.msg"> {{ error.msg }}</span>
            </h4>
            <h4 class="uk-comment-title uk-margin-remove uk-text-truncate uk-text-italic" v-else>
              <i uk-icon="warning" v-if="error && error.invalid"></i>
              {{ placeholder }}
              <span class="uk-text-meta" v-if="error && error.msg"> {{ error.msg }}</span>
            </h4>
            <ul class="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
              <li>
                <button data-edit class="uk-button uk-button-link" v-on:click="$emit('edit')">
                  {{ readonly ? $label('controls.more') : $label('controls.edit') }}
                </button>
              </li>
              <li v-if="!terminal">
                <button class="uk-button uk-button-link" v-on:click="$emit('focus')">
                  {{ $label('controls.details') }}
                </button>
              </li>
              <li v-if="!readonly && linking">
                <button class="uk-button uk-button-link" v-on:click="$emit('unlink')">
                  {{ $label('controls.unlink') }}
                </button>
              </li>
              <li v-else-if="!readonly && !permanent">
                <button class="uk-button uk-button-link uk-text-muted" v-on:click="$emit('delete')">
                  <i uk-icon="icon: trash"></i>
                </button>
              </li>
              <li v-if="!hidden">
                <button class="uk-button uk-button-link" v-on:click="$emit('center')">
                  <i uk-icon="icon: location"></i>
                </button>
              </li>
              <li v-for="attribute in attributes" :key="attribute">
                {{ attribute }}
              </li>
            </ul>
            <slot name="header"></slot>
        </div>
    </header>
    <div class="uk-comment-body">
      <div class="uk-light uk-form uk-form-stacked uk-animation-fade uk-animation-fast" v-if="editing">
        <ul class="uk-list" v-if="error && error.local.length">
          <li class="uk-text-meta uk-text-right" v-for="(msg, index) in error.local" :key="index">{{ msg }} <i uk-icon="warning"></i></li>
        </ul>
        <slot></slot>
      </div>
      <slot v-else></slot>
    </div>
  </article>
</template>

<script>
export default {
  name: 'EditableItem',
  props: {
    terminal: Boolean,
    readonly: Boolean,
    hidden: Boolean,
    editing: Boolean,
    linking: Boolean,
    name: String,
    placeholder: String,
    attributes: Array,
    error: Object,
    permanent: Boolean,
    constraints: Object,
  },
  components: {
  }
}
</script>