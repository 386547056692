<template>
  <EditableItem
    :terminal="true"
    :hidden="true"
    :readonly="readonly"
    :name="item.name"
    :placeholder="$label('objects.unnamed', { label: $label(`objects.${label}`) })"
    :editing="editing"
    :attributes="attributes"
    :error="error"
    v-on:focus="$emit('focus')"
    v-on:edit="$emit('edit')"
    v-on:delete="$emit('delete')"
    v-on:center="$emit('center')">
    <template v-if="editing">
      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">{{ $label('objects.name') }} <span class="uk-text-meta"> {{ error.field('/name') }}</span></span>
          <input
            ref="name"
            class="uk-form-controls uk-inline uk-input"
            type="text"
            v-model="name"
            pattern="[a-z](?:_?[a-z0-9]+)*"
            :placeholder="$command('objects.name', { label: $label(`objects.${label}`) })"
            v-on:change="$emit('update', {field: 'name', value: name.trim().replace(/[\s-()+\.]/g, '_').replace(/__+/g, '_').toLowerCase()})"
            spellcheck="false"
            autocorrect="off"
            autocapitalize="off"
            autocomplete="off"
            v-bind:disabled="readonly">
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help('objects.name') }}
          </p>
        </label>
      </div>

      <div class="uk-margin">
        <label>
          <input
            class="uk-checkbox"
            type="checkbox"
            v-model="general_access"
            v-on:change="$emit('update', {field: 'metadata', value: metadata})"
            v-bind:disabled="readonly">
          <span class="uk-margin-small-left uk-form-label" style="display: inline-block">{{ $label('objects.general_access') }}</span>
        </label>
        <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          {{ $help('objects.general_access') }}
        </p>
      </div>

      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">{{ $label('objects.role') }} <span class="uk-text-meta"> {{ error.field('/role') }}</span></span>
          <input
            class="uk-form-controls uk-inline uk-input"
            type="text"
            v-model="role"
            :placeholder="$command('objects.role')"
            v-on:change="$emit('update', {field: 'role', value: role})"
            autocomplete="off"
            v-bind:disabled="readonly">
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help('objects.role') }}
          </p>
        </label>
      </div>
      <div class="uk-margin uk-flex uk-flex-column uk-text-right">
        <label>
          <span class="uk-margin-small-right uk-form-label" style="display: inline-block">read</span>
          <input class="uk-checkbox" type="checkbox"
            v-model="read"
            v-on:change="$emit('update', {field: 'permissions', value: permissions})"
            v-bind:disabled="readonly">
          <p class="uk-margin-remove uk-text-right uk-text-meta uk-animation-fade" v-if="help">
            Read access is required
          </p>
        </label>
        <label>
          <span class="uk-margin-small-right uk-form-label" style="display: inline-block">write</span>
          <input class="uk-checkbox" type="checkbox"
            v-model="write"
            v-on:change="$emit('update', {field: 'permissions', value: permissions})"
            v-bind:disabled="readonly">
          <p class="uk-margin-remove uk-text-right uk-text-meta uk-animation-fade" v-if="help">
            Write access is required
          </p>
        </label>
        <label>
          <span class="uk-margin-small-right uk-form-label" style="display: inline-block">execute</span>
          <input class="uk-checkbox" type="checkbox"
            v-model="execute"
            v-on:change="$emit('update', {field: 'permissions', value: permissions})"
            v-bind:disabled="readonly">
          <p class="uk-margin-remove uk-text-right uk-text-meta uk-animation-fade" v-if="help">
            Execute access is required
          </p>
        </label>
      </div>

      <div class="uk-margin">
        <label class="uk-flex uk-flex-column">
          <span class="uk-form-label">{{ $label('objects.description') }} <span class="uk-text-meta"> {{ error.field('/description') }}</span></span>
          <textarea
            ref="description"
            class="uk-form-controls uk-inline uk-textarea uk-width-expand uk-flex-auto"
            style="resize: vertical"
            rows="5"
            v-model="description"
            :placeholder="$command('objects.description', { label: $label(`objects.${label}`) })"
            v-on:change="$emit('update', {field: 'description', value: description})"
            v-bind:disabled="readonly">
          </textarea>
          <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
                uk-margin-remove-bottom uk-animation-fade" v-if="help">
            {{ $help(`objects.policy_groups`) }}
          </p>
        </label>
      </div>

      <div class="uk-margin">
        <label class="uk-form-label">{{ $label(`objects.requirements.${label}`) }}</label>
        <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          {{ $help(`objects.requirements.${label}`) }}
        </p>
        <div class="uk-form-controls ">
          <ul class="uk-list" v-if="dependencies.length">
            <li v-for="dependency in dependencies" :key="dependency.id">
              <EditableItem
                  :linking="true"
                  :terminal="true"
                  :editing="false"
                  :readonly="readonly"
                  :name="dependency.values.name"
                  :placeholder="$label('objects.unnamed', { label: $label(`objects.${dependency.section.label}`) })"
                  :attributes="dependency.attributes"
                  v-on:focus="$emit('focus', { id: dependency.id })"
                  v-on:edit="$emit('edit', { id: dependency.id })"
                  v-on:delete="$emit('delete', { id: dependency.id })"
                  v-on:center="$emit('center', { id: dependency.id })"
                  v-on:unlinked="$emit('unlinked', { id: dependency.id })">
                <p v-if="dependency.values.description">{{ dependency.values.description }}</p>
                <p class="uk-text-italic uk-text-center" v-else>
                  {{ $message('objects.missingattr', { label: $label(`objects.${dependency.section.label}`), attr: $label('objects.description') }) }}
                </p>
              </EditableItem>
            </li>
          </ul>
          <div class="uk-text-italic uk-text-center" v-else>
            {{ $message('objects.missingattr', { label: $label(`objects.${label}`), attr: $label(`objects.requirements.${label}`) }) }}
          </div>
        </div>
      </div>
    </template>
    <p v-else-if="description">{{ description }}</p>
    <p class="uk-text-italic uk-text-center" v-else>
      {{ $message('objects.missingattr', { label: $label(`objects.${label}`), attr: $label('objects.description') }) }}
    </p>
  </EditableItem>
</template>

<script>
import { mapState } from 'vuex';
import EditableItem from '@/components/canvas/EditableItem.vue';

function assign(src, dst) {
  const permissions = src.item.permissions ?? '';
  const dependencies = [];
  src.references.forEach((value, key) => {
    if (value) dependencies.push(src.context.get(key) ?? {
      id: key,
      values: {
        name: key.toString(),
        description: null,
      },
      label: 'object',
    });
  });
  dependencies.sort((a, b) => a.name.localeCompare(b.name));
  dst.role = src.item.role ?? '';
  dst.flags = new Set((src.item.metadata ?? '').split(',').map(x => x.trim()));
  dst.general_access = dst.flags.has('ga');
  dst.attributes = src.attribute ? [ src.attribute ] : [];
  dst.name = src.item.name ?? '';
  dst.description = src.item.description ?? '';
  dst.read = permissions.includes('r');
  dst.write = permissions.includes('w');
  dst.execute = permissions.includes('x');
  dst.dependencies = dependencies;
  dst.error = {
    invalid: src.errors.length,
    msg: src.errors.length ? 'invalid data' : null,
    local: [],
    fields: new Map(),
    field(src) {
      return this.fields.get(src) ||'';
    },
  }
  for (const {property, detail} of src.errors) {
    switch (property) {
      case '/name':
      case '/role':
      case '/description':
        dst.error.fields.set(property, detail);
        break;
      default:
        dst.error.local.push(detail);
        break;
    }
  }
  return dst;
}

export default {
  name: 'PolicyGroup',
  props: {
    version: Number,
    item: Object,
    category: String,
    label: String,
    errors: Array,
    editing: Boolean,
    context: Object,
    categories: Object,
    attribute: String,
    references: Map,
    readonly: Boolean,
  },
  components: {
    EditableItem
  },
  data() {
    return assign(this, {});
  },
  mounted() {
  },
  beforeDestroy() {
  },
  watch: {
    version() {
      assign(this, this);
    },
  },
  computed: {
    metadata() {
      var combined = new Set(this.flags);
      if (this.general_access) combined.add('ga');
      else combined.delete('ga');
      return [...combined].sort().join(' ');
    },
    permissions() {
      return `${this.read ? 'r' : '-'}${this.write ? 'w' : '-'}${this.execute ? 'x' : '-'}`;
    },
    ...mapState('api', ['help']),
  },
  methods: {
  },
}
</script>
