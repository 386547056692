<template>
  <EditableItem
    :terminal="true"
    :hidden="true"
    :readonly="readonly"
    :name="item.name"
    :placeholder="$label('objects.unnamed', { label: 'lookup' })"
    :editing="editing"
    :attributes="attributes"
    :error="error"
    v-on:focus="$emit('focus')"
    v-on:edit="$emit('edit')"
    v-on:delete="$emit('delete')"
    v-on:center="$emit('center')">
    <template v-if="editing">
      <label class="uk-flex uk-flex-column uk-margin-bottom">
        <span>{{ $label('objects.name') }} <span class="uk-text-meta"> {{ error.field('/name') }}</span></span>
        <input
          ref="name"
          class="uk-form-controls uk-inline uk-input"
          type="text"
          v-model="name"
          pattern="[a-z](?:_?[a-z0-9]+)*"
          :placeholder="$command('objects.name', { label: 'index' })"
          autofocus
          v-on:change="$emit('update', {field: 'name', value: name.trim().replace(/[\s-()+\.]/g, '_').replace(/__+/g, '_').toLowerCase()})"
          spellcheck="false"
          autocorrect="off"
          autocapitalize="off"
          autocomplete="off"
          v-bind:disabled="readonly">
        <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          {{ $help('objects.name') }}
        </p>
      </label>
      <label>
        <input class="uk-checkbox" type="checkbox" name="optional" v-model="unique" v-on:change="$emit('update', {field: 'unique', value: unique ? '1' : null})" v-bind:disabled="readonly">
        <span class="uk-margin-small-left uk-form-label" style="display: inline-block">
          unique
          <span class="uk-text-meta"> {{ error.field('/unique') }}</span>
        </span>
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          The fields must be unique, creating a business key for the aggregate root
        </p>
      </label>
      <fieldset class="uk-fieldset">
        <span class="uk-form-label">fields <span class="uk-text-meta"> {{ error.field('/fields') }}</span></span>
        <ul class="uk-list uk-text-right">
          <li v-for="(field, index) in fields" :key="index">{{ field }}</li>
          <li class="uk-text-italic">add or remove fields on canvas...</li>
        </ul>
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          Select the included fields - this order is significant
        </p>
      </fieldset>
      <label class="uk-flex uk-flex-column">
        <span>{{ $label('objects.description') }} <span class="uk-text-meta"> {{ error.field('/description') }}</span></span>
        <textarea
          ref="description"
          class="uk-form-controls uk-inline uk-textarea uk-width-expand uk-flex-auto"
          style="resize: vertical"
          rows="5"
          v-model="description"
          :placeholder="$command('objects.description', { label: 'index' })"
          v-on:change="$emit('update', {field: 'description', value: description})"
          v-bind:disabled="readonly">
        </textarea>
        <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          {{ $help('objects.indexes') }}
        </p>
      </label>
    </template>
    <p v-else-if="description">{{ description }}</p>
    <p class="uk-text-italic uk-text-center" v-else>
      {{ $message('objects.missingattr', { label: 'index', attr: $label('objects.description') }) }}
    </p>
  </EditableItem>
</template>

<script>
import { mapState } from 'vuex';
import EditableItem from '@/components/canvas/EditableItem.vue';

function assign(src, dst) {
  dst.name = src.item.name || '';
  dst.unique = !!src.item.unique;
  dst.fields = (src.item.fields ?? '').split(',').map(x => x.trim()).filter(x => x);
  dst.description = src.item.description || '';
  dst.error = {
    invalid: src.errors.length,
    msg: src.errors.length ? 'invalid data' : null,
    local: [],
    fields: new Map(),
    field(src) {
      return this.fields.get(src) ||'';
    },
  }
  for (const {property, detail} of src.errors) {
    switch (property) {
      case '/name':
      case '/unique':
      case '/fields':
      case '/description':
        dst.error.fields.set(property, detail);
        break;
      default:
        dst.error.local.push(detail);
        break;
    }
  }
  return dst;
}

export default {
  name: 'Index',
  props: {
    version: Number,
    item: Object,
    category: String,
    label: String,
    errors: Array,
    editing: Boolean,
    context: Object,
    categories: Object,
    attribute: String,
    references: Map,
    readonly: Boolean
  },
  components: {
    EditableItem
  },
  data() {
    return assign(this, {});
  },
  watch: {
    version() {
      assign(this, this);
    },
  },
  computed: {
    attributes() {
      return this.unique ? [ 'unique' ] : [];
    },
    ...mapState('api', ['help']),
  },
}
</script>