<template>
  <ul class="uk-navbar-nav">
    <li>
      <h2 class="uk-margin-remove-bottom" :class="{'uk-light': inverse}">
        <i uk-icon="list"></i>
        {{ title }}
        <span class="uk-text-meta" v-if="subtitle">{{ subtitle }}</span>
      </h2>
      <div class="uk-navbar-dropdown uk-width-medium">
        <ul class="uk-nav uk-navbar-dropdown-nav">
          <li class="uk-nav-header">Merge</li>
          <li><a href="#" v-on:click.prevent="$emit('cancel')"><i uk-icon="ban"></i> Cancel </a></li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MergeHeader',
  props: {
    title: String,
    subtitle: String,
    inverse: Boolean,
  },
  components: {
  }
};
</script>