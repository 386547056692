<template>
  <ul class="uk-list uk-margin-small-bottom uk-margin-small-top">
    <li v-for="(option, index) in options" :key="index">
      <label >
        <input class="uk-checkbox uk-margin-small-right"
          type="checkbox"
          :value="option[1]"
          v-model="selected"
          v-on:change="$emit('update', {field: 'policy_groups', value: selected.join(' ')})"
          :readonly="readonly"> {{ option[0] }}
      </label>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Access',
  props: {
    version: Number,
    item: Object,
    options: Array,
    readonly: Boolean,
  },
  data() {
    return {
      selected: (this.item.policy_groups ?? '').split(' ').map(x => x.trim()).filter(x => x),
    }
  },
  watch: {
    version() {
      this.selected = (this.item.policy_groups ?? '').split(' ').map(x => x.trim()).filter(x => x);
    }
  }
}
</script>