<template>
  <div>
    <select class="uk-form-controls uk-inline uk-select" v-model="source" @change="$emit('update', source || null)">
      <option value="">/</option>
      <template v-for="(field, i) in structure.tree">
      <option :value="field.value"  :key="i" v-if="field.children">{{ field.label }}</option>
      </template>
    </select>
    <p class="uk-margin-left uk-margin-small-top uk-text-right uk-text-meta
          uk-margin-remove-bottom uk-animation-fade" v-if="help">
      Choose the position in the result object where this navigation applies
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

function assign(src, dst) {
  dst.source = src.item.src ?? '';
  return dst;
}

export default {
  name: 'MappingSource',
  props: {
    parent: Object,
    version: Number,
    item: Object,
    error: Object,
    editing: Boolean,
    context: Object,
    categories: Object,
    attribute: String,
    structure: Object,
  },
  components: {
  },
  data() {
    return assign(this, {});
  },
  mounted() {
  },
  computed: {
    ...mapState('api', ['help']),
  },
  watch: {
    version() {
      assign(this, this);
    },
  },
  methods: {
  }
}
</script>