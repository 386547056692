<template>

  <div class="merge" v-if="active">
    <div class="uk-container uk-container-small">
      <h2 class="uk-margin-top">{{ $label(`title.${active.label}`) }}</h2>
      <ul class="uk-list">
        <template v-for="(step, i) in active.pairs">
        <li :key="i" v-if="step.action != 'automerge'">
          <MergeItem :action="step.action" :local="step.local" :remote="step.remote" :values="step.values" @change="doUpdate(step, $event)"></MergeItem>
        </li>
        </template>
      </ul>
    </div>
  </div>
  <div class="uk-flex uk-flex-column uk-flex-center merge" v-else>
    <div class="uk-flex uk-flex-center uk-flex-middle uk-flex-auto">
      <div class="uk-text-right">
        <div class="uk-text-lead uk-text-muted">merging changes</div>
        <div class="uk-text-meta">{{ layer.upstream.title }}</div>
      </div>
      <span class="uk-margin-left uk-text-muted" uk-spinner="ratio: 2"></span>
    </div>
  </div>
</template>

<script>
import MergeItem from './MergeItem.vue';

export default {
  name: 'MergeDetail',
  props: {
    context: Object,
    layer: Object,
    active: Object,
  },
  components: {
    MergeItem
  },
  data() {
    return {
    }
  },
  methods: {
    doUpdate(step, action) {
      step.action = action;
      for (const step of this.active.pairs) {
        if (!step.action) return;
      }
      this.active.status = 'manual merge';
    }
  }
}
</script>

<style lang="less">
@import "../../../assets/less/theme.less";

.merge {
    background-color: @global-muted-background;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
}
merge {
    height: 100dvh;
}
</style>