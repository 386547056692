<template>
  <EditableItem
    :terminal="true"
    :readonly="readonly"
    :hidden="true"
    :id="item.id"
    :name="context.label(service)"
    placeholder="new dependency"
    :editing="editing"
    :attributes="[]"
    :error="error"
    v-on:edit="$emit('edit')"
    v-on:delete="$emit('delete')"
    v-on:center="$emit('center', item.data_type)">
    <template v-if="editing">
      <label class="uk-flex uk-flex-column">
        <div class="uk-form-label">service <span class="uk-text-meta"> {{ error.field('/service') }}</span></div>
        <div class="uk-form-controls">
          <ObjectAttribute :version="version"
            :context="context"
            :label="$label('objects.service')"
            :primitive="false"
            :required="true"
            :value="service"
            :readonly="readonly"
            v-on:update="$emit('update', {field: 'service', value: $event})">
          </ObjectAttribute>
        </div>
        <p class="uk-margin-small-top uk-text-right uk-text-meta
              uk-margin-remove-bottom uk-animation-fade" v-if="help">
          Select the required service
        </p>
      </label>
      <div class="uk-flex uk-flex-column uk-text-right">
        <label>
          <span class="uk-margin-small-right uk-form-label" style="display: inline-block">read</span>
          <input class="uk-checkbox" type="checkbox" :disabled="!service || readonly" :class="{'uk-disabled': !service || readonly}"
            v-model="read"
            v-on:change="$emit('update', {field: 'permissions', value: permissions})">
          <p class="uk-margin-remove uk-text-right uk-text-meta uk-animation-fade" v-if="help">
            Read access is required
          </p>
        </label>
        <label>
          <span class="uk-margin-small-right uk-form-label" style="display: inline-block">write</span>
          <input class="uk-checkbox" type="checkbox" :disabled="!service || readonly" :class="{'uk-disabled': !service || readonly}"
            v-model="write"
            v-on:change="$emit('update', {field: 'permissions', value: permissions})">
          <p class="uk-margin-remove uk-text-right uk-text-meta uk-animation-fade" v-if="help">
            Write access is required
          </p>
        </label>
        <label>
          <span class="uk-margin-small-right uk-form-label" style="display: inline-block">execute</span>
          <input class="uk-checkbox" type="checkbox" :disabled="!service || readonly" :class="{'uk-disabled': !service || readonly}"
            v-model="execute"
            v-on:change="$emit('update', {field: 'permissions', value: permissions})">
          <p class="uk-margin-remove uk-text-right uk-text-meta uk-animation-fade" v-if="help">
            Execute access is required
          </p>
        </label>
      </div>
    </template>
    <p class="uk-text-center" v-else-if="permissions !== '---'">{{ permissions }}</p>
    <p class="uk-text-italic uk-text-center" v-else>no permissions requested</p>
  </EditableItem>
</template>

<script>
import { mapState } from 'vuex';
import EditableItem from '@/components/canvas/EditableItem.vue';
import ObjectAttribute from './ObjectAttribute.vue';

function assign(src, dst) {
  dst.service = src.item.service || '';
  const permissions = src.item.permissions || '---';
  dst.read = permissions.includes('r');
  dst.write = permissions.includes('w');
  dst.execute = permissions.includes('x');
  dst.error = {
    invalid: src.errors.length,
    msg: src.errors.length ? 'invalid data' : null,
    local: [],
    fields: new Map(),
    field(src) {
      return this.fields.get(src) ||'';
    },
  }
  for (const {property, detail} of src.errors) {
    switch (property) {
      case '/service':
        dst.error.fields.set(property, detail);
        break;
      default:
        dst.error.local.push(detail);
        break;
    }
  }
  return dst;
}

export default {
  name: 'Dependency',
  props: {
    version: Number,
    item: Object,
    errors: Array,
    editing: Boolean,
    context: Object,
    categories: Object,
    attribute: String,
    readonly: Boolean,
  },
  components: {
    EditableItem,
    ObjectAttribute,
  },
  data() {
    return assign(this, {});
  },
  mounted() {
  },
  computed: {
    permissions() {
      return `${this.read ? 'r' : '-'}${this.write ? 'w' : '-'}${this.execute ? 'x' : '-'}`;
    },
    ...mapState('api', ['help']),
  },
  watch: {
    version() {
      assign(this, this);
    },
    validate() {
    },
  }
}
</script>